import React from "react";
import PropTypes from "prop-types";

import "./style.scss";
import CurrencyTextFormatter from "../../../shared/CurrencyTextFormatter";
import { itemValues } from "../../../shared/helpers/itemValues";

function ProductSubtotal({ item }) {
  return (
    <div className="subtotalcontainer">
      <span className="subtotal">Subtotal</span>
      <span className="price">
        {/* {discounts.forEach((discount) => {
          if (discount > 0) totalPrice -= totalPrice * (discount / 100);
        })}
        {totalPrice.toFixed(2)} */}

        <CurrencyTextFormatter value={itemValues(item, "total")} />
      </span>
    </div>
  );
}

ProductSubtotal.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ProductSubtotal;
