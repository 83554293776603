import Inputmask from "inputmask";

const inputMasks = {
  cpf: "999.999.999-99",
  cnpj: "99.999.999/9999-99",
  phone: "(99) 99999-9999",
  email: { alias: "email" },
};

$(document).on("ready turbolinks:load", function () {
  Inputmask({
    mask: [inputMasks.cpf, inputMasks.cnpj],
    showMaskOnHover: false,
  }).mask($('input[data-mask="cpf_cnpj"]'));
  Inputmask({ mask: inputMasks.cpf, showMaskOnHover: false }).mask(
    $('input[data-mask="cpf"]')
  );
  Inputmask({ mask: inputMasks.phone, showMaskOnHover: false }).mask(
    $('input[data-mask="phone"]')
  );
  Inputmask(inputMasks.email).mask($('input[data-mask="email"]'));
});
