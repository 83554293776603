export const Types = {
  REQUEST: "dashboardRecommendations/REQUEST",
  SUCCESS: "dashboardRecommendations/SUCCESS",
  FAIL: "dashboardRecommendations/FAIL",
};

const INITIAL_STATE = {
  awards: {},
  report_client_recommendations: [],
  report_client_charges: [],
  loading: false,
  error: false,
  searchString: "",
  sellerCpf: "",
  noItemsFound: false,
};

export default function dashboardRecommendations(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case Types.REQUEST:
      return {
        ...state,
        loading: true,
        // searchString: action.payload.searchString,
        sellerCpf: action.payload.sellerCpf,
      };

    case Types.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        awards: action.payload.awards,
        report_client_recommendations:
          action.payload.report_client_recommendations,
        report_client_charges: action.payload.report_client_charges,
        noItemsFound:
          action.payload.report_client_recommendations.length > 0
            ? false
            : true,
      };

    case Types.FAIL:
      return { ...state, loading: false, error: true, noItemsFound: true };

    default:
      return state;
  }
}

export const Creators = {
  requestRecommendationsList: () => {
    return {
      type: Types.REQUEST,
    };
  },
};
