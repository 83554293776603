import React, { useState, useEffect } from "react";

import "./style.scss";
import eyeIcon from "../../../assets/dashboard/eyeIcon.svg";
import eyeClosedIcon from "../../../assets/dashboard/eyeClosedIcon.svg";
import equalIcon from "../../../assets/dashboard/equal-icon.svg";
import plusIcon from "../../../assets/dashboard/plus-icon.svg";
import CurrencyTextFormatter from "../CurrencyTextFormatter";
import chevronDown from "../../../assets/general/chevron-down.svg";

function BalanceHeader({ total, clientTotal, orderTotal }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isValueVisible, setIsValueVisible] = useState(false);

  const [isMobile, setIsMobile] = useState();

  function handleScreenSize() {
    const mql = window.matchMedia("(max-width: 1024px)");
    setIsMobile(mql.matches);
  }

  useEffect(() => {
    handleScreenSize();
    window.addEventListener("resize", handleScreenSize);
    return () => {
      window.removeEventListener("resize", handleScreenSize);
    };
  }, []);

  function handleOpenClose() {
    setIsOpen(isOpen ? false : true);
  }

  function handleBalanceVisibility() {
    setIsValueVisible(isValueVisible ? false : true);
  }
  return (
    <div>
      {isMobile ? (
        <div
          className={
            isOpen
              ? "header-accordion -is-open container"
              : "container header-accordion"
          }
        >
          <div className="head">
            {isValueVisible ? (
              <div className="image" onClick={handleBalanceVisibility}>
                <img src={eyeIcon} alt="Olho" />
              </div>)
              : (
                <div className="image" onClick={handleBalanceVisibility}>
                  <img src={eyeClosedIcon} alt="Olho" />
                </div>
              )}

            <div className="text" onClick={handleOpenClose}>
              <p className="total">Prêmios acumulados</p>
              <em className="totalvalue">
                {isValueVisible ? (
                  <CurrencyTextFormatter value={total ? total : 0} />
                ) : (
                    "R$ ••••••"
                  )}
              </em>
            </div>
            <img
              className="chevron"
              src={chevronDown}
              alt="Seta"
              onClick={handleOpenClose}
            />
          </div>
          {isOpen ? (
            <div className="content">
              <ul>
                <li className="item">
                  <span>Indicação premiada</span>
                  <em>
                    {isValueVisible ? (
                      <CurrencyTextFormatter
                        value={clientTotal ? clientTotal : 0}
                      />
                    ) : (
                        "R$ ••••••"
                      )}
                  </em>
                </li>
                <li className="item">
                  <span>Pedido premiado</span>
                  <em>
                    {isValueVisible ? (
                      <CurrencyTextFormatter
                        value={orderTotal ? orderTotal : 0}
                      />
                    ) : (
                        "R$ ••••••"
                      )}
                  </em>
                </li>
              </ul>
            </div>
          ) : null}
        </div>
      ) : (
          <div className="sum container">
            {isValueVisible ? (<div className="image" onClick={handleBalanceVisibility}>
              <img src={eyeIcon} alt="Olho" /></div>)
              : (<div className="image" onClick={handleBalanceVisibility}>
                <img src={eyeClosedIcon} alt="Olho" /></div>
              )}
            <div className="text">
              <p className="valuelabel">Prêmios acumulados</p>
              <em className="value -total">
                {isValueVisible ? (
                  <CurrencyTextFormatter value={total ? total : 0} />
                ) : (
                    "R$ ••••••"
                  )}
              </em>
            </div>
            <div className="math-sign">
              <img src={equalIcon} alt="Igual" />
            </div>
            <div className="text">
              <p className="valuelabel">Indicação premiada</p>
              <em className="value">
                {isValueVisible ? (
                  <CurrencyTextFormatter value={clientTotal ? clientTotal : 0} />
                ) : (
                    "R$ ••••••"
                  )}
              </em>
            </div>
            <div className="math-sign">
              <img src={plusIcon} alt="Igual" />
            </div>
            <div className="text">
              <p className="valuelabel">Pedido premiado</p>
              <em className="value">
                {isValueVisible ? (
                  <CurrencyTextFormatter value={orderTotal ? orderTotal : 0} />
                ) : (
                    "R$ ••••••"
                  )}
              </em>
            </div>
          </div>
        )}
    </div>
  );
}

export default BalanceHeader;
