import React from "react";
import './style.scss'

const ChargeByBluTip = () => {
  return (
    <div className="charge-by-blu-tip">
      <span className="text">
        <em>Não quer cobrar via Blu? </em> <br/>
        Você terá segurança no recebimento da sua comissão.
      </span>
    </div>
  );
};

export default ChargeByBluTip;
