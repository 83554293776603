import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";

import "./style.scss";
import WarningIcon from "../../../../assets/form/warning.svg";

const Field = ({
  name,
  label,
  type,
  placeholder,
  formHandler,
  icon,
  hasError,
  customError,
  refresh,
  min,
  max,
  step,
  mask,
  formatChars,
  maskChar,
  customClass,
  className,
  isDisabled,
  leftIcon,
}) => {
  const [hasCustomError, setHasCustomError] = useState(hasError);

  const isInvalid = formHandler.errors[name] && formHandler.touched[name];

  useEffect(() => {
    setHasCustomError(hasError);
  }, [hasError, refresh]);

  return (
    <div className={`${className} input-field`}>
      <label htmlFor={name} className="label">
        {label}
      </label>
      <InputMask
        id={name}
        className={
          isInvalid || hasCustomError
            ? `${customClass} input error `
            : `${customClass} input `
        }
        name={name}
        mask={mask}
        formatChars={formatChars}
        maskChar={maskChar}
        type={type}
        placeholder={placeholder}
        onChange={formHandler.handleChange}
        value={formHandler.values[name]}
        min={min}
        max={max}
        onChange={(e) => {
          formHandler.handleChange(e);
          setHasCustomError(false);
        }}
        step={step}
        disabled={isDisabled}
        style={{ paddingLeft: leftIcon? 40 : 8}}
      />
      {isInvalid && (
        <>
          <img src={WarningIcon} className="erroricn" />
          <span className="errormsg"> {formHandler.errors[name]} </span>
        </>
      )}

      {hasCustomError && (
        <>
          <img src={WarningIcon} className="erroricn" />
          <span className="errormsg"> {customError} </span>
        </>
      )}

      {icon && <img src={icon} className="icn" />}
      {leftIcon && <img src={leftIcon} className="icon" />}

    </div>
  );
};

Field.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  formHandler: PropTypes.object.isRequired,
  icon: PropTypes.string,
  leftIcon: PropTypes.string,
  hasError: PropTypes.bool,
  customError: PropTypes.string,
  refresh: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  formatChars: PropTypes.object,
};

Field.defaultProps = {
  label: "field",
  type: "text",
  placeholder: "",
  icon: null,
  leftIcon: null,
  hasError: false,
  customError: "",
  refresh: false,
  min: undefined,
  max: undefined,
  step: undefined,
  mask: undefined,
  maskChar: null,
  className: "",
  isDisabled: false,
  formatChars: undefined,
};

export default Field;
