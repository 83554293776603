import React from "react";
import PropTypes from "prop-types";
import ArrowBackIcon from "../../../assets/form/arrow_back.svg";
import "./style.scss";

const DetailHeader = ({
  url,
  title,
  backAction,
  showBackArrow,
  desktopOnly,
}) => {
  return (
    <>
      <div
        className={`detail-header ${
          desktopOnly ? "-desktopOnly" : ""
        } container`}
      >
        <div className="content">
          {showBackArrow ? (
            <>
              <a
                href={url ? url : undefined}
                className="back"
                onClick={() => backAction()}
              >
                <img src={ArrowBackIcon} />
              </a>
              <span className="backmsg">Voltar</span>{" "}
            </>
          ) : null}
          <h2 className="title" style={{ marginLeft: showBackArrow ? 20 : 0 }}>
            {title}
          </h2>
        </div>
        <h2 className="titledesktop">{title}</h2>
      </div>
    </>
  );
};

DetailHeader.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string.isRequired,
  backAction: PropTypes.func,
  showBackArrow: PropTypes.bool,
};

DetailHeader.defaultProps = {
  url: undefined,
  backAction: () => {},
  showBackArrow: true,
};

export default DetailHeader;
