import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useFormik } from "formik";

import "./style.scss";
import {
  customErrorStyles,
  customStyles,
} from "../../shared/helpers/CustomSelectStyles";
import ClientInfo from "../../shared/ClientInfo";
import PrimaryButton from "../../shared/PrimaryButton";
import BottomButtonContainer from "../../shared/BottomButtonContainer";
import DetailHeader from "../../shared/DetailHeader";
import Field from "../../shared/Form/Field";

import FormValidation from "../../shared/helpers/FormValidation";
import api from "../../services/api";

export default function OrderIndication({
  suppliers,
  recommendationForm,
  updateForm,
  previousStep,
  goToFeedback,
  clientStatus,
  updateChargeValue
}) {
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");

  const [fieldHasError, setFieldHasError] = useState({});
  const [customErrorMessage, setCustomErrorMessage] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const handleChange = (e) => {
    setSelectedValue(e.value);
    setFieldHasError({ distributor_name: false });
  };

  const formik = useFormik({
    initialValues: {
      charge: "",
    },
    onSubmit: (values) => {
      setIsLoading(true);
      updateChargeValue(values.charge)
      api
        .post(`/api/v1/client_charges`, {
          client_charge: {
            seller_id: recommendationForm.seller_cpf,
            client_name: recommendationForm.client_name,

            client_cpf_cnpj: recommendationForm.client_cpf_cnpj,
            distributor_name: selectedValue,
            charge: values.charge,
          },
        })
        .then((res) => {
          setIsLoading(false);
          if (res.data.client_charge.status == "ok") {
            goToFeedback({
              type: 5,
              client_cpf_cnpj: res.data.client_charge.client_cpf_cnpj,
              client_name: res.data.client_charge.client_name,
              first_ok_created_at: res.data.client_charge.first_ok_created_at,
              distributor_name: res.data.client_charge.distributor_name,
            });
          } else if (
            res.data.client_charge.status ==
            "already_made_in_the_last_three_months"
          ) {
            goToFeedback({
              type: 4,
              client_cpf_cnpj: res.data.client_charge.client_cpf_cnpj,
              client_name: res.data.client_charge.client_name,
              first_ok_created_at: res.data.client_charge.first_ok_created_at,
              distributor_name: res.data.client_charge.distributor_name,
            });
          } else if (res.data.client_charge.status == "another_charge") {
            goToFeedback({
              type: 3,
              client_cpf_cnpj: res.data.client_charge.client_cpf_cnpj,
              client_name: res.data.client_charge.client_name,
              first_ok_created_at: res.data.client_charge.first_ok_created_at,
              distributor_name: res.data.client_charge.distributor_name,
            });
          }
        })
        .catch((err) => {
          FormValidation({
            err,
            setFieldHasError,
            setCustomErrorMessage,
            setRefresh,
            refresh,
            setIsLoading,
          });
        });

      updateForm({ distributor_name: selectedValue });
    },
  });

  useEffect(() => {
    setOptions(
      suppliers.map((supplier) => ({ value: supplier, label: supplier }))
    );
  }, []);

  return (
    <>
      <DetailHeader
        title="Indique um pedido"
        backAction={previousStep}
        desktopOnly
      />
      <div className="container order-indication">
        <p>
          Tire o primeiro pedido para um novo fornecedor cobrando via Blu e
          informe os dados abaixo. Quando o cliente aprová-lo você recebe o seu
          prêmio.
        </p>

        <ClientInfo
          altStyle
          greenIcon
          name={recommendationForm.client_name}
          cpfCnpj={recommendationForm.client_cpf_cnpj}
          className="clientInfo"
        />
        <form onSubmit={formik.handleSubmit}>
          <div className="formrow">
            <div className="inputcontainer">
              <span>Fornecedor do pedido</span>
              <Select
                placeholder={
                  <span className="selectplaceholder">Selecione uma opção</span>
                }
                styles={
                  fieldHasError.distributor_name
                    ? customErrorStyles
                    : customStyles
                }
                options={options}
                onChange={handleChange}
                value={options.find((obj) => obj.value === selectedValue)}
              />
              {fieldHasError.distributor_name &&
              customErrorMessage.distributor_name ? (
                <span className="customerror">
                  {customErrorMessage.distributor_name}
                </span>
              ) : null}
            </div>

            <Field
              name="charge"
              label="Valor do pedido (opcional)"
              placeholder="Informe o valor do pedido"
              formHandler={formik}
              className="field"
              hasError={fieldHasError.charge}
              customError={customErrorMessage.charge}
              refresh={refresh}
            />
          </div>
          <BottomButtonContainer hasSeparator>
            <PrimaryButton type="submit">
              {isLoading ? (
                <div className="spinner -white -mg-auto"></div>
              ) : (
                "Finalizar"
              )}
            </PrimaryButton>
          </BottomButtonContainer>
        </form>
      </div>
    </>
  );
}
