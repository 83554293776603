import React from "react";

import "./style.scss";
import arrowRight from "../../../assets/general/arrow-right-primary.svg";
import completeImage from "../../../assets/home/ilustra-complete.svg";

import PrimaryButton from "../../shared/PrimaryButton";

export default function RpaCta() {
  return (
    <div className="rpa-cta">
      <div className="content">
        <PrimaryButton
          className="cta"
          onClick={() => {
            Turbolinks.visit("/completar-cadastro");
          }}
          style={{ whiteSpace: "nowrap" }}
        >
          Completar dados
        </PrimaryButton>

        <div className="text">
          <h2 className="title">Complete seus dados</h2>
          <h2 className="title -desktop">
            Complete seus dados e receba seus prêmios com mais comodidade!
          </h2>
          <p className="paragraph">
            Falta pouco para receber seus prêmios com mais comodidade.
          </p>
          <p className="paragraph -desktop">
            Simplificamos o pagamento do prêmio: você completa o cadastro uma
            única vez e recebe seu prêmio direto na sua conta Blu.
          </p>
          <a className="link" href="/completar-cadastro">
            Completar dados <img src={arrowRight} />
          </a>
        </div>

        <img src={completeImage} />
      </div>
    </div>
  );
}
