import React from "react";
import Backdrop from "../../../shared/Backdrop";
import BooleanField from "../../../shared/Form/BooleanField";
import PrimaryButton from "../../../shared/PrimaryButton";
import warningIcon from "../../../../assets/dashboard/warning.svg";

export default function StatusBackdrop({
  isOpen,
  close,
  formHandler,
  name,
  statusList,
  isOrder,
}) {
  return (
    <div>
      <Backdrop isOpen={isOpen} close={close}>
        <form onSubmit={formHandler.handleSubmit}>
          <BooleanField
            name={name}
            value="all"
            formHandler={formHandler}
            label="Filtrar por"
            type="checkbox"
            isRow
            isBold
            onChange={() => {
              if (formHandler.values[name].includes("all")) {
                formHandler.setFieldValue(name, []);
              } else {
                formHandler.setFieldValue(name, ["all", ...statusList]);
              }
            }}
          />
          <hr
            className="separator"
            style={{
              margin: "12px 0",
              border: 0,
              borderBottom: "1px solid #d8dae8",
            }}
          />
          {isOrder ? (
            <>
              <BooleanField
                name={name}
                value="in_analysis"
                formHandler={formHandler}
                labelColor="#6b708b "
                label="Em análise"
                type="checkbox"
                isRow
              />
              <BooleanField
                name={name}
                value="waiting_payment"
                formHandler={formHandler}
                label="Aguardando pagamento"
                type="checkbox"
                isRow
                labelColor="#6b708b "
              />
              <BooleanField
                name={name}
                value="paid_with_blu"
                formHandler={formHandler}
                label="Pedido pago via Blu!"
                type="checkbox"
                isRow
                labelColor="#6b708b "
              />
              <BooleanField
                name={name}
                value="invalid"
                formHandler={formHandler}
                label="Indicação inválida"
                type="checkbox"
                isRow
                labelColor="#6b708b "
              />
            </>
          ) : (
            <>
              <BooleanField
                name={name}
                value="processing"
                formHandler={formHandler}
                labelColor="#6b708b "
                label="Cliente indicado"
                type="checkbox"
                isRow
              />
              <BooleanField
                name={name}
                value="has_user_logged"
                formHandler={formHandler}
                label="Fechou com a Blu!"
                type="checkbox"
                isRow
                labelColor="#6b708b "
              />
              <BooleanField
                name={name}
                value="intermediate"
                formHandler={formHandler}
                label="Começou a vender"
                type="checkbox"
                isRow
                labelColor="#6b708b "
              />
              <BooleanField
                name={name}
                value="has_spent_500"
                formHandler={formHandler}
                label="Vendeu R$500 ou mais"
                type="checkbox"
                isRow
                labelColor="#6b708b "
              />
              <BooleanField
                name={name}
                value="invalid"
                formHandler={formHandler}
                label="Indicação inválida"
                type="checkbox"
                isRow
                labelColor="#6b708b"
              />
            </>
          )}
          {!formHandler.values[name].some((el) => statusList.includes(el)) ? (
            <div
              className="noneselected"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img src={warningIcon} style={{ marginRight: "8px" }} />
              <span style={{ fontWeight: 600, color: "#FF4343", fontSize: 14 }}>
                Selecione pelo menos um status
              </span>
            </div>
          ) : null}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PrimaryButton
              disabled={
                !formHandler.values[name].some((el) => statusList.includes(el))
              }
              type="submit"
              style={{ maxWidth: 500 }}
            >
              Filtrar
            </PrimaryButton>
          </div>
        </form>
      </Backdrop>
    </div>
  );
}
