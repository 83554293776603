import statusHook from './status-hook';

$.fn.statusCard = function() {
    const $statusCard = $(this);

    $statusCard.on("click", function(event) {
        const target = $(this).attr("data-js-status-card");

        event.preventDefault();
        statusHook([target]);
    });
};

$(document).on("ready turbolinks:load", function() {
    $("[data-js-status-card]").statusCard();
});