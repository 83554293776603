import React, { useEffect, useState } from "react";
import { useFormik } from "formik";

import DetailHeader from "../../../shared/DetailHeader";
import CpfCnpjFormatter from "../../../shared/CpfCnpjFormatter";
import ReadOnlyField from "../../../shared/ReadOnlyField";
import Field from "../../../shared/Form/Field";
import PrimaryButton from "../../../shared/PrimaryButton";
import okIcon from "../../../../assets/myAccount/ok-circle.svg";
import "./style.scss";
import BottomButtonContainer from "../../../shared/BottomButtonContainer";
import api from "../../../services/api";
import Snackbar from "../../../shared/Snackbar";
import FormValidation from "../../../shared/helpers/FormValidation";
import BooleanField from "../../../shared/Form/BooleanField";

const WITHOUT_FATHER_NAME_REGISTERED = 'SEM REGISTRO'

export default function PersonalData({
  isRpa,
  customBack,
  customNext,
  showBackCta,
  updateData,
  sellerData,
}) {
  const [fieldHasError, setFieldHasError] = useState({});
  const [customErrorMessage, setCustomErrorMessage] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);

  const formik = useFormik({
    initialValues: {
      rpa_cpf: sellerData.rpa_cpf || "",
      name: sellerData.name || "",
      birthday: sellerData.birthday
        ? sellerData.birthday.split("-").reverse().join("-")
        : "",
      mother_name: sellerData.mother_name || "",
      father_name: sellerData.father_name 
        ? sellerData.father_name === WITHOUT_FATHER_NAME_REGISTERED
        ? "" 
        : sellerData.father_name
        : "",
      no_father_name_registered: sellerData.father_name === WITHOUT_FATHER_NAME_REGISTERED
        ? 'no_father_name_registered'
        : 'has_father_name'
    },
    validate: (values) => {
      let errors = {};
      let requiredMsg = "Este campo não pode ficar em branco.";
      const hasFatherName = values?.no_father_name_registered === 'has_father_name'

      if (!values.name) errors.name = requiredMsg;
      if (!values.birthday) errors.birthday = requiredMsg;
      if (!values.mother_name) errors.mother_name = requiredMsg;
      if (hasFatherName && !values.father_name) errors.father_name = requiredMsg;
      return errors;
    },
    onSubmit: (values) => {
      setIsLoading(true);
      api
        .patch(`/api/v1/sellers/${sellerData.cpf_cnpj}`, {
          seller: {
            rpa_cpf: values.rpa_cpf,
            name: values.name,
            birthday: values.birthday,
            mother_name: values.mother_name,
            father_name: values?.no_father_name_registered === 'has_father_name'
              ? values.father_name
              : WITHOUT_FATHER_NAME_REGISTERED,
            verify_attributes: [
              "rpa_cpf",
              "name",
              "birthday",
              "mother_name",
              "father_name",
            ],
          },
        })
        .then((res) => {
          setIsLoading(false);
          setShowSuccessSnackbar(true);
          if (customNext) {
            customNext();
          }
        })
        .catch((err) => {
          FormValidation({
            err,
            setFieldHasError,
            setCustomErrorMessage,
            setRefresh,
            refresh,
            setIsLoading,
          });
          
        });
      isRpa && updateData(values);
    },
  });

  useEffect(() => {
    window.scrollTo(0, -1000);
  }, []);

  const handleHasFatherName = () => {    
    const nofatherName = formik.values.no_father_name_registered.includes("no_father_name_registered")
    formik.setFieldValue("no_father_name_registered", nofatherName 
      ? "has_father_name"
      : "no_father_name_registered"
    )
  }

  return (
    <>
      <DetailHeader
        backAction={() =>
          customBack ? customBack() : Turbolinks.visit("#meus-dados")
        }
        title="Dados Pessoais"
        showBackArrow={showBackCta}
      />
      <div className="container">
        <div className="personal-data">
          {isRpa ? (
            <h1 className="rpa-title">
              Para começar, informe os dados a seguir
            </h1>
          ) : null}
          <ReadOnlyField title="CNPJ">
            <CpfCnpjFormatter unmaskedCpfCnpj={sellerData.cpf_cnpj} />
          </ReadOnlyField>
          <ReadOnlyField title="E-mail" subtitle={sellerData.email} />
          <br />
          <form onSubmit={formik.handleSubmit}>
            {sellerData.cpf_cnpj.length > 11 ? (
              <div className="formrow">
                <Field
                  name="rpa_cpf"
                  label="CPF"
                  placeholder="Informe o seu CPF"
                  formHandler={formik}
                  customClass="field"
                  hasError={fieldHasError.rpa_cpf}
                  customError={customErrorMessage.rpa_cpf}
                  refresh={refresh}
                  mask="999.999.999-99"
                />
              </div>
            ) : null}
            <div className="formrow -md-śm">
              <Field
                name="name"
                label="Nome completo"
                placeholder="Informe seu nome completo"
                formHandler={formik}
                className="field"
                hasError={fieldHasError.name}
                customError={customErrorMessage.name}
                refresh={refresh}
              />
              <Field
                name="birthday"
                label="Data de nascimento"
                placeholder="dd/mm/aaaa"
                mask="d9/m9/ys99"
                formatChars={{
                  9: "[0-9]",
                  a: "[A-Za-z]",
                  "*": "[A-Za-z0-9]",
                  d: "[0-3]",
                  m: "[0-1]",
                  y: "[1-2]",
                  s: "(0|9)",
                }}
                formHandler={formik}
                className="field"
                hasError={fieldHasError.birthday}
                customError={customErrorMessage.birthday}
                refresh={refresh}
              />
            </div>

            <Field
              name="mother_name"
              label="Nome da mãe"
              placeholder="Informe o nome da sua mãe"
              formHandler={formik}
              className="field"
              hasError={fieldHasError.mother_name}
              customError={customErrorMessage.mother_name}
              refresh={refresh}
            />
            <Field
              name="father_name"
              label="Nome do pai"
              placeholder="Informe o nome do seu pai"
              formHandler={formik}
              className="field"
              hasError={fieldHasError.father_name}
              customError={customErrorMessage.father_name}
              refresh={refresh}
            />
            <BooleanField 
              name="no_father_name_registered"
              value="no_father_name_registered"
              label="Não consta no registro de nascimento."
              formHandler={formik}
              customClass="checkbox-container"
              type="checkbox"
              refresh={refresh}   
              onChange={handleHasFatherName}
              isBold
              labelColor="#6b708b"
            />            
            <br />
            <BottomButtonContainer>
              <PrimaryButton type="submit" className="button">
                {isLoading ? (
                  <div className="spinner -white -mg-auto"></div>
                ) : isRpa ? (
                  "Avançar"
                ) : (
                  "Salvar"
                )}
              </PrimaryButton>
            </BottomButtonContainer>
          </form>
        </div>
      </div>
      {!isRpa ? (
        <Snackbar
          shouldShow={showSuccessSnackbar}
          text="Dados salvos com sucesso"
          icon={okIcon}
          handleClose={() => setShowSuccessSnackbar(false)}
        />
      ) : null}
    </>
  );
}
