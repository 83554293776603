import React, { useEffect } from "react";
import { connect } from "react-redux";

import { Types } from "../../../store/ducks/dashboardActiveTab";
import { Types as FilterTypes } from "../../../store/ducks/dashboardFilter";

import "./style.scss";
import Field from "../../shared/Form/Field";
import DashboardList from "../DashboardList";
import { useFormik } from "formik";
import searchIcon from "../../../assets/general/search-icon.svg";

function RecommendationsList({
  setActiveTab,
  dashboardActiveTab,
  setFilter,
  filters,
}) {
  const formik = useFormik({
    initialValues: {
      recommendationSearch: filters.filterStr || "",
    },
  });

  useEffect(() => {
    setFilter({ ...filters, filterStr: formik.values.recommendationSearch });
  }, [formik.values.recommendationSearch]);

  return (
    <div className="dashboard-recommendations-list">
      <form onSubmit={formik.handleSubmit}>
        <Field
          name="recommendationSearch"
          label=""
          placeholder="CNPJ ou razão social"
          formHandler={formik}
          leftIcon={searchIcon}
        />
      </form>

      <DashboardList />
      {filters.filterStr === "" && filters.filterByStatus.length == 0 ? (
        <div className="listendcontainer">
          <span className="listend">Essas são todas as suas indicações.</span>
        </div>
      ) : null}

      {filters.filteredClientList.length === 0 ? (
        <div className="listendcontainer">
          <span className="listend">Não encontramos nenhum resultado.</span>
        </div>
      ) : null}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveTab: (payload) => {
      dispatch({ type: Types.SET_ACTIVE_TAB, payload });
    },
    setFilter: (payload) => {
      dispatch({ type: FilterTypes.SET_FILTER, payload });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    dashboardActiveTab: state.dashboardActiveTab,
    filters: state.dashboardFilter,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecommendationsList);
