import React, { useEffect, useState } from "react";
import CpfCnpjFormatter from "../../../shared/CpfCnpjFormatter";

function OrderListItem({
  clientName,
  clientCnpj,
  date,
  status,
  supplierCnpj,
  supplierName,
}) {
  const itemDate = new Date(Date.parse(date));
  const [statusLabel, setStatusLabel] = useState("");

  useEffect(() => {
    switch (status) {
      case "in_analysis":
        setStatusLabel("Em análise");
        break;
      case "already_made_in_the_last_three_months":
      case "invalid":
        setStatusLabel("Indicação inválida");
        break;
      case "paid_with_blu":
        setStatusLabel("Pedido pago via Blu!");
        break;
      case "waiting_payment":
        setStatusLabel("Aguardando pagamento");
        break;
      default:
        break;
    }
  }, [status]);

  return (
    <div className="dashboard-list-item -order">
      <div className="info">
        <span className="cnpj">
          <CpfCnpjFormatter unmaskedCpfCnpj={clientCnpj} />
        </span>
        <span className="name -mb">{clientName}</span>
        <span className="cnpj">
          <CpfCnpjFormatter unmaskedCpfCnpj={supplierCnpj} />
        </span>
        <span className="name">{supplierName}</span>
        <span className={`status -mobile -${status}`}> {statusLabel} </span>
      </div>
      <span className={`status -desktop -${status}`}> {statusLabel} </span>
      <div className="date">
        <span>{`${
          itemDate.getDate() < 10
            ? "0" + itemDate.getDate()
            : itemDate.getDate()
        }/${
          itemDate.getMonth() + 1 < 10
            ? "0" + Number(itemDate.getMonth() + 1)
            : itemDate.getMonth() + 1
        }/${itemDate.getFullYear()}`}</span>
      </div>
    </div>
  );
}

export default OrderListItem;
