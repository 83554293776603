import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

function PrimaryButton({ className, children, ...rest }) {
  return (
    <button data-testid="primary-button" {...rest} className={`primary-button ${className}`}>
      {children}
    </button>
  );
}

PrimaryButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

PrimaryButton.defaultProps = {
  className: "",
  children: null,
};

export default PrimaryButton;
