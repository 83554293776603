import React from "react";

import './style.scss';
import DetailHeader from "../../shared/DetailHeader";
import MenuItemMobile from "../../shared/MenuItemMobile";

export default function SecurityAndPrivacy() {
  return (
    <>
      <DetailHeader
        title="Segurança e Privacidade"
        backAction={() => Turbolinks.visit("/minha-conta")}
      />
      <div className="container security-and-privacy">
        <div className="my-account-menu-mobile">
          <MenuItemMobile
            title="Privacidade e dados"
            subtitle=""
            onClick={() =>
              Turbolinks.visit(
                "#privacidade-e-dados"
              )
            }
          />
          {/* <MenuItemMobile
            title="Alterar minha senha"
            subtitle=""
            onClick={() =>
              Turbolinks.visit(
                "#alterar-minha-senha"
              )
            }
            className="noborderitem"
          /> */}
        </div>
      </div>
    </>
  );
}
