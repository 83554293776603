import React from "react";

import "./style.scss";
import arrowUpRight from "../../../assets/myAccount/arrow-up-right.svg";
import DetailHeader from "../../shared/DetailHeader";

export default function Support({ showBackCta }) {
  return (
    <>
      <DetailHeader
        backAction={() => Turbolinks.visit("/minha-conta")}
        title="Suporte"
        showBackArrow={showBackCta}
      />
      <div className="container">
        <div className="account-support">
          <h2 className="subheading">Canal direto do Rep</h2>

          <div className="contact">
            <span>repblu@useblu.com.br</span>
          </div>
          
          <div className="contact">
            <p>3003 0807 (Capitais e Regiões Metropolitanas)</p>
            <p>0800 326 0807 (Demais Regiões)</p>
          </div>

          <hr className="separator" />

          <a className="termscta" href="/termos-e-condicoes" target="_blank">
            Termos e Condições
            <img src={arrowUpRight} alt="Seta" />
          </a>
        </div>
      </div>
    </>
  );
}
