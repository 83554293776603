import React from "react";
import "./style.scss";
import Backdrop from "../../shared/Backdrop";
import interventionImage from "../../../assets/dashboard/ilustra-intervencao.svg";
import PrimaryButton from "../../shared/PrimaryButton";
import SecondaryButton from "../../shared/SecondaryButton";

export default function RegisterIntervention({
  isOpen,
  portalHost,
  close,
  status,
}) {
  return (
    <Backdrop customWidth={600} isFullScreen isOpen={isOpen} close={close}>
      <div className="register-intervention-container">
        <img src={interventionImage} alt="Pendencia cadastral" />
        <h2>Ops, parece que você tem alguma pendência cadastral.</h2>
        {
          {
            company_documentation_reproved: (
              <>
                <p className="spacing-bottom">
                  O seu cadastro não foi concluído com sucesso. Entre em contato
                  com a área de relacionamento do cliente para a sua
                  regularização cadastral:
                </p>
                <div className="number">
                  <em>3003 0807</em>
                  <p>Capitais e Regiões Metropolitanas</p>
                </div>
                <div className="number">
                  <em className="last-number">0800 326 0807</em>
                  <p>Demais Regiões </p>
                </div>
                <p className="spacing-top">
                  <strong>Atenção:</strong> A comissão das suas indicações na
                  Comunidade REP só poderá ser paga após o cadastro ser
                  regularizado.
                </p>
                <PrimaryButton onClick={close} className="custom-button">
                  Entendi
                </PrimaryButton>
              </>
            ),
            waiting_company_documentation_upload: (
              <>
                <p className="spacing-bottom">
                  Envie o contrato social através do Portal da Blu para a
                  regularização do seu cadastro.
                </p>

                <p className="spacing-bottom">
                  <strong>Atenção:</strong> A comissão das suas indicações na
                  Comunidade REP só poderá ser paga após o cadastro ser
                  regularizado.
                </p>
                <div className="action-container">
                  <PrimaryButton
                    onClick={() => window.open(portalHost, "_blank")}
                    className="custom-button"
                  >
                    Ir para o Portal da Blu
                  </PrimaryButton>
                  <SecondaryButton onClick={close} className="custom-button">
                    Talvez mais tarde
                  </SecondaryButton>
                </div>
              </>
            ),
          }[status]
        }
      </div>
    </Backdrop>
  );
}
