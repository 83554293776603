import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { Actions as PersonalizedPageActions } from "../../../../store/ducks/personalizedPage";

import "./style.scss";
import linkImage from "../../../../assets/home/ilustra-link.svg";
import dots from "../../../../assets/home/dots-vertical-solid.svg";
import hasPagelinkImage from "../../../../assets/myAccount/ilustra-link.svg";
import linkIcon from "../../../../assets/home/link-outline.svg";
import PrimaryButton from "../../../shared/PrimaryButton";
import WhatsappPrimaryButton from "../../../shared/WhatsappPrimaryButton";
import whatsappShare from "../../../shared/helpers/RepLinkWhatsappShare";
import SecondaryButton from "../../../shared/SecondaryButton";
import CopyToClipboard from "../../../shared/helpers/CopyToClipboard";
import Snackbar from "../../../shared/Snackbar";
import checkCircleIcon from "../../../../assets/general/check-circle-outline.svg";


const PersonalizedPageWidget = ({
  requestPersonalizedPageList,
  personalizedPage,
  currentSeller,
}) => {
  const [showMenuItem, setShowMenuItems] = useState(false);
  const [showCopiedSnackbar, setShowCopiedSnackbar] = useState(false);

  useEffect(() => {
    requestPersonalizedPageList(currentSeller.id);
  }, []);

  const handleCopyLink = () => {
    setShowCopiedSnackbar(true);
    CopyToClipboard(personalizedPage?.pages[0]?.url)
  };

  return !personalizedPage?.pages?.length ? (
    <div className="personalized-page-widget">
      <div className="badge badge--new">Novo</div>
      <div className="content">
        <img src={linkImage} alt="Link Personalizado" />
        <h2>
          Crie sua página personalizada! Com ela fica ainda mais fácil indicar
          ;)
        </h2>
        <p>
          Compartilhe sua página personalizada direto com seus clientes e assim
          que eles se cadastrarem a indicação é registrada. Fácil, né?
        </p>

        <PrimaryButton
          onClick={() => Turbolinks.visit("/minha-conta#pagina-personalizada")}
        >
          Criar página personalizada
        </PrimaryButton>
      </div>
    </div>
  ) : (
    <>
      <div className="personalized-page-widget">
        <div
          className="menu"
          onClick={() => setShowMenuItems(showMenuItem ? false : true)}
        >
          <img src={dots} />
          <a
            href="/minha-conta#pagina-personalizada"
            className={`menu-item ${showMenuItem && "show"}`}
          >
            Editar minha página
          </a>
        </div>
        <div className="content">
          <img src={hasPagelinkImage} alt="Link Personalizado" />

          <h2>
            Indique seus clientes compartilhando o link da sua página
            personalizada
          </h2>
          <p className="link">
            <img src={linkIcon} alt="link" />
            <a
              href={`/representante/${personalizedPage?.pages[0]?.slug}`}
              target="_blank"
            >
              rep.blu.com.br/
              <em>{personalizedPage?.pages[0]?.slug}</em>
            </a>
          </p>

          <WhatsappPrimaryButton
            action={() => whatsappShare(personalizedPage?.pages[0]?.slug)}
            text="Enviar via WhatsApp"
          />
          <SecondaryButton
            borderless
            onClick={handleCopyLink}
          >
            Copiar link
          </SecondaryButton>
        </div>
      </div>
      <Snackbar
        shouldShow={showCopiedSnackbar}
        handleClose={setShowCopiedSnackbar}
        icon={checkCircleIcon}
        text="Link Copiado"
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    requestPersonalizedPageList: (sellerId) =>
      dispatch(PersonalizedPageActions.requestPersonalizedPageList(sellerId)),
  };
};

const mapStateToProps = (state) => {
  return {
    personalizedPage: state.personalizedPage,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalizedPageWidget);
