const searchRowByValue = (row, value) => {
    const hasValue = row.toLowerCase().includes(value.toLowerCase());
    
    return hasValue;
};

$.fn.dashboardSearch = function(params) {
    const $wrapper = this;
    const $table = $(params.target);
    const $alert = $('.dashboard-no-recommendations');
    const $rows = $table.find('tbody tr');
    const $clear = $wrapper.find('.clear');

    /* Prevent submit search form */
    $wrapper.on('submit', function(e) {
        e.preventDefault();
    })

    /* Allow search form to be cleared */
    $wrapper.find('.clear').on('click', function () {
        $wrapper.trigger('reset');
        $table.show();
        $rows.show();
        $alert.hide();
        $clear.hide();
    });

    /* When the search form is filled */
    $wrapper.find('.input').on('change paste keyup', function() {
        const value = $(this).val();

        const valid = $rows.filter((_index, element) => searchRowByValue($(element).text(), value));
        const invalid = $rows.filter((_index, element) => !searchRowByValue($(element).text(), value));
    
        valid.show();
        invalid.hide();

        if (value.length > 0) {
            $clear.show();
        } else {
            $clear.hide();
        }

        if (valid.length === 0) {
            $alert.show();
            $table.hide();
        } else {
            $alert.hide();
            $table.show();
        }
    });
};

$(document).on("ready turbolinks:load", function () {
    $('.dashboard-search').dashboardSearch({
        target: '.dashboard-table'
    });
});
