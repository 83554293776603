import React from "react";
import { useState } from "react";
import { useFormik } from "formik";

import "./style.scss";
import api from "../../services/api";
import Field from "../../shared/Form/Field";
import FormValidation from "../../shared/helpers/FormValidation";
import PrimaryButton from "../../shared/PrimaryButton";
import BottomButtonContainer from "../../shared/BottomButtonContainer";
import ClientInfo from "../../shared/ClientInfo";
import DetailHeader from "../../shared/DetailHeader";
import { validateEmailWitelist } from "../../shared/helpers/InputValidators";

function NotBluClient({
  nextStep,
  updateForm,
  recommendationForm,
  previousStep,
}) {
  const [fieldHasError, setFieldHasError] = useState({});
  const [customErrorMessage, setCustomErrorMessage] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      client_name: recommendationForm.client_name || "",
      user_name: recommendationForm.user_name || "",
      user_email: recommendationForm.user_email || "",
      user_phone: recommendationForm.user_phone || "",
      user_phone_2: recommendationForm.user_phone_2 || "",
    },
    onSubmit: (values) => {
      setIsLoading(true);
      api
        .post(`/api/v1/client_recommendations`, {
          client_recommendation: {
            client_cpf_cnpj: recommendationForm.client_cpf_cnpj,
            seller_cpf: recommendationForm.seller_cpf,
            client_name: values.client_name,
            user_name: values.user_name,
            user_email: values.user_email,
            user_phone: values.user_phone,
            user_phone_2: values.user_phone_2,
            verify_attributes: [
              "client_name",
              "user_name",
              "user_email",
              "user_phone",
              "user_phone_2",
            ],
          },
        })
        .then(() => {
          setIsLoading(false);
          if (nextStep) {
            nextStep();
          }
        })
        .catch((err) => {
          FormValidation({
            err,
            setFieldHasError,
            setCustomErrorMessage,
            setRefresh,
            refresh,
            setIsLoading,
          });
        });
      updateForm(values);
    },
    validate: (values) => {
      let errors = {};
      const invalidPhoneMsg = "Informe um número de celular válido.",
        requiredMsg = "Este campo não pode ficar em branco.";
      const isInWiteMailListCheck = validateEmailWitelist(values.user_email);
      if (values.user_phone.length < 15) errors.user_phone = invalidPhoneMsg;
      if (!values.client_name) errors.client_name = requiredMsg;
      if (!values.user_name) errors.user_name = requiredMsg;
      if (!!isInWiteMailListCheck) {
        errors.user_email = isInWiteMailListCheck;
      }
      if (!values.user_email) errors.user_email = requiredMsg;
      return errors;
    },
  });

  return (
    <>
      <DetailHeader
        title="Precisamos de mais algumas informacões"
        backAction={previousStep}
        desktopOnly
      />
      <div className="container recommendation-not-blu-client">
        <ClientInfo
          altStyle
          greenIcon
          cpfCnpj={recommendationForm.client_cpf_cnpj}
        />
        <form onSubmit={formik.handleSubmit}>
          <div className="formrow">
            <Field
              name="client_name"
              label="Nome da loja"
              placeholder="Informe o nome da loja"
              formHandler={formik}
              className="field"
              hasError={fieldHasError.client_name}
              customError={customErrorMessage.client_name}
              refresh={refresh}
            />
            <Field
              name="user_name"
              label="Responsável pela loja"
              placeholder="Informe o responsável pela loja"
              formHandler={formik}
              className="field"
              hasError={fieldHasError.user_name}
              customError={customErrorMessage.user_name}
              refresh={refresh}
            />
          </div>
          <div className="formrow">
            <Field
              name="user_email"
              label="E-mail do responsável pela loja"
              placeholder="Informe o e-mail do responsável"
              formHandler={formik}
              className="field"
              hasError={fieldHasError.user_email}
              customError={customErrorMessage.user_email}
              refresh={refresh}
            />

            <Field
              name="user_phone"
              label="Whatsapp do responsável pela loja"
              placeholder="Informe o nº do celular com DDD"
              formHandler={formik}
              className="field"
              hasError={fieldHasError.user_phone}
              customError={customErrorMessage.user_phone}
              refresh={refresh}
              mask="(99) 99999-9999"
            />
          </div>

          <div className="formrow">
            <Field
              name="user_phone_2"
              label="Informe outro telefone (opcional)"
              placeholder="Informe o nº do telefone com DDD"
              formHandler={formik}
              className="field"
              hasError={fieldHasError.user_phone_2}
              customError={customErrorMessage.user_phone_2}
              refresh={refresh}
              mask="(99) 99999-9999"
            />
          </div>
          <BottomButtonContainer hasSeparator>
            <PrimaryButton type="submit">
              {isLoading ? (
                <div className="spinner -white -mg-auto"></div>
              ) : (
                "Continuar"
              )}
            </PrimaryButton>
          </BottomButtonContainer>
        </form>
      </div>
    </>
  );
}

export default NotBluClient;
