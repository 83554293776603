import React, { useEffect, useState } from "react";

import "./style.scss";
import PersonalData from "./MyData/PersonalData";
import MenuItemMobile from "../shared/MenuItemMobile";
import MenuDropdownDesktop from "../shared/MenuDropdownDesktop";
import Documents from "./MyData/Documents";
import Address from "./MyData/Address";
import MySuppliers from "./MyData/MySuppliers";
import MyData from "./MyData";
import SecurityAndPrivacy from "./SecurityAndPrivacy";
import DataPrivacy from "./SecurityAndPrivacy/DataPrivacy";
import Support from "./Support";
import PersonalizedPage from "./PersonalizedPage";
import AccountData from "./SecurityAndPrivacy/DataPrivacy/AccountData";

export default function MyAccount({ currentSeller }) {
  const [accordionVisibility, setAccordionVisibility] = useState({
    myData: true,
    security: false,
    support: false,
    personalizedPage: false,
  });

  const locationHash = window.location.hash;

  function toggleMenuDropdown(accordionName) {
    const tempAccordion = accordionVisibility[accordionName] ? false : true;
    setAccordionVisibility({
      [accordionName]: tempAccordion,
    });
  }

  useEffect(() => {
    switch (locationHash) {
      case "#privacidade-e-dados":
        toggleMenuDropdown("security");
        break;
      case "#suporte":
        toggleMenuDropdown("support");
        break;
      case "#pagina-personalizada":
        toggleMenuDropdown("personalizedPage");
        break;
      default:
        break;
    }

    if (locationHash === "#privacidade-e-dados") {
      toggleMenuDropdown("security");
    } else if (locationHash === "#suporte") {
      toggleMenuDropdown("support");
    }
  }, []);

  return (
    <div className="container">
      <div className="my-account-layout-desktop">
        <div className="my-account-menu-desktop">
          <h1 className="title">Minha conta</h1>

          <MenuDropdownDesktop
            showNewBadge
            title="Página personalizada"
            isOpen={accordionVisibility.personalizedPage}
            toggle={() => {
              toggleMenuDropdown("personalizedPage");
              Turbolinks.visit("#pagina-personalizada");
            }}
            showArrow={false}
          />
          <MenuDropdownDesktop
            title="Meus dados"
            isOpen={accordionVisibility.myData}
            toggle={() => toggleMenuDropdown("myData")}
          >
            <a
              href="#dados-pessoais"
              className={
                locationHash === "#dados-pessoais" || !locationHash
                  ? `desktop-menu-item -selected`
                  : `desktop-menu-item`
              }
            >
              Dados pessoais
            </a>
            <a
              href="#documentos"
              className={
                locationHash === "#documentos"
                  ? `desktop-menu-item -selected`
                  : `desktop-menu-item`
              }
            >
              Documentos
            </a>
            <a
              href="#endereco"
              className={
                locationHash === "#endereco"
                  ? `desktop-menu-item -selected`
                  : `desktop-menu-item`
              }
            >
              Endereço
            </a>
            {/* <a
              href="#meus-fornecedores"
              className={
                locationHash === "#meus-fornecedores"
                  ? `desktop-menu-item -selected`
                  : `desktop-menu-item`
              }
            >
              Meus fornecedores
            </a> */}
          </MenuDropdownDesktop>
          <MenuDropdownDesktop
            title="Segurança e privacidade"
            isOpen={accordionVisibility.security}
            id="check"
            toggle={() => toggleMenuDropdown("security")}
          >
            <a
              href="#privacidade-e-dados"
              className={
                locationHash === "#privacidade-e-dados" ||
                locationHash === "#dados-da-conta"
                  ? `desktop-menu-item -selected`
                  : `desktop-menu-item`
              }
            >
              Privacidade e dados
            </a>
          </MenuDropdownDesktop>
          <MenuDropdownDesktop
            title="Suporte"
            isOpen={accordionVisibility.support}
            toggle={() => {
              toggleMenuDropdown("support");
              Turbolinks.visit("#suporte");
            }}
            showArrow={false}
          />
        </div>

        <div className="my-account-dektop-content">
          {{
            "#pagina-personalizada": (
              <PersonalizedPage
                sellerData={currentSeller}
                showBackCta={false}
              />
            ),
            "#dados-pessoais": (
              <PersonalData sellerData={currentSeller} showBackCta={false} />
            ),
            "#documentos": (
              <Documents sellerData={currentSeller} showBackCta={false} />
            ),
            "#endereco": (
              <Address sellerData={currentSeller} showBackCta={false} />
            ),
            "#meus-fornecedores": <MySuppliers />,
            "#privacidade-e-dados": <DataPrivacy showBackCta={false} />,
            "#dados-da-conta": <AccountData />,

            "#mudar-senha": false,
            "#suporte": <Support showBackCta={false} />,
          }[locationHash] || (
            <PersonalData sellerData={currentSeller} showBackCta={false} />
          )}
        </div>
      </div>

      <div className="my-account-mobile">
        {{
          "#pagina-personalizada": (
            <PersonalizedPage sellerData={currentSeller} showBackCta={true} />
          ),
          "#dados-pessoais": <PersonalData sellerData={currentSeller} />,
          "#documentos": <Documents sellerData={currentSeller} />,
          "#endereco": <Address sellerData={currentSeller} />,
          // "#meus-fornecedores": <MySuppliers />,
          "#privacidade-e-dados": <DataPrivacy />,
          // "#mudar-senha": false,
          "#suporte": <Support />,
          "#meus-dados": <MyData />,
          "#dados-da-conta": <AccountData />,
          "#seguranca-e-privacidade": <SecurityAndPrivacy />,
        }[locationHash] || (
          <div className="my-account-menu-mobile">
            <h1 className="title">Minha conta</h1>
            <MenuItemMobile
              title="Página personalizada"
              onClick={() => Turbolinks.visit("#pagina-personalizada")}
            />
            <MenuItemMobile
              title="Meus dados"
              subtitle="Dados cadastrais, meus fornecedores"
              onClick={() => Turbolinks.visit("#meus-dados")}
            />
            <MenuItemMobile
              title="Segurança e privacidade"
              subtitle="Senha, notificações, políticas"
              onClick={() => Turbolinks.visit("#seguranca-e-privacidade")}
            />
            <MenuItemMobile
              title="Suporte"
              subtitle="Fale conosco, termos e condições"
              onClick={() => Turbolinks.visit("#suporte")}
            />
            <a data-method="get" href="/logout" className="logout">
              <span className="text">Sair da conta</span>
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
