import orderBy from 'lodash.orderby';

$.fn.dashboardDataTable = function(params) {
    const $table = this;
    const $rows = $table.find('tbody > tr');

    // Initialize arrows element for each sort field
    $table.find("[data-js-sort-field]").each(function(_index, element) {
        $(element).append('<span></span>');
    });

    // Sort event
    $table.find("[data-js-sort-field]").on("click", function() {
        const $th = $(this);
        const column = $th.attr('data-js-sort-field');
        const direction = $th.attr('data-js-sort-direction');

        // This handler indicates what is the next direction
        const directionsHandler = {
            "": "DESC",
            "ASC": "",
            "DESC": "ASC",
        };
        const directionTarget = directionsHandler[direction];

        //Tracking
        mixpanel.track("recommendation_sort",{
            sort_type: $th.text()
        })

        // We are using lodash `orderBy()` function for a better developer experience.
        // https://lodash.com/docs/#orderBy
        const orderedRows = orderBy($rows, [(row) => {
            const value = $(row).find(`.${column}`).text();

            return value;
        }], [directionTarget.toLowerCase()]);

        // Replace the current direction for other headers
        $table.find('th').attr("data-js-sort-direction", "");

        // Update the current direction to the new
        $th.attr("data-js-sort-direction", directionTarget);

        // Replace table content with ordered rows
        $table.find('tbody').html(orderedRows);

    });
}

$(document).on("ready turbolinks:load", function () {
    $("[data-js-datatable]").dashboardDataTable();
});
