import React from "react";
import PropTypes from "prop-types";

import "./style.scss";
import phoneIcon from "../../../../../assets/myAccount/phone.svg";
import mailIcon from "../../../../../assets/myAccount/mail.svg";
import DetailHeader from "../../../../shared/DetailHeader";

function AccountData({ showBackCta }) {
  return (
    <>
      <DetailHeader
        backAction={() => Turbolinks.visit("#privacidade-e-dados")}
        title="Dados da sua conta"
        showBackArrow={showBackCta}
      />
      <div className="container account-data">
        <h2 className="title"> Solicitar exclusão de dados</h2>
        <p className="paragraph">
          Você pode solicitar a exclusão dos seus Dados através do cancelamento
          da sua Conta ou de outros cadastros da Blu que você tenha (conforme
          aplicável) e a Blu fará a exclusão definitiva de seus Dados em até 180
          dias. Após a exclusão, a Blu poderá manter os seus Dados somente (a)
          pelo prazo necessário para fins de processos judiciais,
          administrativos e arbitrais, (b) para cumprimento de obrigação legal
          e/ou regulatória, (c) para o exercício regular de direitos, como, por
          exemplo, fazer valer os direitos da Blu com base no contrato de
          assinatura e/ou Termos de Uso aplicáveis ou (d) em formato
          anonimizado.
          <br /> <br />
          Para prosseguir com o sua solicitação entre em contato conosco:
          <br /> <br />
        </p>

        <div className="contact">
          <img src={phoneIcon} alt="Telefone" /><span>3003 0807 (Capitais e Regiões Metropolitanas)</span>
        </div>
        <div className="contact">
          <img src={phoneIcon} alt="Telefone" />  <span>0800 326 0807 (Demais Regiões)</span>
        </div>
        <div className="contact">
          <img src={mailIcon} alt="E-mail" />
          <span>repblu@useblu.com.br</span>
        </div>
      </div>
    </>
  );
}

AccountData.propTypes = {
  showBackArrow: PropTypes.bool,
};

AccountData.defaultProps = {
  showBackArrow: true,
};

export default AccountData;
