import React from "react";
import PropTypes from "prop-types";

import "./style.scss";
import closeIcon from "../../../../assets/recommendations/close.svg"

function FeedbackMainBody({
  paragraph,
  title,
  image,
  subtitle,
  titleAndSubtitle,
}) {

  function handleClose() {
    Turbolinks.visit('/dashboard')
  }

  return (<>
    <div className="feedback-close" onClick={handleClose} >
      <img src={closeIcon} alt="Fechar" />
      <span> Fechar </span>
    </div>
    <div className="feedback-main-body">
      <div className="imagecontainer">
        <img className="image" src={image} />
      </div>
      <div className="content">
        {title}
        {titleAndSubtitle}
        {subtitle}
        {paragraph}
      </div>
    </div>
  </>
  );
}

FeedbackMainBody.propTypes = {
  paragraph: PropTypes.any,
  title: PropTypes.any,
  image: PropTypes.string.isRequired,
  subtitle: PropTypes.any,
  titleAndSubtitle: PropTypes.any,
};

FeedbackMainBody.defaultProps = {
  paragraph: null,
  title: null,
  subtitle: null,
  titleAndSubtitle: null,
};

export default FeedbackMainBody;
