import React, { useEffect, useState } from "react";
import { useFormik } from "formik";

import "./style.scss";
import noContactImage from "../../../assets/opportunities/no-contact.svg";
import clientIcon from "../../../assets/opportunities/icn-client.svg";
import phoneIcon from "../../../assets/opportunities/icn-phone.svg";

import DetailHeader from "../../shared/DetailHeader";
import Field from "../../shared/Form/Field";
import TextAreaField from "../../shared/Form/TextAreaField";
// import CpfCnpjFormatter from "../../shared/CpfCnpjFormatter";

import { connect } from "react-redux";
import { Types } from "../../../store/ducks/opportunities";
import api from "../../services/api";
import FormValidation from "../../shared/helpers/FormValidation";
import InfoWithIcon from "../../shared/InfoWithIcon";
import FooterActions from "../../shared/FooterActions";

function OpportunityDetail({
  changePage,
  requestProcessOpportunities,
  opportunities,
  currentSeller,
  recentlyUpdated,
}) {
  const [fieldHasError, setFieldHasError] = useState({});
  const [customErrorMessage, setCustomErrorMessage] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      user_phone: "",
      comments: "",
    },
    onSubmit: (values) => {
      setIsLoading(true);
      api
        .post(`/api/v1/opportunities/process_business_action`, {
          opportunity: {
            id: opportunities.selectedOpportunity.id,
            seller_cpf: currentSeller.cpf_cnpj,
            payload: {
              client_recommendation: {
                user_phone: values.user_phone,
              },
              comments: values.comments,
            },
          },
        })
        .then((res) => {
          setIsLoading(false);
          recentlyUpdated({
            recentlyUpdated: {
              newPhone: values.user_phone,
              comments: values.comments,
            },
          });
          console.log(res.data);
          changePage("detailUpdatedSuccess");
        })
        .catch((err) => {
          FormValidation({
            err,
            setFieldHasError,
            setCustomErrorMessage,
            setRefresh,
            refresh,
            setIsLoading,
          });
        });
    },
  });

  useEffect(() => {
    if (!opportunities.selectedOpportunity?.opportunitable_object) {
      changePage("list");
    }
  }, []);

  return (
    <>
      <DetailHeader title="" backAction={() => changePage("list")} />
      <div className="container opportunity-detail">
        <div className="imgcontainer">
          <img src={noContactImage} />
        </div>
        <div className="content">
          <h1 className="title">
            Não conseguimos falar com o cliente indicado
          </h1>

          <InfoWithIcon
            isCpfCnpj
            icon={clientIcon}
            description={
              opportunities.selectedOpportunity?.opportunitable_object
                ?.client_recommendation?.client_cpf_cnpj || changePage("list")
            }
            primaryText={
              opportunities.selectedOpportunity?.opportunitable_object
                ?.client_recommendation?.client_name
            }
          />

          <InfoWithIcon
            isCpfCnpj={false}
            icon={phoneIcon}
            description="Telefone"
            primaryText={
              opportunities.selectedOpportunity?.opportunitable_object
                ?.client_recommendation?.user_phone
            }
          />

          <p className="description">
            Pode ser que os telefones informados estejam incorretos,
            desatualizados ou o lojista não estava disponível no horário da
            ligação.
            <br />
            <br />
            <strong>
              Para que possamos fazer uma nova tentativa preencha os campos
              abaixo.
            </strong>
          </p>
          <form onSubmit={formik.handleSubmit}>
            <Field
              label="Telefone com DDD"
              placeholder="Informe o telefone do cliente"
              name="user_phone"
              mask="(99) 99999-9999"
              formHandler={formik}
              hasError={fieldHasError.user_phone}
              customError={customErrorMessage.user_phone}
              refresh={refresh}
            />
            <br />
            <TextAreaField
              label="Observações (opcional)"
              placeholder="Ex: O melhor horário para falar com o cliente é a partir de 17h."
              name="comments"
              formHandler={formik}
            />

            <FooterActions loading={isLoading} secondaryAction={() => changePage("discard")} />
          </form>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setTab: (payload) => {
      dispatch({ type: Types.SET_TAB, payload });
    },
    requestOpportunities: (payload) => {
      dispatch({ type: Types.REQUEST, payload });
    },
    requestProcessOpportunities: (payload) => {
      dispatch({ type: Types.REQUEST_PROCESS, payload });
    },
    recentlyUpdated: (payload) => {
      dispatch({ type: Types.RECENTLY_UPDATED, payload });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    opportunities: state.opportunities,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OpportunityDetail);
