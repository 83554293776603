$(document).on("ready turbolinks:load", function () {
	const ua = navigator.userAgent.toLowerCase(),
	 	isAndroid = ua.indexOf("android") > -1,
		isIOS = ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1;
		
	const isMobile = isAndroid || isIOS,
		isMobileResolution = $(window).width() <= 768,
		isMobileContext = isMobile && isMobileResolution

	const lastPopup = localStorage.getItem("lastPopup"),
		time_now = new Date().getTime();

	if (isMobileContext) {
		$(".mobile-index").append(`
			<div class="app-download">
				<img src='/assets/app-download/close.svg' class='close-download'>
				<img src='/assets/app-download/download-icon.svg'>
				<div class="app-download__text">
					<h5> Baixe já o app! </h5>
					<p>Tenha a comunidade no seu celular.</p>
				</div>

				<a href="https://useblu.app.link/blurep" class="button button--primary"> Baixar </a>
			</div>
		`);
	} else {
		$(".mobile-index").hide();
	}

	if (time_now - lastPopup > 1000 * 60 * 60 * 24) {
		localStorage.removeItem('lastPopup');
		$(".app-download").css("display", "flex");
	}

	$(".close-download").on("click", function () {
		$(".app-download").hide();
		localStorage.setItem("lastPopup", time_now);
	});
});
