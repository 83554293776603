import React from "react";
import PropTypes from "prop-types";

import chevronRightIcon from "../../../../../assets/checkout/chevron-right.svg";
import CurrencyTextFormatter from "../../../../shared/CurrencyTextFormatter";
import ItemImage from "../../../../shared/ItemImage";

import "./style.scss";

function ProductListItem({ product }) {
  return (
    <div className="product-list-item">
      <div className="product">
        <ItemImage
          image={product.images[0] ? product.images[0] : undefined}
          size="thumb"
        />
        <div className="info">
          <span className="code">{product.code}</span>
          <span className="name">{product.name}</span>
          <span className="price">
            <CurrencyTextFormatter value={product.unity_price} />
          </span>
        </div>
        <div className="actions">
          <img src={chevronRightIcon} alt="Ação" className="" />
        </div>
      </div>
    </div>
  );
}

ProductListItem.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductListItem;
