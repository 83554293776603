import React from "react";
import PropTypes from "prop-types";

import icnClient from "../../../assets/recommendations/icn-cliente.svg";
import UserIcon from "../../../assets/checkout/user.svg";
import "./style.scss";
import CpfCnpjFormatter from "../CpfCnpjFormatter";

const ClientInfo = ({ cpfCnpj, name, greenIcon, altStyle, className }) => {
  return (
    <div className={`client-info ${className}`}>
      <img src={greenIcon ? icnClient : UserIcon} />
      <div className={altStyle ? "content -alt" : "content"}>
        <p>
          <CpfCnpjFormatter unmaskedCpfCnpj={cpfCnpj} />
        </p>
        <em>{name}</em>
      </div>
    </div>
  );
};

ClientInfo.propTypes = {
  cpfCnpj: PropTypes.string.isRequired,
  name: PropTypes.string,
  greenIcon: PropTypes.bool,
  className: PropTypes.string,
};

ClientInfo.defaultProps = {
  greenIcon: false,
  name: null,
  className: '',
};

export default ClientInfo;
