import React, { useEffect, useState } from "react";

import "./style.scss";
import getSuppliers from "../shared/helpers/GetSuppliers";
import ClientStep from "./ClientStep";
import Feedback from "./Feedback";
import NotBluClient from "./NotBluClient";
import OrderIndication from "./OrderIndication";
import SellerStep from "./SellerStep";
import SupplierStep from "./SupplierStep";
import recommendationImage from "../../assets/recommendations/ilustra-indicacao.svg";

export default function Recommendations({ currentSeller }) {
  const [activeSection, setActiveSection] = useState({
    aboutSeller: true,
    aboutClient: false,
    supplier: false,
    alreadyBluClient: false,
    notBluClient: false,
    orderIndication: false,
    feedback: false,
  });
  const [suppliers, setSuppliers] = useState([]);
  const [feedbackData, setFeedbackData] = useState({});
  const [clientStatus, setClientStatus] = useState("");
  const [chargeValue, setChargeValue] = useState();

  const [recommendationForm, setRecommendationForm] = useState({});

  function handleUpdateRecommendationForm(values) {
    setRecommendationForm({ ...recommendationForm, ...values });
  }

  function handleNavigation(selected) {
    const tempObject = activeSection;
    Object.keys(tempObject).forEach((v) => (tempObject[v] = false));
    setActiveSection({ ...tempObject, [selected]: true });
  }

  function goToFeedback({
    client_cpf_cnpj,
    client_name,
    first_ok_created_at,
    distributor_name,
  }) {
    setFeedbackData({
      client_cpf_cnpj,
      client_name,
      first_ok_created_at,
      distributor_name,
    });
    handleNavigation("feedback");
  }

  useEffect(() => {
    getSuppliers(setSuppliers);
    if (currentSeller) {
      if (currentSeller.cpf_cnpj.length >= 11) {
        handleUpdateRecommendationForm({
          seller_cpf: currentSeller.cpf_cnpj,
        });
        handleNavigation("aboutClient");
      }
    }
  }, []);

  useEffect(() => {
    switch (true) {
      case activeSection.aboutClient:
        mixpanel.track("recommendation_clientid_view", {
          origin: document.referrer ? document.referrer : "",
        });
        break;
      case activeSection.notBluClient:
        mixpanel.track("recommendation_start_view");
        break;
      case activeSection.supplier:
        mixpanel.track("recommendation_detail_view");
        break;
      case activeSection.orderIndication:
        mixpanel.track("recommendation_supplier_view");
        break;
      case activeSection.feedback:
        mixpanel.track("recommendation_done_view", {
          status: "recommendation_ok",
          value: chargeValue ? chargeValue : "",
        });
        break;
      default:
        break;
    }
  }, [activeSection]);

  return (
    <div className="recommendations">
      {!activeSection.feedback ? (
        <section className="description">
          <div className="container content">
            <img src={recommendationImage} alt="Indicação" />
            <div className="text">
              <h1 className="title">
                Ganhe ainda mais com a Indicação Premiada!
              </h1>
              <p className="paragraph">
                Agora você ganha <strong>R$500</strong> por cada indicação que
                se tornar cliente Blu!
              </p>
            </div>
          </div>
        </section>
      ) : null}
      {
        {
          [activeSection.aboutSeller]: (
            <SellerStep
              updateForm={handleUpdateRecommendationForm}
              nextStep={() => handleNavigation("aboutClient")}
              recommendationForm={recommendationForm}
            />
          ),
          [activeSection.aboutClient]: (
            <ClientStep
              updateForm={handleUpdateRecommendationForm}
              nextStep={() => handleNavigation("notBluClient")}
              goToOrderIndication={() => handleNavigation("orderIndication")}
              recommendationForm={recommendationForm}
              setClientStatus={setClientStatus}
            />
          ),
          [activeSection.notBluClient]: (
            <NotBluClient
              updateForm={handleUpdateRecommendationForm}
              nextStep={() => handleNavigation("supplier")}
              previousStep={() => handleNavigation("aboutClient")}
              recommendationForm={recommendationForm}
            />
          ),
          [activeSection.supplier]: (
            <SupplierStep
              updateForm={handleUpdateRecommendationForm}
              recommendationForm={recommendationForm}
              suppliers={suppliers}
              previousStep={() => handleNavigation("notBluClient")}
              goToFeedback={goToFeedback}
            />
          ),
          [activeSection.orderIndication]: (
            <OrderIndication
              recommendationForm={recommendationForm}
              suppliers={suppliers}
              updateForm={handleUpdateRecommendationForm}
              previousStep={() => handleNavigation("aboutClient")}
              goToFeedback={goToFeedback}
              clientStatus={clientStatus}
              updateChargeValue={setChargeValue}
            />
          ),
          [activeSection.feedback]: (
            <Feedback
              clientName={feedbackData.client_name}
              supplierName={feedbackData.distributor_name}
              firstCharge={feedbackData.first_ok_created_at}
            />
          ),
        }[true]
      }
    </div>
  );
}
