import React from "react";
import "./style.scss";

import Mobile from "./Mobile";
import { useState } from "react";
import { useEffect } from "react";
import Desktop from "./Desktop";

import { connect } from "react-redux";
import { Types } from "../../../store/ducks/dashboardActiveTab";
import { Types as RecommendationsListTypes } from "../../../store/ducks/dashboardRecommendations";
import { Types as FilterTypes } from "../../../store/ducks/dashboardFilter";
// import BalanceHeader from "../../shared/BalanceHeader";
import PrimaryButton from "../../shared/PrimaryButton";

function Main({
  currentSeller,
  setActiveTab,
  activeTab,
  requestRecommendationsList,
  clientRecommendations,
  filters,
  setFilter,
}) {
  const [isMobile, setIsMobile] = useState();

  function handleScreenSize() {
    const mql = window.matchMedia("(max-width: 1024px)");
    setIsMobile(mql.matches);
  }

  useEffect(() => {
    requestRecommendationsList({ sellerCpf: currentSeller.cpf_cnpj });
    handleScreenSize();
    window.addEventListener("resize", handleScreenSize);
    return () => {
      window.removeEventListener("resize", handleScreenSize);
    };
  }, []);

  useEffect(() => {
    setFilter({
      ...filters,
      clientList: clientRecommendations.report_client_recommendations,
      orderList: clientRecommendations.report_client_charges,
    });
  }, [clientRecommendations]);

  return (
    <div className="new-dashboard-header">
      {/* <BalanceHeader
        total={clientRecommendations.awards.total}
        clientTotal={clientRecommendations.awards.client_recommendations}
        orderTotal={clientRecommendations.awards.client_charges}
      /> */}

      {!clientRecommendations.loading ? (
        <div className="content">
          {clientRecommendations.report_client_charges.length > 0 ||
          clientRecommendations.report_client_recommendations.length > 0 ? (
            isMobile ? (
              <Mobile currentSeller={currentSeller} />
            ) : (
              <Desktop currentSeller={currentSeller} />
            )
          ) : (
            <div className="norecommendations">
              <h2>Você ainda não fez nenhuma indicação. Comece a ganhar já!</h2>
              <PrimaryButton
                onClick={() => Turbolinks.visit("/indicacao-premiada")}
              >
                Indicar agora
              </PrimaryButton>
            </div>
          )}
        </div>
      ) : (
        <div
          style={{ display: "flex", justifyContent: "center" }}
          className="container"
        >
          <div className="spinner" />
        </div>
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveTab: (payload) => {
      dispatch({ type: Types.SET_ACTIVE_TAB, payload });
    },
    setFilter: (payload) => {
      dispatch({ type: FilterTypes.SET_FILTER, payload });
    },
    requestRecommendationsList: (payload) => {
      dispatch({ type: RecommendationsListTypes.REQUEST, payload });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    activeTab: state.activeTab,
    clientRecommendations: state.dashboardRecommendations,
    filters: state.dashboardFilter,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
