export function itemValues(item, value) {
  let tempSubtotal = item.quantity * item.product.unity_price;
  let tempDiscountedSubtotal = tempSubtotal;
  let tempDiscount = 0;

  item.discounts.forEach((discount) => {
    if (discount > 0) {
      tempDiscount += tempDiscountedSubtotal * (discount / 100);
      tempDiscountedSubtotal -= tempDiscountedSubtotal * (discount / 100);

    }
  });

  switch (value) {
    case "discount":
      return tempDiscount;
    case "subtotal":
      return tempSubtotal;
    case "total":
      return tempDiscountedSubtotal;
    default:
      return tempDiscountedSubtotal;
  }
}
