import React from "react";
import PropTypes from "prop-types";

import "./style.scss";

function ComplementaryData({ complementaryData }) {
  return (
    <div className="overview-complementary-data">
      <div className="field">
        <div className="fieldtitle">Forma de pagamento</div>
        <div className="fieldvalue">
          {
            {
              charging_by_blu: "Cobrança via Blu",
              billet: "Boleto tradicional",
              ted: "TED",
              bank_deposit: "Depósito bancário",
            }[complementaryData.payment_method]
          }
        </div>
      </div>
      <hr className="separator" />
      <div className="field">
        <div className="fieldtitle">Cond. de pagamento</div>
        <div className="fieldvalue">
          {complementaryData.payment_conditions
            ? complementaryData.payment_conditions
            : "-"}
        </div>
      </div>
      <hr className="separator" />
      <div className="field">
        <div className="fieldtitle">Substituição tributária</div>
        <div className="fieldvalue">
          {complementaryData.tax_substitution > 0
            ? complementaryData.tax_substitution + "%"
            : "-"}
        </div>
      </div>
      <hr className="separator" />
      <div className="field">
        <div className="fieldtitle">IPI</div>
        <div className="fieldvalue">
          {complementaryData.ipi > 0 ? complementaryData.ipi + "%" : "-"}
        </div>
      </div>
      <hr className="separator" />
      <div className="field">
        <div className="fieldtitle">Tipo de frete</div>
        <div className="fieldvalue">
          {complementaryData.frete_type ? complementaryData.frete_type.toUpperCase() : "-"}
        </div>
      </div>
      <hr className="separator" />
      <div className="field">
        <div className="fieldtitle">Observações</div>
        <div className="fieldvalue">
          {complementaryData.comments ? complementaryData.comments : "-"}
        </div>
      </div>
    </div>
  );
}

ComplementaryData.propTypes = {
  complementaryData: PropTypes.object.isRequired,
};

export default ComplementaryData;
