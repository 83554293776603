import React, { useState, useEffect } from "react";
import { useFormik } from "formik";

import "./style.scss";
import DetailHeader from "../shared/DetailHeader";
import ClientInfo from "../shared/ClientInfo";
import infoIcon from "../../assets/checkout/info.svg";
import BooleanField from "../shared/Form/BooleanField";
import Field from "../shared/Form/Field";
import TextAreaField from "../shared/Form/TextAreaField";
import ChargeByBluTip from "./ChargeByBluTip";
import percentIcon from "../../assets/checkout/%.svg";
import { itemValues } from "../shared/helpers/itemValues";
import CurrencyTextFormatter from "../shared/CurrencyTextFormatter";
import api from "../services/api";

const Addition = ({ currentSeller }) => {
  // const [isLoading, setIsLoading] = useState(false);

  const [fieldHasError, setFieldHasError] = useState({});
  const [customErrorMessage, setCustomErrorMessage] = useState({});
  const [refresh, setRefresh] = useState(false);

  const [subtotal, setSubtotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [taxSubstitutionValue, setTaxSubstitutionValue] = useState(0);
  const [ipiValue, setIpiValue] = useState(0);

  const formik = useFormik({
    initialValues: {
      payment_method: "",
      paymentCondition: "",
      taxSubstitution: "",
      ipi: "",
      comments: "",
      frete_type: "",
    },
  });

  let client;
  try {
    client = JSON.parse(localStorage.client);
  } catch {
    Turbolinks.visit("/pedidos");
  }

  let items;

  try {
    items = JSON.parse(localStorage.getItem("localCartItems"));
    if (items.length <= 0) {
      Turbolinks.visit("/pedidos/novo");
    }
  } catch {
    Turbolinks.visit("/pedidos/novo");
  }

  function handleRadioGroup(name) {
    setFieldHasError({ ...fieldHasError, [name]: false });
  }

  function handleTaxes() {
    let tempTaxSubstitution =
        (subtotal - discount) * (formik.values.taxSubstitution / 100),
      tempIpi = (subtotal - discount) * (formik.values.ipi / 100);
    setTaxSubstitutionValue(tempTaxSubstitution);
    setIpiValue(tempIpi);
  }

  function handleItemValues() {
    let tempSubtotal = 0,
      tempDiscount = 0;

    items.forEach((el) => {
      tempSubtotal += itemValues(el, "subtotal");
      tempDiscount += itemValues(el, "discount");
    });
    setSubtotal(tempSubtotal);
    setDiscount(tempDiscount);
  }

  function handleNext() {
    api
      .post("/api/v1/orders", {
        order: {
          seller_id: currentSeller.cpf_cnpj,
          client_cpf_cnpj: client.cpfCnpj,
          client_business_name: client.businessName,
          client_name: client.tradingName,
          client_phone: client.phone,
          client_state_registration: client.stateRegistration,
          address_city: client.city,
          address_complement: client.complement,
          address_neighborhood: client.neighbourhood,
          address_number: client.number,
          address_state: client.state,
          address_street: client.address,
          address_zipcode: client.cep,
          comments: formik.values.comments,
          frete_type: formik.values.frete_type,
          payment_method: formik.values.payment_method,
          tax_substitution: formik.values.taxSubstitution,
          payment_conditions: formik.values.paymentCondition,
          client_responsible_email: client.clientEmail,
          client_responsible_name: client.clientName,
          ipi: formik.values.ipi,
          verify_attributes: [
            "client_business_name",
            "client_name",
            "client_phone",
            "address_city",
            "address_neighborhood",
            "address_number",
            "address_state",
            "address_street",
            "address_zipcode",
            "frete_type",
            "payment_method",
            "payment_conditions",
            "tax_substitution",
            "ipi",
            "client_responsible_email",
            "client_responsible_name",
            "client_cpf_cnpj",
          ],
        },
      })
      .then(() => {
        localStorage.setItem(
          "complementaryData",
          JSON.stringify({
            comments: formik.values.comments,
            frete_type: formik.values.frete_type,
            payment_method: formik.values.payment_method,
            tax_substitution: formik.values.taxSubstitution,
            payment_conditions: formik.values.paymentCondition,
            ipi: formik.values.ipi,
          })
        );
        Turbolinks.visit("/pedidos/resumo");
      })
      .catch((err) => {
        if (err.response.data.errors.length > 0) {
          const errors = err.response.data.errors;
          let tempHasError = {};
          let tempErrorMessage = {};

          errors.forEach((error) => {
            tempHasError = { ...tempHasError, [error.type]: true };
            tempErrorMessage = {
              ...tempErrorMessage,
              [error.type]: error.messages[0],
            };
          });
          setFieldHasError(tempHasError);
          setCustomErrorMessage(tempErrorMessage);
          // setIsLoading(false);
          setRefresh(!refresh);
        }
      });
  }

  useEffect(() => {
    handleItemValues();
  }, [items]);

  useEffect(() => {
    handleTaxes();
  }, [formik.values.taxSubstitution, formik.values.ipi]);

  return (
    <>
      <DetailHeader title="Complementos" url="/pedidos/novo" />
      <div className="container order-addition">
        <div className="content">
          <ClientInfo cpfCnpj={client.cpfCnpj} name={client.tradingName} />

          <div className="section -payment">
            <h4 className="title">Pagamento</h4>

            <div className="content">
              <div className="info">
                <span className="text">Forma de pagamento</span>
                <div className="infoimage">
                  <img src={infoIcon} className="image" alt="Informações" />
                  <span className="tooltip">
                    Antecipação ZERO: economia de até 15% <br />
                    Boleto Blu*: mais prazo para pagar <br />À Vista: desconto
                    de à vista
                    <span className="additionalinfo">
                      *Verifique se sua fábrica está aceitando o boleto Blu.
                    </span>
                  </span>
                </div>
              </div>
              {client.status !== "not_blu_client" ? (
                <BooleanField
                  name="payment_method"
                  label="Cobrança via Blu"
                  value="charging_by_blu"
                  type="radio"
                  formHandler={formik}
                  hasError={fieldHasError["payment_method"]}
                  handleRadioGroup={handleRadioGroup}
                />
              ) : null}
              <BooleanField
                name="payment_method"
                label="Boleto tradicional"
                value="billet"
                type="radio"
                formHandler={formik}
                hasError={fieldHasError["payment_method"]}
                handleRadioGroup={handleRadioGroup}
              />
              {formik.values.payment_method === "billet" ? (
                <ChargeByBluTip />
              ) : null}
              <BooleanField
                name="payment_method"
                label="TED"
                value="ted"
                type="radio"
                formHandler={formik}
                hasError={fieldHasError["payment_method"]}
                handleRadioGroup={handleRadioGroup}
              />
              {formik.values.payment_method === "ted" ? (
                <ChargeByBluTip />
              ) : null}
              <BooleanField
                name="payment_method"
                label="Depósito bancário"
                value="bank_deposit"
                type="radio"
                formHandler={formik}
                hasError={fieldHasError["payment_method"]}
                handleRadioGroup={handleRadioGroup}
              />
              {fieldHasError["payment_method"] ? (
                <span className="error">
                  {customErrorMessage["payment_method"]}
                </span>
              ) : null}
              {formik.values.payment_method === "bank_deposit" ? (
                <ChargeByBluTip />
              ) : null}
              <br />
              {formik.values.payment_method !== "charging_by_blu" &&
              formik.values.payment_method !== "" ? (
                <Field
                  name="paymentCondition"
                  placeholder="Exemplo: 30/60/90 dias"
                  formHandler={formik}
                  label="Condições de pagamento"
                  hasError={fieldHasError["payment_conditions"]}
                  customError={customErrorMessage["payment_conditions"]}
                  refresh={refresh}
                />
              ) : null}
            </div>
          </div>
          <div className="section -taxes">
            <h4 className="title">Impostos</h4>
            <div className="content">
              <div className="tax">
                <Field
                  name="taxSubstitution"
                  placeholder="0"
                  formHandler={formik}
                  label="Substituição tributária"
                  mask="99.99"
                  maskChar="0"
                  customClass="field"
                  icon={percentIcon}
                />
                {formik.values.taxSubstitution > 0 ? (
                  <>
                    <span className="equalsign"> = </span>
                    <div className="value">
                      <span>
                        <CurrencyTextFormatter value={taxSubstitutionValue} />
                      </span>
                    </div>
                  </>
                ) : null}
              </div>

              <div className="tax">
                <Field
                  name="ipi"
                  placeholder="0"
                  formHandler={formik}
                  label="IPI"
                  mask="99.99"
                  maskChar="0"
                  customClass="field"
                  icon={percentIcon}
                />

                {formik.values.ipi > 0 ? (
                  <>
                    <span className="equalsign"> = </span>
                    <div className="value">
                      <span>
                        <CurrencyTextFormatter value={ipiValue} />
                      </span>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>

          <div className="section -shipping">
            <h4 className="title">Frete</h4>

            <div className="content">
              <div className="info">
                <span className="text">Selecione o tipo de frete</span>

                <div className="infoimage">
                  <img src={infoIcon} className="image" alt="Informações" />
                  <span className="tooltip">
                    <em>CIF:</em> frete e seguro pagos pelo fornecedor. <br />
                    <em>FOB:</em> frete e seguro pagos pelo cliente.
                  </span>
                </div>
              </div>

              <BooleanField
                name="frete_type"
                label="CIF"
                value="cif"
                type="radio"
                formHandler={formik}
                hasError={fieldHasError["frete_type"]}
                handleRadioGroup={handleRadioGroup}
              />
              <BooleanField
                name="frete_type"
                label="FOB"
                value="fob"
                type="radio"
                formHandler={formik}
                hasError={fieldHasError["frete_type"]}
                handleRadioGroup={handleRadioGroup}
              />
              {fieldHasError["frete_type"] ? (
                <span className="error">
                  {customErrorMessage["frete_type"]}
                </span>
              ) : null}
            </div>
          </div>

          <hr className="separator" />
          <TextAreaField
            name="comments"
            label="Observações"
            placeholder="Se precisar, escreva uma observação sobre o pedido."
            maxLength={150}
            formHandler={formik}
          />
          <br />
          <div className="summaryfooter">
            <div className="info">
              <span className="text">Subtotal</span>
              <span className="price">
                <CurrencyTextFormatter value={subtotal} />
              </span>
            </div>
            {discount > 0 ? (
              <div className="info">
                <span className="text">Descontos</span>
                <span className="price">
                  -<CurrencyTextFormatter value={discount} />
                </span>
              </div>
            ) : null}
            <div className="info">
              <span className="text">Impostos</span>
              <span className="price">
                <CurrencyTextFormatter
                  value={taxSubstitutionValue + ipiValue}
                />
              </span>
            </div>
            <div className="info -total">
              <span className="text">Total</span>
              <span className="price">
                <CurrencyTextFormatter
                  value={
                    subtotal - discount + (taxSubstitutionValue + ipiValue)
                  }
                />
              </span>
            </div>
            <button
              onClick={handleNext}
              href="/pedidos/complemento"
              className="button"
            >
              Continuar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

Addition.propTypes = {};

export default Addition;
