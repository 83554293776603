import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";

import "./style.scss";
import api from "../../services/api";
import Field from "../../shared/Form/Field";
import FormValidation from "../../shared/helpers/FormValidation";
import PrimaryButton from "../../shared/PrimaryButton";
import BottomButtonContainer from "../../shared/BottomButtonContainer";

function SellerStep({ nextStep, updateForm, recommendationForm }) {
  const [fieldHasError, setFieldHasError] = useState({});
  const [customErrorMessage, setCustomErrorMessage] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      seller_cpf: recommendationForm.seller_cpf || "",
    },
    onSubmit: (values) => {
      setIsLoading(true);
      api
        .post(`/api/v1/client_recommendations`, {
          client_recommendation: {
            seller_cpf: values.seller_cpf,
            verify_attributes: ["seller_cpf"],
          },
        })
        .then(() => {
          setIsLoading(false);
          if (nextStep) {
            nextStep();
          }
        })
        .catch((err) => {
          FormValidation({
            err,
            setFieldHasError,
            setCustomErrorMessage,
            setRefresh,
            refresh,
            setIsLoading,
          });
        });
      updateForm(values);
    },
  });
  return (
    <div className="container recommendation-about-seller">
      <h2>Sobre você, informe:</h2>
      <form onSubmit={formik.handleSubmit}>
        <div className="formrow">
          <Field
            name="seller_cpf"
            label="Seu CPF"
            placeholder="Informe o seu CPF"
            formHandler={formik}
            className="field"
            hasError={fieldHasError.seller_cpf}
            customError={customErrorMessage.seller_cpf}
            refresh={refresh}
            mask="999.999.999-99"
          />
        </div>
        <BottomButtonContainer hasSeparator>
          <PrimaryButton type="submit">
            {isLoading ? (
              <div className="spinner -white -mg-auto"></div>
            ) : (
              "Continuar"
            )}
          </PrimaryButton>
        </BottomButtonContainer>
      </form>
    </div>
  );
}

SellerStep.propTypes = {
  nextStep: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
};

export default SellerStep;
