import React from "react";

import cardIcon from "../../../../assets/representativePage/card-icon.svg";
import cashIcon from "../../../../assets/representativePage/cash-icon.svg";
import tagIcon from "../../../../assets/representativePage/tag-icon.svg";
import bluTag from "../../../../assets/representativePage/blu-tag.svg";
import reminderIcon from "../../../../assets/representativePage/icn-reminder.svg";

import "./style.scss";

export default function Advantages({ hideOnMobile }) {
  return (
    <div className="advantages" hideonmobile={hideOnMobile}>
      <img className="blutag" src={bluTag} alt="Só na Blu" />
      <div className="reminder-content">
        <img src={reminderIcon} width="40" height="40" alt="Lembre-se" />
        <h5 className="reminder-title">Lembre-se</h5>
        <p className="reminder-text">
          Pagar com a Blu é sempre a melhor opção:
        </p>
      </div>
      <ul className="list">
        <li className="item">
          <img className="item-icon" src={cardIcon} alt="Cartão" />
          <div>
            <h5 className="item-main">Venda em até 12x</h5>
            <p className="item-info">
              sem se preocupar com seu fluxo de caixa.
            </p>
          </div>
        </li>
        <li className="item">
          <img className="item-icon" src={cashIcon} alt="Dinheiro" />
          <div>
            <h5 className="item-main">
              Compre sem análise ou limite de crédito
            </h5>
            <p className="item-info">com nossos fornecedores parceiros.</p>
          </div>
        </li>
        <li className="item">
          <img className="item-icon" src={tagIcon} alt="Economia" />
          <div>
            <h5 className="item-main">Economize até 10%</h5>
            <p className="item-info">
              com taxa zero de antecipação para compra com fornecedor.
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
}
