import React from "react";

import "./style.scss";
import orderIcon from "../../../../assets/recommendations/icn-pedido.svg";

function FeedbackSharedList(props) {
  return (
    <div className="list-win-150">
      <ul className="list">
        <h2 className="listtitle">
          <strong>Ganhe R$ 150</strong> em cada nova indicação 🤑
        </h2>
        <li className="item">
          <img className="image" src={orderIcon} />
          <p className="paragraph">
            Indique outros clientes para pagar <strong>esse fornecedor</strong>{" "}
            via Blu.
          </p>
        </li>
        <li className="item">
          <img className="image" src={orderIcon} />
          <p className="paragraph">
            Indique <strong>esse cliente</strong> para pagar outros fornecedores
            via Blu.
          </p>
        </li>
      </ul>
    </div>
  );
}

export default FeedbackSharedList;
