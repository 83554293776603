import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import PropTypes from "prop-types";

import "./style.scss";
import api from "../../../services/api";
import Field from "../../../shared/Form/Field";
import SelectField from "../../../shared/Form/SelectField";
import DetailHeader from "../../../shared/DetailHeader";
import FixedButton from "../../../shared/Form/FixedButton";

const Detail = ({
  clientInfo,
  clientFound,
  productSelectionUrl,
  currentSeller,
}) => {
  const [ufs, setUfs] = useState([]);
  const [fieldHasError, setFieldHasError] = useState({});
  const [customErrorMessage, setCustomErrorMessage] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [isLoadingAddress, setIsLoadingAddress] = useState(false);

  const hasClient = clientInfo.client && true;
  let hasAddress;

  let client;
  try {
    client = JSON.parse(localStorage.client);
    if (client.cpfCnpj !== clientInfo.client.cpf_cnpj) {
      localStorage.removeItem("localCartItems");
    }
  } catch {}

  if (hasClient) hasAddress = clientInfo.client.main_address && true;

  const formik = useFormik({
    initialValues: {
      businessName: (hasClient && clientInfo.client.business_name) || "",
      tradingName: (hasClient && clientInfo.client.name) || "",
      stateRegistration:
        (hasClient && clientInfo.client.state_registration) || "",
      cep:
        hasAddress && clientInfo.client.main_address.zipcode
          ? clientInfo.client.main_address.zipcode
          : "",
      address: hasAddress ? clientInfo.client.main_address.street : "",
      number: hasAddress ? clientInfo.client.main_address.number : "",
      complement: hasAddress ? clientInfo.client.main_address.complement : "",
      state: hasAddress ? clientInfo.client.main_address.state : "",
      city: hasAddress ? clientInfo.client.main_address.city : "",
      neighbourhood: hasAddress
        ? clientInfo.client.main_address.neighborhood
        : "",
      phone: (hasClient && clientInfo.client.phone) || "",
      clientEmail: (hasClient && clientInfo.client.responsible_email) || "",
      clientName: (hasClient && clientInfo.client.responsible_name) || "",
    },
    onSubmit: (values) => {
      api
        .post("/api/v1/orders", {
          order: {
            seller_id: currentSeller.cpf_cnpj,
            client_cpf_cnpj: clientInfo.client.cpf_cnpj,
            client_business_name: values.businessName,
            client_name: values.tradingName,
            client_phone: values.phone,
            client_state_registration: values.stateRegistration,
            address_city: values.city,
            address_complement: values.complement,
            address_neighborhood: values.neighbourhood,
            address_number: values.number,
            address_state: values.state,
            address_street: values.address,
            address_zipcode: values.cep,
            client_responsible_email: values.clientEmail,
            client_responsible_name: values.clientName,
            verify_attributes: [
              "client_business_name",
              "client_name",
              "client_phone",
              "address_city",
              "address_neighborhood",
              "address_number",
              "address_state",
              "address_street",
              "address_zipcode",
              "client_responsible_email",
              "client_responsible_name",
              "client_state_registration",
            ],
          },
        })
        .then(() => {
          localStorage.client = JSON.stringify({
            ...values,
            cpfCnpj: clientInfo.client.cpf_cnpj,
            status: clientInfo.status,
          });
          Turbolinks.visit(productSelectionUrl);
        })
        .catch((err) => {
          if (err.response.data.errors.length > 0) {
            const errors = err.response.data.errors;
            let tempHasError = {};
            let tempErrorMessage = {};

            errors.forEach((error) => {
              tempHasError = { ...tempHasError, [error.type]: true };
              tempErrorMessage = {
                ...tempErrorMessage,
                [error.type]: error.messages[0],
              };
            });
            setFieldHasError(tempHasError);
            setCustomErrorMessage(tempErrorMessage);
            // setIsLoading(false);
            setRefresh(!refresh);
          }
        });
    },
  });

  async function handleGetUfs() {
    const response = await axios.get(
      "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
    );
    const mappedUfs = [];
    response.data.map((el) =>
      mappedUfs.push({ value: el.nome, label: el.nome, id: el.id })
    );
    setUfs(mappedUfs);
  }

  useEffect(() => {
    handleGetUfs();
  }, []);

  useEffect(() => {
    if (formik.values.cep.length === 9) {
      setIsLoadingAddress(true);
      api.get(`/api/v1/ceps/${formik.values.cep}`).then((res) => {
        const { city, neighborhood, state, street } = res.data.main_address;
        formik.setFieldValue("city", city || "");
        formik.setFieldValue("neighbourhood", neighborhood || "");
        formik.setFieldValue("state", state || "");
        formik.setFieldValue("address", street || "");
        setIsLoadingAddress(false);
      });
    }
  }, [formik.values.cep]);

  return (
    <div className="container">
      <DetailHeader url="/pedidos" title="Dados do cliente" />
      <form onSubmit={formik.handleSubmit} className="client-form">
        {clientFound ? (
          <div className="clientfound">
            <div className="field">
              <div className="fieldtitle">CNPJ ou CPF</div>
              <div className="fieldvalue">{clientInfo.client.cpf_cnpj}</div>
            </div>
            <hr className="separator" />
            <div className="field">
              <div className="fieldtitle">Razão Social</div>
              <div className="fieldvalue">{clientInfo.client.name}</div>
            </div>
            <hr className="separator" />
            <div className="field">
              <div className="fieldtitle">Nome Fantasia</div>
              {clientInfo.client.business_name}
              <div className="fieldvalue"></div>
            </div>
            <hr className="separator" />
            <div className="field">
              <div className="fieldtitle">Endereço</div>
              <div className="fieldvalue">
                {`${clientInfo.client.main_address.street}, 
							${clientInfo.client.main_address.number} - 
							${clientInfo.client.main_address.neighborhood}, 
							${clientInfo.client.main_address.city}, 
							${clientInfo.client.main_address.state} `}
              </div>
            </div>

            <div className="formrow">
              <Field
                label="Inscrição estadual (opcional)"
                name="stateRegistration"
                formHandler={formik}
                hasError={fieldHasError.client_state_registration}
                customError={customErrorMessage.client_state_registration}
                refresh={refresh}
              />

              <Field
                label="Nome do responsável"
                name="clientName"
                formHandler={formik}
                hasError={fieldHasError.client_responsible_name}
                customError={customErrorMessage.client_responsible_name}
                refresh={refresh}
              />
            </div>

            <div className="formrow">
              <Field
                label="Email do responsável"
                name="clientEmail"
                formHandler={formik}
                hasError={fieldHasError.client_responsible_email}
                customError={customErrorMessage.client_responsible_email}
                refresh={refresh}
              />

              <Field
                label="Telefone com DDD"
                name="phone"
                formHandler={formik}
                hasError={fieldHasError.client_phone}
                customError={customErrorMessage.client_phone}
                refresh={refresh}
                mask="(99) 99999-9999"
              />
            </div>
          </div>
        ) : (
          <>
            <div className="formrow">
              <Field
                label="Razão social"
                name="businessName"
                formHandler={formik}
                hasError={fieldHasError.client_business_name}
                customError={customErrorMessage.client_business_name}
                refresh={refresh}
              />
              <Field
                label="Nome Fantasia"
                name="tradingName"
                formHandler={formik}
                hasError={fieldHasError.client_name}
                customError={customErrorMessage.client_name}
                refresh={refresh}
              />
            </div>
            <div className="formrow">
              <Field
                label="Inscrição estadual (opcional)"
                name="stateRegistration"
                formHandler={formik}
                hasError={fieldHasError.client_state_registration}
                customError={customErrorMessage.client_state_registration}
                refresh={refresh}
              />
            </div>
            <div className="formrow">
              <div className="inputcontainer">
                <Field
                  label="CEP"
                  name="cep"
                  formHandler={formik}
                  hasError={fieldHasError.address_zipcode}
                  customError={customErrorMessage.address_zipcode}
                  refresh={refresh}
                  mask="99999-999"
                  isDisabled={isLoadingAddress}
                />
                {isLoadingAddress ? <div className="spinner" /> : null}
              </div>

              <Field
                label="Endereço"
                name="address"
                formHandler={formik}
                hasError={fieldHasError.address_street}
                customError={customErrorMessage.address_street}
                refresh={refresh}
                isDisabled={isLoadingAddress}
              />
            </div>
            <div className="formrow">
              <Field
                label="Número"
                name="number"
                formHandler={formik}
                hasError={fieldHasError.address_number}
                customError={customErrorMessage.address_number}
                refresh={refresh}
                mask="9999999"
              />
              <Field
                label="Complemento"
                name="complement"
                formHandler={formik}
              />
            </div>
            <div className="formrow">
              <SelectField
                label="Estado"
                name="state"
                options={ufs}
                formHandler={formik}
                hasError={fieldHasError.address_state}
                customError={customErrorMessage.address_state}
                refresh={refresh}
                isDisabled={isLoadingAddress}
              />
              <Field
                label="Cidade"
                name="city"
                formHandler={formik}
                hasError={fieldHasError.address_city}
                customError={customErrorMessage.address_city}
                refresh={refresh}
                isDisabled={isLoadingAddress}
              />
            </div>
            <div className="formrow">
              <Field
                label="Bairro"
                name="neighbourhood"
                formHandler={formik}
                hasError={fieldHasError.address_neighborhood}
                customError={customErrorMessage.address_neighborhood}
                refresh={refresh}
                isDisabled={isLoadingAddress}
              />
            </div>
            <div className="formrow">
              <Field
                label="Nome do responsável"
                name="clientName"
                formHandler={formik}
                hasError={fieldHasError.client_responsible_name}
                customError={customErrorMessage.client_responsible_name}
                refresh={refresh}
              />

              <Field
                label="Email do responsável"
                name="clientEmail"
                formHandler={formik}
                hasError={fieldHasError.client_responsible_email}
                customError={customErrorMessage.client_responsible_email}
                refresh={refresh}
              />
            </div>
            <div className="formrow">
              <Field
                label="Telefone com DDD"
                name="phone"
                formHandler={formik}
                hasError={fieldHasError.client_phone}
                customError={customErrorMessage.client_phone}
                refresh={refresh}
                mask="(99) 99999-9999"
              />
            </div>
          </>
        )}
        <FixedButton />
      </form>
    </div>
  );
};

Detail.propTypes = {
  clientFound: PropTypes.bool.isRequired,
  clientInfo: PropTypes.object,
  productSelectionUrl: PropTypes.string.isRequired,
  currentSeller: PropTypes.object.isRequired,
};

Detail.defaultProps = {
  clientInfo: { client: {} },
};

export default Detail;
