import React from "react";

import "./style.scss";

import chevronRight from "../../../assets/myAccount/chevron-right.svg";

export default function MenuItemMobile({ title, subtitle, onClick, className }) {
  return (
    <div className={`menu-list-item-mobile ${className}`} onClick={onClick}>
      <div className="textcontainer">
        <span className="title">{title}</span>
        {subtitle ? <span className="subtitle">{subtitle}</span> : null}
      </div>
      <img className="chevron" src={chevronRight} />
    </div>
  );
}
