import "select2";
import "./select2_locale_pt-BR";

$(document).on("turbolinks:before-cache", function() {
  if ($("select").data("select2")) {
    $("select").select2("destroy");
  }
});

$(document).ready(ready);
$(document).ajaxComplete(ready);
$(document).on("turbolinks:load", ready);

function ready() {
  const personTypeRadios = $('input:radio[name=cpf_or_cnpj]');
  const lastPersonTypeSelected = localStorage.getItem('signup_person_type') || 'cpf' ;
  const cpfField = $("#seller_user_cpf")
  if(personTypeRadios.length) {  
    personTypeRadios.filter('[value=' + lastPersonTypeSelected + ']').attr('checked', true);
  }

  if (lastPersonTypeSelected === 'cpf'){
    $('#user_cnpj').hide()                        
    $("label[for='seller_user_cpf']").text("CPF");
    cpfField.attr("placeholder", "Informe o seu CPF");
  } else {
    $("#seller_user_cpf").show()
    $("label[for='seller_user_cpf']").text('CPF do representante legal')
    cpfField.attr("placeholder", "Informe o CPF");
  }

  const sellerCustomDistributor = $("#seller_custom_distributor").val();

  const states = $("select.states").select2({
    placeholder: "Informe uma região",
    language: "pt-BR"
  });

  const statesNew = $("select.statesNew").select2({
    placeholder:"Selecione uma opção",
    language: "pt-BR"
  });

  const knowHow = $("select.know_how_blu").select2({
    minimumResultsForSearch: -1,
    placeholder: "Já conhecia a Blu? *",
    language: "pt-BR"
  });

  const storesCount = $("select.stores_count").select2({
    minimumResultsForSearch: -1,
    placeholder: "É uma rede ou loja única? *",
    language: "pt-BR"
  });

  const distributorName = $("select.distributor_name_1").select2({
    placeholder: "Principal Fornecedor que atende a loja *",
    tags: true,
    language: "pt-BR"
  });

  const sellerDistributorName1 = $("select.seller_distributor_name_1").select2({
    placeholder: "Informe o principal fornecedor",
    tags: true,
    language: "pt-BR"
  });

  const sellerDistributorName1New = $("select.seller_distributor_name_1_new").select2({
    placeholder: "Selecione uma opção",
    tags: true,
    language: "pt-BR"
  });

  const onSelectChange = function() {
    $(this).trigger("blur");
    if(this.id === 'seller_distributor_name'){
      $("[aria-labelledby='select2-seller_distributor_name-container']").attr('style', 'border-color: #d3d4de !important');
    }
    if(this.id === 'seller_state'){
      $("[aria-labelledby='select2-seller_state-container']").attr('style', 'border-color: #d3d4de !important');
    }
  };

  const onRadioChange = function() {
    const persontTypeCheckedValue = $('input:radio[name=cpf_or_cnpj]:checked').val()
    if(persontTypeCheckedValue === 'cnpj') {
      $("#seller_cpf_cnpj").rules("add", {
        required : true,
        cnpj: true,
        messages : { cnpj : 'O CNPJ informado não é válido.' }
      });
    } else {
      $("#seller_cpf_cnpj").rules( 'remove', 'cnpj' );
    }
    localStorage.setItem('signup_person_type', persontTypeCheckedValue);
  };

  personTypeRadios.on("change", onRadioChange);
  states.on("change", onSelectChange);
  statesNew.on("change", onSelectChange);
  knowHow.on("change", onSelectChange);
  storesCount.on("change", onSelectChange);
  distributorName.on("change", onSelectChange);
  sellerDistributorName1.on("change", onSelectChange);
  sellerDistributorName1New.on("change", onSelectChange);

  $("select.stores_count").on("change", function(e) {
    const isntUniqueStore = $(e.target).val() !== "Loja única";

    $(".client_recommendation_stores_count .hint").toggle(isntUniqueStore);
  });

  $("select.distributor_name_1").select2({
    placeholder: "Principal Fornecedor que atende a loja",
    tags: true,
    language: "pt-BR"
  });

  $("select.blu_seller_name").select2({
    placeholder: "Vendedor Blu",
    language: "pt-BR"
  });

  $("select.seller_distributor_name_1").on("change", function(e) {
    if ($(e.target).val() == "Outro") {
      $("#seller_custom_distributor").css("display", "block");
    } else {
      $("#seller_custom_distributor").css("display", "none");
      $("#seller_custom_distributor").val("");
    }
  });

  $("select.seller_distributor_name_1_new").on("change", function(e) {
    if ($(e.target).val() == "Outro") {
      $("#seller_custom_distributor").css("display", "block");
    } else {
      $("#seller_custom_distributor").css("display", "none");
      $("#seller_custom_distributor").val("");
    }
  });

  if (sellerCustomDistributor && sellerCustomDistributor.length > 1) {
    $("#seller_custom_distributor").css("display", "block");
    $("select.seller_distributor_name_1")
      .val("Outro")
      .trigger("change.select2");
    $("select.seller_distributor_name_1_new")
    .val("Outro")
    .trigger("change.select2");
  }
  
  $("select.seller_other_distributors").select2({
    placeholder:
      "Informe outro fornecedor",
    tags: true,
    language: "pt-BR"
  });

  $("select.seller_other_distributors_new").select2({
    placeholder:
      "Informe o fornecedor",
    tags: true,
    language: "pt-BR"
  });
}
