$(document).on("ready turbolinks:load", function () {
  const textFields =
    "form input[type='text'], form input[type='tel'], form input[type='email'], form input[type='password']";

  const onFocus = function () {
    $(this).parent().addClass("focused");
  };

  const onBlur = function () {
    $(this).parent().removeClass("focused");
  };

  const onChange = function () {
    const value = $(this).val();

    if (value !== "") {
      $(this).parent().addClass("filled");
    } else {
      $(this).parent().removeClass("filled");
    }
  };

  $(textFields).on("focus", onFocus);
  $(textFields).on("blur", onBlur);
  $(textFields).on("change", onChange);

  $(textFields).each(function () {
    const value = $(this).val();

    if (value === "") {
      return;
    }

    $(this).parent().addClass("filled");
  });

  $(".toggle-password-visibility button").on("click", function () {
    const className = "toggle-password-visibility--visible";
    const wrapper = $(this).parent();
    const input = wrapper.find("input");

    const isVisible = wrapper.hasClass(className);

    console.log("isVisible", isVisible);

    if (isVisible) {
      wrapper.removeClass(className);
      input.prop("type", "password");
    } else {
      wrapper.addClass(className);
      input.prop("type", "text");
    }
  });
});
