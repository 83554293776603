import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

function SecondaryButton({ className, children, borderless, ...rest }) {
  return (
    <button
      {...rest}
      className={`secondary-button ${borderless && "borderless"} ${className}`}
    >
      {children}
    </button>
  );
}

SecondaryButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  borderless: PropTypes.bool,
};

SecondaryButton.defaultProps = {
  className: "",
  children: null,
  borderless: false,
};

export default SecondaryButton;
