$.fn.dashboardTabs = function(params) {
    const $tabs = this;

    const onChange = (href) => {
        // Remove all active classNames
        $tabs.find('.list > .item').removeClass("-active");
        $tabs.find('.content > .item').removeClass("-active");
    
        // Add active className in desired element
        $tabs.find(`.list > .item > a[href='${href}']`).parent().addClass("-active");
        $tabs.find(`.content > .item${href}`).addClass("-active");
    };

    // Set default active tab
    $tabs.find(`.list > .item > a[href='${params.active}']`).parent().addClass("-active");
    $tabs.find(`.content > .item${params.active}`).addClass("-active");
    
    // On tab item click
    $tabs.find(".list > .item > a").on("click", function() {
        const item = this;
        const href = item.getAttribute("href");

        onChange(href);
    });


    $("[data-js-change-tab]").on("click", function(event) {
        const href = $(this).attr('data-js-change-tab');

        event.preventDefault();
        onChange(href);
    });
}

$(document).on("ready turbolinks:load", function () {
    $(".dashboard-tabs").dashboardTabs({
        active: "#resumo"
    });
});
