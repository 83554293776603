import React from "react";
import "./style.scss";
import PropTypes from "prop-types";

const FixedButton = ({ label, children, clickAction }) => {
	return (
		<div className="fixed-button">
			{children}
			<button className="button button--primary" type="submit" onClick={clickAction}>
				{label}
			</button>
		</div>
	);
};

FixedButton.propTypes = {
	label: PropTypes.string,
	clickAction: PropTypes.func,
};

FixedButton.defaultProps = {
	label: "Continuar",
};

export default FixedButton;
