import React, { useState, useRef, useEffect } from "react";

import ClientInfo from "../../shared/ClientInfo";
import DetailHeader from "../../shared/DetailHeader";
import SearchForm from "./SearchForm";
import ProductsList from "./ProductsList";
import ProductDetail from "../ProductDetail";
import Summary from "./Summary";
import api from "../../services/api";

const ProductSelection = ({ currentSeller }) => {
  const [product, setProduct] = useState({});
  const [cartItems, setCartItems] = useState(
    JSON.parse(localStorage.getItem("localCartItems")) || []
  );
  const [cartItem, setCartItem] = useState(undefined);
  const [showDetail, setShowDetail] = useState(false);
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);

  const [products, setProducts] = useState([]);
  const [productCount, setProductCount] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [suppliers, setSuppliers] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [searchFieldValue, setSearchFieldValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [foundProducts, setFoundProducts] = useState([]);

  const firstSearchUpdate = useRef(true);

  async function handleFindProducts(_suppliers, _currentPage = currentPage) {
    if (searchValue !== "") {
      await api
        .get(
          `/api/v1/suppliers/${_suppliers[0].cpf_cnpj}/products?name=${searchValue}&page=${currentPage}`
        )
        .then((res) => {
          const tempProducts = [];
          res.data.products.map((_product) => {
            tempProducts.push(_product);
          });
          setFoundProducts([...foundProducts, ...tempProducts]);
          setProducts([...foundProducts, ...tempProducts]);
          setCurrentPage(currentPage + 1);
          setHasMore(!res.data.last_page);
          setProductCount(res.data.total_pages);
          setIsLoading(false);
        });
    } else {
      await api
        .get(
          `/api/v1/suppliers/${_suppliers[0].cpf_cnpj}/products?page=${currentPage}`
        )
        .then((res) => {
          const tempProducts = [];
          res.data.products.map((_product) => {
            tempProducts.push(_product);
          });
          setProducts([...products, ...tempProducts]);
          setCurrentPage(currentPage + 1);
          setHasMore(!res.data.last_page);
          setProductCount(res.data.total_pages);
          setIsLoading(false);
        });
    }
  }

  async function handleFindSuppliers() {
    await api
      .get(`api/v1/sellers/${currentSeller.cpf_cnpj}/suppliers`)
      .then((res) => {
        const tempSuppliers = [];
        res.data.suppliers.map((supplier) => {
          tempSuppliers.push(supplier);
        });
        setSuppliers(tempSuppliers);
        handleFindProducts(tempSuppliers, currentPage);
      });
  }

  function handleSearch(value) {
    setCurrentPage(1);
    setFoundProducts([]);
    setProducts([]);
    setSearchValue(value);
    setIsLoading(true);
  }

  useEffect(() => {
    handleFindSuppliers();
  }, []);

  //Responsible for searching
  useEffect(() => {
    if (firstSearchUpdate.current && searchValue === "") {
      firstSearchUpdate.current = false;
      return;
    }

    if (suppliers) {
      handleFindProducts(suppliers);
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchFieldValue !== searchValue && searchFieldValue === "") {
      handleSearch("");
      setSearchValue("");
    }
  }, [searchFieldValue]);

  //If there is not a parsable client go back
  let client;
  try {
    client = JSON.parse(localStorage.client);
  } catch {
    Turbolinks.visit("/pedidos");
  }

  function handleHideDetail() {
    setShowDetail(false);
  }

  function handleShowDetail(_product) {
    setShowDetail(true);
    setProduct(_product);
  }

  function handleToggleSummary() {
    isSummaryOpen ? setIsSummaryOpen(false) : setIsSummaryOpen(true);
    setCartItem(undefined);
  }

  function handleAddCartItem(item) {
    setCartItems([...cartItems, item]);
    localStorage.setItem(
      "localCartItems",
      JSON.stringify([...cartItems, item])
    );
  }

  function handleRemoveCartItem(item) {
    const tempCartItems = cartItems.filter((el) => {
      return el.internalId !== item.internalId;
    });

    setCartItems(tempCartItems);
    localStorage.setItem("localCartItems", JSON.stringify(tempCartItems));
  }

  function handleEditCartItem(_item) {
    let items = [...cartItems];
    let itemIndex = cartItems.findIndex(
      (el) => _item.internalId === el.internalId
    );
    let item = { ...items[itemIndex] };

    item.quantity = _item.quantity;
    item.discounts = _item.discounts;

    items[itemIndex] = item;
    setCartItems(items);
    setCartItem(undefined);
    localStorage.setItem("localCartItems", JSON.stringify(items));
  }

  function handleDetailEdit(_cartItem) {
    setCartItem(_cartItem);
    setShowDetail(true);
  }

  useEffect(() => {
    if (!cartItems.length > 0) {
      setIsSummaryOpen(false);
    }
  }, [cartItems]);

  return (
    <>
      {showDetail ? (
        <ProductDetail
          product={cartItem ? cartItem.product : product}
          backAction={handleHideDetail}
          addItem={handleAddCartItem}
          editItem={handleEditCartItem}
          cartItem={cartItem}
          cartItemsLength={cartItems.length}
        />
      ) : (
        <>
          {!isSummaryOpen && (
            <div className="product-selection container">
              <DetailHeader url="/pedidos" title="Seleção de produtos" />
              <ClientInfo cpfCnpj={client.cpfCnpj} name={client.tradingName} />
              <SearchForm
                onSearch={handleSearch}
                updateSearchFieldValue={setSearchFieldValue}
                isLoading={isLoading}
              />
              <ProductsList
                products={products}
                suppliers={suppliers}
                hasMore={hasMore}
                findMore={handleFindProducts}
                currentSeller={currentSeller}
                goToDetail={handleShowDetail}
                productCount={productCount}
              />
            </div>
          )}
          {cartItems.length > 0 && (
            <Summary
              isOpen={isSummaryOpen}
              handleToggle={handleToggleSummary}
              items={cartItems}
              removeItem={handleRemoveCartItem}
              goToDetailEdit={handleDetailEdit}
            />
          )}
        </>
      )}
    </>
  );
};

export default ProductSelection;
