$(window).on('load', function() {
    const ua = navigator.userAgent.toLowerCase(),
        isAndroid = ua.indexOf("android") > -1,
        isIOS = ua.indexOf("iphone") > -1 || ua.indexOf("ipad") > -1;
    
    const isMobile = isAndroid || isIOS,
        isMobileResolution = $(window).width() <= 768,
        isMobileContext = isMobile && isMobileResolution

    if(isMobileContext){
        $('.dashboard-status-card > .content').removeClass('scroll')
    }
});
