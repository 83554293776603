export const Types = {
  SET_TAB: "opportunities/SET_TAB",
  SELECT_OPPORTUNITY: "opportunities/SELECT_OPPORTUNITY",
  RECENTLY_UPDATED: "opportunities/RECENTLY_UPDATED",
  RECENTLY_DISCARDED: "opportunities/RECENTLY_DISCARDED",

  REQUEST: "opportunities/REQUEST",
  SUCCESS: "opportunities/SUCCESS",
  FAIL: "opportunities/FAIL",
};

const INITIAL_STATE = {
  tab: "pending",
  rawList: [],
  openList: [],
  closedList: [],
  loading: false,
  error: false,
  processErrors: [],
  selectedOpportunity: {},
  recentlyUpdated: {},
  recentlyDiscarded: {}
};

export default function opportunities(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_TAB:
      return {
        ...state,
        tab: action.payload.tab,
      };

    case Types.SELECT_OPPORTUNITY:
      return {
        ...state,
        selectedOpportunity: action.payload.selectedOpportunity,
      };
    case Types.RECENTLY_UPDATED:
      return {
        ...state,
        recentlyUpdated: action.payload.recentlyUpdated,
      };
      case Types.RECENTLY_DISCARDED:
        return {
          ...state,
          recentlyDiscarded: action.payload.recentlyDiscarded,
        };
  
    case Types.REQUEST:
      return {
        ...state,
        loading: true,
        sellerCpf: action.payload.sellerCpf,
      };

    case Types.SUCCESS:
      return {
        ...state,
        loading: false,
        rawList: action.payload.list,
        openList: action.payload.list.filter(
          (opportunity) => opportunity.status === "open"
        ),
        closedList: action.payload.list.filter(
          (opportunity) =>
            opportunity.status === "archived" || opportunity.status === "done"
        ),
      };

    case Types.FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };

    default:
      return state;
  }
}

export const Creators = {
  setTab: () => {
    return {
      type: Types.SET_TAB,
    };
  },

  requestOpportunities: () => {
    return {
      type: Types.REQUEST,
    };
  },

  selectOpportunity: () => {
    return {
      type: Types.SELECT_OPPORTUNITY,
    };
  },

  recentlyUpdated: () => {
    return {
      type: Types.RECENTLY_UPDATED,
    };
  },
  recentlyDiscarded: () => {
    return {
      type: Types.RECENTLY_DISCARDED,
    };
  },
  
};
