$(document).on("ready turbolinks:load", function () {
	//First slide
	let nextButtonId = "next1";
	let firstSlideId = "current1";
	let prevButtonId = "prev1";

	//Second slide
	let nextButtonId2 = "next2";
	let firstSlideId2 = "current2";
	let prevButtonId2 = "prev2";

	//Shared 'active' class
	let activeClass = ".slider-item--active";

	/**
	 * Function responsible for creating the Slider
	 * Receives the IDs for a 'next' and 'previous' buttons
	 * also receives the class name used for the active slide state and the first slide to display
	 * @param {string} _nextButton
	 * @param {string} _prevButton
	 * @param {string} _firstSlide
	 * @param {string} active
	 */
	function createSlider(_nextButton, _prevButton, _firstSlide, active) {
		let nextButton = $("#" + _nextButton);
		let prevButton = $("#" + _prevButton);
		let firstSlide = $("#" + _firstSlide);
		let currentSlideClassId = ("current-slide" + _firstSlide).toString();
		firstSlide.addClass(currentSlideClassId);

		// Next button action
		nextButton.on("click", function () {
			let currentSlide = $("." + currentSlideClassId);
			let nextSlide = currentSlide.next();

			if (nextSlide.length) {
				currentSlide.addClass("slide-left"); //Sliding left animation
				currentSlide.removeClass("show-up"); //Clearing show-up to avoid animation conflicts

				setTimeout(() => {
					currentSlide.removeClass(active);
					currentSlide.removeClass(currentSlideClassId);
					nextSlide.addClass("show-up"); //'Becoming current item' animation
					nextSlide.addClass(currentSlideClassId);
					nextSlide.addClass(active);
					currentSlide.removeClass("slide-left");

					currentSlide.removeClass("slider-item--active").css("z-index", -10);
					nextSlide.addClass("slider-item--active").css("z-index", 10);
				}, 300);

				prevButton.removeClass("item-disabled");
				nextButton.removeClass("item-disabled");

				if (!nextSlide.next().length) {
					nextButton.addClass("item-disabled");
				}
			} else {
				nextButton.addClass("item-disabled");
			}
		});

		// Previous button action
		prevButton.on("click", function () {
			let currentSlide = $("." + currentSlideClassId);
			let prevSlide = currentSlide.prev();

			if (prevSlide.length) {
				currentSlide.addClass("slide-right"); //Sliding right animation
				currentSlide.removeClass("show-up"); //Clearing show-up to avoid animation conflicts

				setTimeout(() => {
					currentSlide.removeClass(active);
					currentSlide.removeClass(currentSlideClassId);
					prevSlide.addClass("show-up"); //'Becoming current item' animation
					prevSlide.addClass(currentSlideClassId);
					prevSlide.addClass(active);
					currentSlide.removeClass("slide-right");

					currentSlide.removeClass("slider-item--active").css("z-index", -10);
					prevSlide.addClass("slider-item--active").css("z-index", 10);
				}, 300);

				nextButton.removeClass("item-disabled");
				prevButton.removeClass("item-disabled");

				if (!prevSlide.prev().length) {
					prevButton.addClass("item-disabled");
				}
			} else {
				prevButton.addClass("item-disabled");
			}
		});
	}

	/*
	 * createSlider function called for each slider in page
	 */
	createSlider(nextButtonId, prevButtonId, firstSlideId, activeClass);
	createSlider(nextButtonId2, prevButtonId2, firstSlideId2, activeClass);
});
