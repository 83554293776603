import React, { useEffect, useState } from "react";
import { useFormik } from "formik";

import "./style.scss";
import negativeStatusImg from "../../../assets/recommendations/negative-status.svg";
import api from "../../services/api";
import Field from "../../shared/Form/Field";
import PrimaryButton from "../../shared/PrimaryButton";
import BottomButtonContainer from "../../shared/BottomButtonContainer";

import Backdrop from "../../shared/Backdrop";
import DetailHeader from "../../shared/DetailHeader";

function ClientStep({
  nextStep,
  previousStep,
  updateForm,
  goToOrderIndication,
  recommendationForm,
  setClientStatus,
}) {
  const [fieldHasError, setFieldHasError] = useState({});
  const [customErrorMessage, setCustomErrorMessage] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [showBackdrop, setShowBackdrop] = useState(false);

  const formik = useFormik({
    initialValues: {
      client_cpf_cnpj: recommendationForm.client_cpf_cnpj || "",
    },
    onSubmit: (values) => {
      setIsLoading(true);
      api
        .post(`/api/v1/client_recommendations`, {
          client_recommendation: {
            client_cpf_cnpj: values.client_cpf_cnpj,
            seller_cpf: recommendationForm.seller_cpf,
          },
        })
        .then((res) => {})
        .catch((err) => {
          setIsLoading(false);
          let statusMessages = err.response.data.errors.find(
            (x) => x.type === "status"
          )?.messages;
          if (
            statusMessages?.includes(
              "another_recommendation"
            ) || statusMessages?.includes("already_blu_client")
          ) {
            setShowBackdrop(true);
          } else {
            const errors = err.response.data.errors;
            if (errors.some((e) => e.type === "client_cpf_cnpj")) {
              setFieldHasError({ client_cpf_cnpj: true });
              setCustomErrorMessage({
                client_cpf_cnpj: errors.find(
                  (e) => e.type === "client_cpf_cnpj"
                ).messages[0],
              });
              setRefresh(!refresh);
            } else {
              updateForm(values);
              nextStep();
            }
          }
        });
    },
  });

  return (
    <>
      <DetailHeader
        title="Sobre seu cliente, informe:"
        desktopOnly
        showBackArrow={false}
      />
      <div className="container recommendation-about-client">
        <form onSubmit={formik.handleSubmit}>
          <div className="formrow">
            <Field
              name="client_cpf_cnpj"
              label="CNPJ do cliente"
              placeholder="Informe o CNPJ do cliente"
              formHandler={formik}
              className="field"
              hasError={fieldHasError.client_cpf_cnpj}
              customError={customErrorMessage.client_cpf_cnpj}
              refresh={refresh}
              mask="99.999.999/9999-99"
            />
          </div>
          <BottomButtonContainer hasSeparator>
            <PrimaryButton type="submit">
              {isLoading ? (
                <div className="spinner -white -mg-auto"></div>
              ) : (
                "Continuar"
              )}
            </PrimaryButton>
          </BottomButtonContainer>
        </form>

        <Backdrop
          isOpen={showBackdrop}
          close={() => setShowBackdrop(false)}
          showCloseButton
        >
          <div className="imgcontainer">
            <img src={negativeStatusImg} alt="Sem sucesso" />
          </div>
          <h1 className="backdroptitle">Ops, indicação inválida.</h1>
          <p className="backdropparagraph">
            Essa indicação já pode ter sido realizada ou esse cliente já é Blu.
            Faça outra indicação para continuar.
          </p>
          <div className="buttons">
            <PrimaryButton
              onClick={() => {
                formik.setFieldValue("client_cpf_cnpj", "");
                setShowBackdrop(false);
              }}
              className="custombutton"
            >
              Fazer outra indicação
            </PrimaryButton>
          </div>
        </Backdrop>
      </div>
    </>
  );
}

export default ClientStep;
