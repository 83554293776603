import { combineReducers } from "redux";

import dashboardActiveTab from "./dashboardActiveTab";
import dashboardRecommendations from "./dashboardRecommendations";
import dashboardFilter from "./dashboardFilter";
import opportunities from "./opportunities";
import personalizedPage from "./personalizedPage";

const rootReducer = combineReducers({
  dashboardActiveTab,
  dashboardRecommendations,
  dashboardFilter,
  opportunities,
  personalizedPage,
});

export default rootReducer;
