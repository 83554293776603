import React, { useEffect, useState } from "react";

import "./style.scss";
import Card from "../../shared/Card";
import chevronRight from "../../../assets/general/chevron-right.svg";
import arrowRight from "../../../assets/general/arrow-right.svg";
import arrowRightOrange from '../../../assets/dashboard/arrow-right-orange.svg'
import moneyBag from "../../../assets/dashboard/ilustra-money-bag.svg";
import opportunitiesImage from '../../../assets/opportunities/opportunities-cut.svg';
import PrimaryButton from '../../shared/PrimaryButton';
import SecondaryButton from '../../shared/SecondaryButton';

import { connect } from "react-redux";
import { Types } from "../../../store/ducks/dashboardActiveTab";
import { Types as FilterTypes } from "../../../store/ducks/dashboardFilter";
import { Types as OpportunitiesTypes } from "../../../store/ducks/opportunities";

function Cards({
  currentSeller,
  setActiveTab,
  activeTab,
  recommendations,
  requestOpportunities,
  opportunities,
  setFilter,
  filters,
}) {
  const [invalidCounter, setInvalidCounter] = useState(0);
  const [intermediateCounter, setIntermediateCounter] = useState(0);
  const [hasSpent500Counter, setHasSpent500Counter] = useState(0);
  const [hasUserLoggedCounter, setHasUserLoggedCounter] = useState(0);
  const [processingCounter, setProcessingCounter] = useState(0);

  // Load every quantity for client recommendation cards (separated in individual variables for easier legibility)
  useEffect(() => {
    requestOpportunities({ sellerCpf: currentSeller.cpf_cnpj });
    let tempProcessingCounter = 0,
      tempInvalidCounter = 0,
      tempIntermediateCounter = 0,
      tempHasSpent500Counter = 0,
      tempHasUserLoggedCounter = 0;

    recommendations.report_client_recommendations.map((el) => {
      switch (el.status) {
        case "intermediate":
          tempIntermediateCounter++;
          break;
        case "processing":
          tempProcessingCounter++;
          break;
        case "invalid":
        case "another_recommendation":
        case "already_blu_client":
          tempInvalidCounter++;
          break;
        case "has_user_logged":
          tempHasUserLoggedCounter++;
          break;
        case "has_spent_500":
          tempHasSpent500Counter++;
          break;
        default:
          break;
      }
    });

    setProcessingCounter(tempProcessingCounter);
    setIntermediateCounter(tempIntermediateCounter);
    setInvalidCounter(tempInvalidCounter);
    setHasSpent500Counter(tempHasSpent500Counter);
    setHasUserLoggedCounter(tempHasUserLoggedCounter);
  }, [recommendations]);

  function handleClientCards(name) {
    setFilter({
      ...filters,
      filterByStatus: [name],
    });
    setActiveTab({ activeTab: "recommendations" });
  }

  return (
    <div className="cards">
      <div className="opportunities-cta-mobile">
        <div className="main">
          <h2 className="ctatitle">
            Oportunidades pendentes {" "}
            <div className="badge badge--dashboard">{opportunities.openList.length}</div>
          </h2>
          <a className="link" href="/oportunidades"> Ver tudo <img src={arrowRightOrange} /></a>
        </div>
        <img src={opportunitiesImage} />
      </div>

      <div className="opportunities-cta-desktop">
        <img src={opportunitiesImage} />
        <div className="main">
          <h2 className="ctatitle">
            Você tem <strong>{opportunities.openList.length}</strong> oportunidades pendentes
          </h2>
          <p className="ctadescription">Acompanhe informações importantes para potencializar seus ganhos aqui na Blu.</p>
        </div>
        <div className="buttoncontainer">
          <PrimaryButton onClick={() => { Turbolinks.visit('/oportunidades') }} style={{ whiteSpace: "nowrap" }}>Ver oportunidades</PrimaryButton>
          <SecondaryButton onClick={() => { Turbolinks.visit('/oportunidades?page=description')}} style={{ border: 0, background: 'transparent', whiteSpace: "nowrap" }}>Saiba mais</SecondaryButton>
        </div>
      </div>

      <div className="titlecontainer">
        <h2 className="listtitle">Indicação premiada </h2>
        <a
          onClick={() => {
            setFilter({
              ...filters,
              filterByStatus: [
                "processing",
                "invalid",
                "has_user_logged",
                "has_spent_500",
                "intermediate",
              ],
            });
            setActiveTab({ activeTab: "recommendations" });
          }}
          className="link"
        >
          Ver tudo <img src={chevronRight} />
        </a>
      </div>
      <div className="cards-list scroll">
        <Card
          title="Cliente indicado"
          color="cyan"
          tooltip="A indicação foi validada e está sendo tratada por nossa equipe."
          onClick={() => handleClientCards("processing")}
          value={processingCounter}
        />
        <Card
          title="Fechou com a Blu!"
          tooltip="Sua indicação se tornou cliente Blu. Vamos enviar a maquininha para as vendas começarem o quanto antes."
          onClick={() => handleClientCards("has_user_logged")}
          value={hasUserLoggedCounter}
        />
        <Card
          title="Começou a vender"
          color="yellow"
          tooltip="Já iniciou as vendas usando a Blu mas ainda não atingiu o mínimo de R$500 em vendas."
          onClick={() => handleClientCards("intermediate")}
          value={intermediateCounter}
        />
        <Card
          title="Vendeu R$500 ou mais"
          color="green"
          tooltip="O cliente atingiu o mínimo em vendas e você ganha R$500 por essa indicação."
          onClick={() => handleClientCards("has_spent_500")}
          value={hasSpent500Counter}
        />
        <Card
          title="Indicação inválida"
          color="gray"
          tooltip="Ops, a indicação foi realizada por outra pessoa antes de você ou então ela já é cliente Blu."
          onClick={() => handleClientCards("invalid")}
          value={invalidCounter}
        />
      </div>

      <div className="recommendcta">
        <img src={moneyBag} className="image" />
        <div className="text">
          <h3>
            Continue indicando e aumente seus <strong>ganhos!</strong>
          </h3>
          <a href="/indicacao-premiada">
            Indicar agora <img src={arrowRight} />
          </a>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveTab: (payload) => {
      dispatch({ type: Types.SET_ACTIVE_TAB, payload });
    },
    setFilter: (payload) => {
      dispatch({ type: FilterTypes.SET_FILTER, payload });
    },
    requestOpportunities: (payload) => {
      dispatch({ type: OpportunitiesTypes.REQUEST, payload });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    dashboardActiveTab: state.dashboardActiveTab,
    recommendations: state.dashboardRecommendations,
    filters: state.dashboardFilter,
    opportunities: state.opportunities,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cards);
