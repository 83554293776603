import React from "react";
import PropTypes from "prop-types";

import "./style.scss";
import imageNotFound from "../../../assets/checkout/image-not-found.svg";
import imageNotFoundMd from "../../../assets/checkout/image-not-found-md.svg";

function ItemImage({ image, size }) {
  return (
    <div className="item-image">
      <div className="content">
      {{
        thumb: image ? (
          <img src={image.thumb} className="imgloader" />
        ) : (
          <img src={imageNotFound} />
        ),
        sm: image ? <img src={image.sm} /> : <img src={imageNotFound} />,
        md: image ? <img src={image.md} className="imgloader -md" /> : <img src={imageNotFoundMd} />,
        lg: image ? <img src={image.lg} className="imgloader -lg" /> : <img src={imageNotFoundMd}  />,
      }[size] || <img src={imageNotFound} />}
      </div>
    </div>
  );
}

ItemImage.propTypes = {
  image: PropTypes.object,
  size: PropTypes.string.isRequired,
};

ItemImage.defaultProps = {
  image: undefined,
  size: "thumb",
};

export default ItemImage;
