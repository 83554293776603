import React from "react";
import PropTypes from "prop-types";

import "./style.scss";

function BottomButtonContainer({ children, hasSeparator }) {
  return (
    <div
      className={
        hasSeparator
          ? "bottom-button-container -has-separator"
          : "bottom-button-container"
      }
    >
      {children}
    </div>
  );
}

BottomButtonContainer.propTypes = {
  children: PropTypes.any.isRequired,
  hasSeparator: PropTypes.bool,
};

BottomButtonContainer.defaultProps = {
  hasSeparator: false,
};
export default BottomButtonContainer;
