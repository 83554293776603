import React from "react";

import Advantages from "../Advantages";
import Field from "../../../shared/Form/Field";
import Title from "../Title";

export default function RepData({
  form,
  fieldHasError,
  customErrorMessage,
  refresh,
  modalClose
}) {
  return (
    <div className="company-data">
      <div style={{ width: "100%", maxWidth: 456 }}>
        <Title close={modalClose} />
        <p className="step-info">
          Agora, precisamos dos dados do{" "}
          <strong>representante legal da sua empresa.</strong>
        </p>
        <div className="form-row">
          <Field
            label="Nome completo"
            name="user_name"
            placeholder="Informe o nome completo"
            formHandler={form}
            hasError={fieldHasError.user_name}
            customError={customErrorMessage.user_name}
            refresh={refresh}
          />
          <Field
            label="E-mail"
            name="user_email"
            placeholder="Informe o e-mail"
            formHandler={form}
            hasError={fieldHasError.user_email}
            customError={customErrorMessage.user_email}
            refresh={refresh}
          />
        </div>
        <div className="form-row">
          <Field
            label="CPF"
            name="user_cpf"
            placeholder="Informe o CPF"
            formHandler={form}
            hasError={fieldHasError.user_cpf}
            customError={customErrorMessage.user_cpf}
            refresh={refresh}
            mask="999.999.999-99"
            />
          <Field
            label="Celular com DDD"
            name="user_phone"
            placeholder="Informe o celular"
            formHandler={form}
            hasError={fieldHasError.user_phone}
            customError={customErrorMessage.user_phone}
            refresh={refresh}
            mask="(99) 99999-9999"
            />
        </div>
        <div className="form-row">
          <Field
            label="Outro telefone (opcional)"
            name="user_phone_2"
            placeholder="Informe outro telefone"
            formHandler={form}
            hasError={fieldHasError.user_phone_2}
            customError={customErrorMessage.user_phone_2}
            refresh={refresh}
            mask="(99) 99999-9999"
            />
        </div>
      </div>

      <Advantages />
    </div>
  );
}
