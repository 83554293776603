import React, { Component } from "react";

import Search from "./Search";
import Detail from "./Detail";

export default class Identification extends Component {
  constructor(props) {
    super(props);
    this.state = { showDetail: false };
    this.handleClientInfo = this.handleClientInfo.bind(this);
  }

  handleClientInfo(values, clientFound) {
    this.setState({ clientInfo: values, showDetail: true, clientFound });
  }

  render() {
    return (
      <div>
        {this.state.showDetail ? (
          <Detail
            clientFound={this.state.clientFound}
            clientInfo={this.state.clientInfo}
            productSelectionUrl={this.props.productSelectionUrl}
            currentSeller={this.props.currentSeller}
          />
        ) : (
          <Search handleState={this.handleClientInfo} />
        )}
      </div>
    );
  }
}
