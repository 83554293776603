$(document).on("ready turbolinks:load", function () {

    /**
     * Hide all dropdowns if the element clicked is the navigation parent.
     */
    $('.header__navigation').on('click', function(event) {
        const target = event.target;
        const isNavigation = target.tagName === 'NAV';

        if (isNavigation) {
            $(".dropdown").removeClass("dropdown--visible");
            $(".dropdown__content").slideUp();
        }
    });

    $('.dropdown__btn').on('click', function() {
        const parent = $(this).parent();

        // 1. Iterates over all `.dropdown` elements
        $(".dropdown").each(function() {
            // 2. Check if the current item is the parent clicked element
            const isParent = $(this).is(parent);

            // 2.1 If truthy, toggle the `.dropdown--visible` className
            if (isParent) {
                $(this).toggleClass("dropdown--visible");
                $(this).find('.dropdown__content').slideToggle();
            // 2.2 If falsy, remove the `.dropdown--visible` className
            } else {
                $(this).removeClass("dropdown--visible");
                $(this).find('.dropdown__content').slideUp();
            }
        });
    });
});