import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./style.scss";

function BooleanField({
  name,
  type,
  label,
  value,
  formHandler,
  customClass,
  hasError,
  refresh,
  handleRadioGroup,
  isRow,
  isBold,
  labelColor,
  onChange,
  ...rest
}) {
  const checkboxClass = `order-checkbox ${customClass || ""}`;
  const radioClass = `order-radio ${customClass || ""}`;
  const BoolFieldClass = type === "checkbox" ? checkboxClass : radioClass;
  const [hasCustomError, setHasCustomError] = useState(hasError);

  useEffect(() => {
    setHasCustomError(hasError);
  }, [hasError, refresh]);

  return (
    <label
      className={hasCustomError ? `${BoolFieldClass} -error` : BoolFieldClass}
      style={{ paddingLeft: isRow ? 0 : 30 }}
    >
      <input
        {...rest}
        onChange={(e) => {
          formHandler.handleChange(e);
          setHasCustomError(false);
          handleRadioGroup(name);
          onChange();
        }}
        type={type}
        name={name}
        value={value}
        checked={formHandler.values[name].includes(value)}
        className="visually-hidden"
      />
      <span
        className={type === "checkbox" ? "checkmark" : "radio-select"}
        style={{ left: isRow ? "unset" : 0, right: isRow ? 0 : "unset" }}
      ></span>
      <span
        className="label"
        style={{ color: labelColor, fontWeight: isBold ? 600 : 500 }}
      >
        {label}
      </span>
    </label>
  );
}

BooleanField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  formHandler: PropTypes.object.isRequired,
  customClass: PropTypes.string,
  hasError: PropTypes.bool,
  refresh: PropTypes.bool,
  handleRadioGroup: PropTypes.func,
  isRow: PropTypes.bool,
  onChange: PropTypes.func,
  isBold: PropTypes.bool,
  labelColor: PropTypes.string
};

BooleanField.defaultProps = {
  customClass: "",
  hasError: false,
  refresh: false,
  isRow: false,
  isBold: false,
  labelColor: '',
  handleRadioGroup: () => {},
  onChange: () => {},
};

export default BooleanField;
