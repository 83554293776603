import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SearchIcon from "../../../../assets/checkout/icn-busca.svg";
import useDebounce from "../../../../hooks/use-debounce";
import "./style.scss";

const SearchForm = ({ onSearch, updateSearchFieldValue, isLoading }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 700);

  useEffect(() => {
    if (!debouncedSearchTerm) {
      return;
    }

    onSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    updateSearchFieldValue(searchTerm);
  }, [searchTerm]);

  return (
    <form className="search-form">
      <div className="input-field -has-icon">
        <img className="icon" src={SearchIcon} />
        <input
          className="input"
          value={searchTerm}
          placeholder="Busque pelo nome do produto"
          type="text"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {isLoading ? <div className="spinner"></div> : null}
      </div>
    </form>
  );
};

SearchForm.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default SearchForm;
