import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "./style.scss";
import WarningIcon from "../../../../assets/form/warning.svg";

const SelectField = ({
  name,
  label,
  options,
  defaultOption,
  formHandler,
  hasError,
  customError,
  refresh,
  isDisabled,
}) => {
  const [hasCustomError, setHasCustomError] = useState(hasError);
  const isInvalid = formHandler.errors[name] && formHandler.touched[name];

  useEffect(() => {
    setHasCustomError(hasError);
  }, [hasError, refresh]);

  return (
    <div className="select-field">
      <label htmlFor={name} className="label">
        {label}
      </label>
      <select
        id={name}
        className={isInvalid || hasCustomError ? "input error" : "input"}
        name={name}
        onChange={formHandler.handleChange}
        value={formHandler.values[name]}
        onChange={(e) => {
          formHandler.handleChange(e);
          setHasCustomError(false);
        }}
        disabled={isDisabled}
      >
        <option value={defaultOption.value}> {defaultOption.label} </option>
        {options.map((option) => (
          <option key={option.id} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {isInvalid && (
        <>
          <img src={WarningIcon} className="erroricn" />
          <span className="errormsg"> {formHandler.errors[name]} </span>
        </>
      )}
      {hasCustomError && (
        <>
          <img src={WarningIcon} className="erroricn" />
          <span className="errormsg"> {customError} </span>
        </>
      )}
    </div>
  );
};

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  defaultOption: PropTypes.object,
  formHandler: PropTypes.object.isRequired,
  hasError: PropTypes.bool,
  customError: PropTypes.string,
  refresh: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

SelectField.defaultProps = {
  label: "Select",
  defaultOption: { label: "Selecione uma opção", value: "" },
  hasError: false,
  customError: "",
  refresh: false,
  isDisabled: false,
};

export default SelectField;
