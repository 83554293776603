import React from "react";

import pointRight from "../../../assets/home/point-right.svg";
import table from "../../../assets/home/table.svg";
import contentIcon from "../../../assets/home/order-outline.svg";
import CardCta from "../../shared/CardCta";

import "./style.scss";

export default function ActionCards() {
  return (
    <div>
      <div className="container logged-home-action-cards">
        <div className="cards">
          <CardCta
            action={() => Turbolinks.visit("/indicacao-premiada")}
            img={pointRight}
            text="Fazer indicação"
          />

          <CardCta
            action={() => Turbolinks.visit("/dashboard/acompanhar-indicacoes")}
            img={table}
            text="Minhas indicações"
          />

          <CardCta
            action={() =>
              window.open("https://blogrep.blu.com.br/category/capacitacao-blu/", "_blank")
            }
            img={contentIcon}
            text="Material de capacitação"
          />
        </div>
      </div>
    </div>
  );
}
