import React from "react";

import "./style.scss";
import checkIcon from "../../../assets/opportunities/check.svg";
import phoneIcon from "../../../assets/opportunities/icn-phone.svg";
import obsIcon from "../../../assets/opportunities/observation.svg";

import { connect } from "react-redux";
import DetailHeader from "../../shared/DetailHeader";
import CpfCnpjFormatter from "../../shared/CpfCnpjFormatter";

function OpportunityUpdated({ changePage, opportunities }) {
  const date = new Date(
    Date.parse(opportunities.selectedOpportunity.updated_at)
  );

  return (
    <>
      <DetailHeader title="" backAction={() => changePage("list")} />
      <div className="container opportunity-updated">
        <div className="content">
          <div className="feedbackbox">
            <img src={checkIcon} />
            <p>
              Você já informou um telefone ou uma observação sobre a indicação e
              iremos tentar outro contato.
            </p>
          </div>
          <h1 className="title">Informações atualizadas</h1>
          <p className="description -black">
            Estes foram os dados que você informou:
          </p>
          {opportunities.selectedOpportunity?.business_action === "update_user_phone" ? (
            <div className="clientinfo">
              <div className="image">
                <img src={phoneIcon} />
              </div>
              <div className="text">
                <span>Telefone</span>
                <span className="primary">
                  {
                    opportunities.selectedOpportunity.opportunitable_object
                      .client_recommendation.user_phone
                  }
                </span>
              </div>
            </div>
          ) : null}

          {opportunities.selectedOpportunity.comments ? (
            <div className="clientinfo">
              <div className="image">
                <img src={obsIcon} />
              </div>
              <div className="text">
                <span>Observação</span>
                <span className="primary">
                  {opportunities.selectedOpportunity.comments}
                </span>
              </div>
            </div>
          ) : null}
          <hr className="separator" />
          <p className="description -black -sm-margin">
            Detalhes da oportunidade
          </p>
          <p className="description -sm">
            Não conseguimos falar com o cliente{" "}
            <strong>
              {
                opportunities.selectedOpportunity.opportunitable_object
                  .client_recommendation.client_name
              }{" "}
              (
              <CpfCnpjFormatter
                unmaskedCpfCnpj={
                  opportunities.selectedOpportunity.opportunitable_object
                    .client_recommendation.client_cpf_cnpj
                }
              />
              ).
            </strong>
          </p>
          <div className="footnote">
            <span>
              Oportunidade finalizada em{" "}
              {date.toLocaleDateString("pt-BR", {
                hour: "numeric",
                minute: "numeric",
              })}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    opportunities: state.opportunities,
  };
};
export default connect(mapStateToProps)(OpportunityUpdated);
