import React, { useEffect, useState } from "react";
import OpportunityDetailTemplate from "../OpportunityDetailTemplate";
import noContactImage from "../../../assets/opportunities/no-contact.svg";
import noInterestImage from "../../../assets/opportunities/no-interest.svg";
import { connect } from "react-redux";
import BluBenefitsList from "./BluBenefitsList";

function OpportunityRefusalDetail({ changePage, opportunities }) {
  const [image, setImage] = useState(noContactImage);
  const [title, setTitle] = useState(
    "Não conseguimos falar com o decisor do seu cliente"
  );
  const [content, setContent] = useState(<></>);

  useEffect(() => {
    switch (opportunities.selectedOpportunity?.payload?.reason_for_refusal) {
      case "Não conseguiu falar mais com o decisor":
        setImage(noContactImage);
        setTitle("Não conseguimos falar com o decisor do seu cliente");
        setContent(
          <>
            <p className="description">
              Talvez a gente não tenha ligado no melhor horário ou o decisor não
              estava esperando pelo nosso contato.
              <br />
              <br />
              <strong>
                Você pode conversar novamente com o seu lojista, reforçar os
                benefícios Blu e avisar que entraremos em contato.
              </strong>
            </p>
          </>
        );
        break;
      case "O lojista não deixou explicar a solução da Blu":
        setImage(noInterestImage);
        setTitle("Seu cliente não demonstrou interesse em conhecer a Blu :(");
        setContent(
          <>
            <p className="description">
              Que tal ajudá-lo a entender todos os benefícios da nossa solução e
              garantir sua indicação?
            </p>
            <BluBenefitsList />
            <p className="description">
              Com a Blu, o seu cliente compra mais e você vende mais!
            </p>
          </>
        );
        break;

      case "Representante não falou da Blu":
        setImage(noInterestImage);
        setTitle("Seu cliente nos informou que ainda não conhece a Blu :(");
        setContent(
          <>
            <p className="description">
              Que tal ajudá-lo a entender todos os benefícios da nossa solução e
              garantir sua indicação?
            </p>
            <BluBenefitsList />
            <p className="description">
              Com a Blu, o seu cliente compra mais e você vende mais!
            </p>
          </>
        );
        break;
      default:
        break;
    }
  }, [opportunities.selectedOpportunity?.payload?.reason_for_refusal]);

  return (
    <div>
      <OpportunityDetailTemplate
        image={image}
        showPhone={false}
        changePage={changePage}
        secondaryAction={() => changePage("discard")}
        primaryAction={() => changePage("refusalProcess")}
        title={title}
        content={content}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    opportunities: state.opportunities,
  };
};

export default connect(mapStateToProps)(OpportunityRefusalDetail);
