import React from "react";

import "./style.scss";
import notPending from "../../../../assets/opportunities/not-pending.svg";

export default function NoOpenOpportunities() {
  return (
    <div className="no-open-opportunities">
      <div className="imgcontainer">
        <img src={notPending} style={{ margin: "auto" }} />
      </div>
      <p className="notfoundtext">
        Muito bem! <br />
        Você finalizou todas as oportunidades pendentes. Em breve enviaremos
        novas oportunidades para você.
      </p>
    </div>
  );
}
