import React from "react";

import "./style.scss";
import close from "../../../assets/general/close-blue.svg";
import opportunitiesImage from "../../../assets/opportunities/opportunities.svg";

function OpportunitiesDescription({ changePage }) {
  return (
    <>
      <div className="container">
        <img
          onClick={() => changePage("list")}
          src={close}
          style={{ marginBottom: 24, cursor: "pointer" }}
        />
      </div>
      <div className="container opportunities-description">
        <div className="imgcontainer">
          <img src={opportunitiesImage} />
        </div>

        <div className="content">
          <h1 className="title">Não perca nenhuma oportunidade!</h1>

          <p className="description">
            Aqui você acompanha informações importantes para o seu dia a dia com
            a Blu: desde indicações que precisam de uma atenção especial até
            atualizações que vão ajudar a melhorar o seu desempenho na
            Comunidade Rep Nota 1.000.
          </p>
        </div>
      </div>
    </>
  );
}

export default OpportunitiesDescription;
