$(document).on("ready turbolinks:load", function () {
	// Defining modals, triggers and closing buttons
	let trigger = {
		passwordReset: $("#passwordReset"),
		passwordResetSuccess: $("#passwordResetSuccess"),
	};

	let modalCloseButton = $(".modal-close-button")
	let modal = $('.modal')

	/**
	 * Function responsible for showing and closing modal
	 */

	function toggleModal(_modal) {
		_modal.toggleClass("show-modal");
	}

	// Trigger Buttons

	trigger.passwordReset.on("click", function () {
		toggleModal($("#passwordResetModal"));
	});

	trigger.passwordResetSuccess.on("click", function(){
		toggleModal($("#passwordResetSuccessModal"))
	});

	// Close Buttons

	modalCloseButton.on("click", function(){
		$(this).parents('.modal').toggleClass('show-modal')
	})

	//Close by clicking outside

	modal.on("click", function(e){
		if (e.target !== this) return;
		modal.removeClass('show-modal')
	})

	$(document).keydown(function(event) { 
		if (event.keyCode == 27) { 
		  modal.removeClass('show-modal');
		}
	});
});
