import React from "react";

import "./style.scss";
import PrimaryButton from "../../../shared/PrimaryButton";
import indicatedClientImage from "../../../../assets/representativePage/cliente-indicado.svg";
import Title from "../Title";

export default function StatusError({ handleOkGotIt, modalClose }) {
  return (
    <>
      <Title close={modalClose} hideOnDesktop />
      <div className="modal-status-error">
        <img src={indicatedClientImage} alt="indication_img" />
        <div className="content">
          <h2 className="title">Ops, algo deu errado.</h2>
          <p className="paragraph">
            O CNPJ informado já pode ter sido indicado ou pode corresponder a um
            cliente que já é Blu.
          </p>
          <p className="paragraph">
            Entre em contato com o representante comercial que te enviou este
            link para saber mais detalhes de como prosseguir.
          </p>

          <PrimaryButton onClick={handleOkGotIt}>Ok, entendi</PrimaryButton>
        </div>
      </div>
    </>
  );
}
