import React, { useState } from "react";
import { useFormik } from "formik";
import PropTypes from "prop-types";

import "./style.scss";
import Field from "../../../shared/Form/Field";
import OrderIcon from "../../../../assets/form/icn-pedido.svg";
import api from "../../../services/api";

const validate = (values) => {
  let errors = {};

  if (!values.cpfCnpj) {
    errors.cpfCnpj = "Este campo não pode ficar em branco.";
  }

  return errors;
};

const Search = ({ handleState }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [customError, setCustomError] = useState("");
  const [refresh, setRefresh] = useState(false);

  const formik = useFormik({
    initialValues: {
      cpfCnpj: "",
    },
    onSubmit: (values) => {
      setIsLoading(true);
      api
        .get(`/api/v1/clients/find_by_cpf_cnpj?cpf_cnpj=${values.cpfCnpj}`)
        .then((res) => {
          const isBluClient = res.data.status === 'not_blu_client' ? false : true;
          handleState(res.data, isBluClient);
        })
        .catch((err) => {
          if (err.response.data.errors.length > 0) {
            const errors = err.response.data.errors;
            setHasError(true);
            setCustomError(errors[0].messages[0]);
            setIsLoading(false);
            setRefresh(!refresh);
          }
        });
    },
    validate,
  });

  return (
    <div className="container ">
      <div className="order-search">
        <div className="neworder">
          <img src={OrderIcon} />
          <h5>Novo Pedido</h5>
        </div>
        <div className="heading">
          <h2> Para começar, informe: </h2>
        </div>
        <form onSubmit={formik.handleSubmit} className="form">
          <Field
            label="CPF ou CNPJ"
            name="cpfCnpj"
            placeholder="Informe o CNPJ ou CPF do cliente"
            formHandler={formik}
            hasError={hasError}
            customError={customError}
            refresh={refresh}
            mask={
              formik.values.cpfCnpj.length > 14
                ? "99.999.999/9999-99"
                : "999.999.999-999999"
            }
          />
          <button
            disabled={isLoading}
            className="button button--primary"
            type="submit"
          >
            {isLoading ? <div className="spinner"></div> : "Continuar"}
          </button>
        </form>
      </div>
    </div>
  );
};

Search.propTypes = {
  handleState: PropTypes.func.isRequired,
};

export default Search;
