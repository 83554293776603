import React, { useState } from "react";

import "./style.scss";
import DetailHeader from "../../shared/DetailHeader";
import PrimaryButton from "../../shared/PrimaryButton";
import SecondaryButton from "../../shared/SecondaryButton";
import TextAreaField from "../../shared/Form/TextAreaField";

import discardImage from "../../../assets/opportunities/discard.svg";
import { useFormik } from "formik";
import api from "../../services/api";
import FormValidation from "../../shared/helpers/FormValidation";

import { connect } from "react-redux";
import { Types } from "../../../store/ducks/opportunities";

function OpportunityDiscard({
  changePage,
  currentSeller,
  recentlyDiscarded,
  opportunities,
}) {
  const [fieldHasError, setFieldHasError] = useState({});
  const [customErrorMessage, setCustomErrorMessage] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      negative_comments: "",
    },
    onSubmit: (values) => {
      setIsLoading(true);
      api
        .post(`/api/v1/opportunities/archive_business_action`, {
          opportunity: {
            id: opportunities.selectedOpportunity.id,
            seller_cpf: currentSeller.cpf_cnpj,
            payload: {
              negative_comments: values.negative_comments,
            },
            comments: values.comments,
          },
        })
        .then((res) => {
          setIsLoading(false);
          recentlyDiscarded({
            recentlyDiscarded: {
              negative_comments: values.negative_comments,
              client_name:
                opportunities.selectedOpportunity.opportunitable_object
                  .client_recommendation.client_name,
            },
          });
          console.log(res.data);
          changePage("discardSuccess");
        })
        .catch((err) => {
          FormValidation({
            err,
            setFieldHasError,
            setCustomErrorMessage,
            setRefresh,
            refresh,
            setIsLoading,
          });
        });
    },
  });

  return (
    <>
      <DetailHeader
        title=""
        backAction={() =>
          opportunities.selectedOpportunity.business_action ===
          "update_user_phone"
            ? changePage("detail")
            : changePage("refusalDetail")
        }
      />
      <div className="container opportunity-discard">
        <div className="imgcontainer">
          <img src={discardImage} />
        </div>
        <div className="content">
          <h1 className="title">Deseja mesmo descartar a oportunidade?</h1>

          <p className="description">
            Ao descartar a oportunidade sua indicação será excluída e outros
            representantes poderão ganhar indicando este cliente.
            <br />
            <br />
            Essa ação não poderá ser desfeita mas você poderá reindicar o
            cliente.
          </p>
          <form onSubmit={formik.handleSubmit}>
            <TextAreaField
              label="Por favor, informe o motivo"
              placeholder="Ex: O cliente não tem mais interesse na solução da Blu."
              name="negative_comments"
              formHandler={formik}
              hasError={fieldHasError.negative_comments}
              customError={customErrorMessage.negative_comments}
              refresh={refresh}
            />

            <div className="buttoncontainer">
              <PrimaryButton type="submit" className="discard">
                {isLoading ? (
                  <div className="spinner -mg-auto -white"></div>
                ) : (
                  "Descartar oportunidade "
                )}
              </PrimaryButton>
              <SecondaryButton
                type="reset"
                onClick={() => changePage("detail")}
              >
                Manter oportunidade
              </SecondaryButton>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    recentlyDiscarded: (payload) => {
      dispatch({ type: Types.RECENTLY_DISCARDED, payload });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    opportunities: state.opportunities,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityDiscard);
