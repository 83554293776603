import React from "react";

import "./style.scss";
import Cards from "../../Cards";
import RecommendationsList from "../../RecommendationsList";
import DetailHeader from "../../../shared/DetailHeader";

import { connect } from "react-redux";
import { Types } from "../../../../store/ducks/dashboardActiveTab";

function Mobile({ setActiveTab, dashboardActiveTab, recommendations, currentSeller }) {
  return (
    <>
      {{
        overview: (
          <DetailHeader title="Minhas indicações" showBackArrow={false} />
        ),
        recommendations: (
          <DetailHeader
            title="Indicação premiada"
            backAction={() => setActiveTab({ activeTab: "overview" })}
          />
        ),
      }[dashboardActiveTab.activeTab] || <Cards />}

      <div className="cardscontainer">
        <div className="container">
          {{
            overview: <Cards currentSeller={currentSeller}/>,
            recommendations: <RecommendationsList />,
          }[dashboardActiveTab.activeTab] || <Cards />}
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveTab: (payload) => {
      dispatch({ type: Types.SET_ACTIVE_TAB, payload });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    dashboardActiveTab: state.dashboardActiveTab,
    recommendations: state.dashboardRecommendations,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mobile);
