import React from "react";
import PropTypes from "prop-types";

import "./style.scss";
import ItemImage from "../../../shared/ItemImage";

const ProductImage = ({ images }) => {
  return (
    <div className="imagecontainer">
      <div className="images">
        {/* <img src={imageSrc} /> */}
        <ItemImage image={images[0]} size="md" />
      </div>
    </div>
  );
};

ProductImage.propTypes = {
  images: PropTypes.array,
};

ProductImage.defaultProps = {
  images: [],
};

export default ProductImage;
