import React from "react";

import "./style.scss";
import warn from "../../../assets/opportunities/warn.svg";
import obsIcon from "../../../assets/opportunities/observation.svg";

import { connect } from "react-redux";
import DetailHeader from "../../shared/DetailHeader";
import CpfCnpjFormatter from "../../shared/CpfCnpjFormatter";

function OpportunityDiscarded({ changePage, opportunities }) {
  const date = new Date(
    Date.parse(
      opportunities.selectedOpportunity.opportunitable_object
        .client_recommendation.archived_at
    )
  );
  return (
    <>
      <DetailHeader title="" backAction={() => changePage("list")} />
      <div className="container opportunity-discarded">
        <div className="content">
          <div className="feedbackbox">
            <img src={warn} />
            <p>
              Você descartou esta oportunidade mas ainda pode indicar este
              cliente novamente.
            </p>
          </div>

          <h1 className="title">Informações atualizadas</h1>

          <p className="description -black">
            Estes foram os dados que você informou:
          </p>

          <div className="clientinfo">
            <div className="image">
              <img src={obsIcon} />
            </div>
            <div className="text">
              <span>Motivo</span>
              <span className="primary">
                {opportunities.selectedOpportunity.negative_comments}
              </span>
            </div>
          </div>

          <hr className="separator" />

          <p className="description -black -sm-margin">
            Detalhes da oportunidade
          </p>
          <p className="description -sm">
            Não conseguimos falar com o cliente{" "}
            <strong>
              {
                opportunities.selectedOpportunity.opportunitable_object
                  .client_recommendation.client_name
              }{" "}
              (
              <CpfCnpjFormatter
                unmaskedCpfCnpj={
                  opportunities.selectedOpportunity.opportunitable_object
                    .client_recommendation.client_cpf_cnpj
                }
              />
              ).
            </strong>
          </p>

          <div className="footnote">
            <span>
              {" "}
              Oportunidade finalizada em{" "}
              {date.toLocaleDateString("pt-BR", {
                hour: "numeric",
                minute: "numeric",
              })}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    opportunities: state.opportunities,
  };
};
export default connect(mapStateToProps)(OpportunityDiscarded);
