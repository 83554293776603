import React from "react";
import PropTypes from "prop-types";

import "./style.scss";
import CurrencyTextFormatter from "../../../shared/CurrencyTextFormatter";
import ItemImage from "../../../shared/ItemImage";
import { itemValues } from "../../../shared/helpers/itemValues";

function OverviewItem({ item }) {
  return (
    <div className="cart-item">
      <div className="product">
        <ItemImage image={item.product.images[0]} size="thumb" />

        <div className="info">
          <span className="code">{item.product.code}</span>
          <span className="name">{item.product.name}</span>
          <span className="code">
            {item.quantity} X{" "}
            <CurrencyTextFormatter value={item.product.unity_price} />
          </span>
          <span className="price">
            {itemValues(item, "discount") > 0 && (
              <span className="discount">
                <CurrencyTextFormatter value={itemValues(item, "subtotal")} />
              </span>
            )}
            <CurrencyTextFormatter value={itemValues(item, "total")} />
          </span>
        </div>
      </div>
    </div>
  );
}

OverviewItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default OverviewItem;
