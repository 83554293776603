import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import store from "../../store";

import OpportunitiesList from "./OpportunitiesList";
import OpportunitiesDescription from "./OpportunitiesDescription";
import OpportunityDetail from "./OpportunityDetail";
import OpportunityDetailUpdated from "./OpportunityDetailUpdated";
import OpportunityDiscard from "./OpportunityDiscard";
import OpportunityDiscarded from "./OpportunityDiscarded";
import OpportunityDiscardSuccess from "./OpportunityDiscardSuccess";
import OpportunityUpdated from "./OpportunityUpdated";
import OpportunityRefusalDetail from "./OpportunityRefusalDetail";
import OpportunityRefusalProcess from "./OpportunityRefusalProcess";

export default function Opportunities({ currentSeller }) {
  const [activePage, setActivePage] = useState("list");
  const urlParams = new URLSearchParams(window.location.search);
  const page = urlParams.get("page");

  useEffect(() => {
    if (page) {
      setActivePage(page);
    }
  }, []);

  return (
    <Provider store={store}>
      {{
        list: (
          <OpportunitiesList
            currentSeller={currentSeller}
            changePage={setActivePage}
            currentSeller={currentSeller}
          />
        ),
        description: <OpportunitiesDescription changePage={setActivePage} />,
        detail: (
          <OpportunityDetail
            currentSeller={currentSeller}
            changePage={setActivePage}
          />
        ),
        refusalDetail: (
          <OpportunityRefusalDetail
            currentSeller={currentSeller}
            changePage={setActivePage}
          />
        ),
        detailUpdatedSuccess: (
          <OpportunityDetailUpdated
            currentSeller={currentSeller}
            changePage={setActivePage}
          />
        ),
        refusalProcess: (
          <OpportunityRefusalProcess
            currentSeller={currentSeller}
            changePage={setActivePage}
          />
        ),
        discard: (
          <OpportunityDiscard
            currentSeller={currentSeller}
            changePage={setActivePage}
          />
        ),
        discardSuccess: (
          <OpportunityDiscardSuccess
            currentSeller={currentSeller}
            changePage={setActivePage}
          />
        ),
        updated: (
          <OpportunityUpdated
            currentSeller={currentSeller}
            changePage={setActivePage}
          />
        ),
        discarded: (
          <OpportunityDiscarded
            currentSeller={currentSeller}
            changePage={setActivePage}
          />
        ),
      }[activePage] || (
        <OpportunitiesList
          currentSeller={currentSeller}
          changePage={setActivePage}
          currentSeller={currentSeller}
        />
      )}
    </Provider>
  );
}
