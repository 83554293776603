import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./style.scss";
import missedCall from "../../../assets/opportunities/phone-missed.svg";
import finalizedCorner from "../../../assets/opportunities/finalized.svg";
import discardedCorner from "../../../assets/opportunities/discarded.svg";
import refusedIcon from "../../../assets/opportunities/hand-outline.svg";

import { connect } from "react-redux";
import { Types } from "../../../store/ducks/opportunities";

function OpportunityCard({
  isFinalized,
  status,
  businessAction,
  clientName,
  changePage,
  opportunity,
  selectOpportunity,
  isWidget,
  payload,
}) {
  const [isMobileWidth, setIsMobileWidth] = useState();
  const nextPage =
    businessAction === "update_user_phone"
      ? { done: "updated", archived: "discarded" }[status] || "detail"
      : { done: "updated", archived: "discarded" }[status] || "refusalDetail";

  function handleScreenSize() {
    const mql = window.matchMedia("(max-width: 1024px)");
    setIsMobileWidth(mql.matches);
  }

  useEffect(() => {
    handleScreenSize();
    window.addEventListener("resize", handleScreenSize);
    return () => {
      window.removeEventListener("resize", handleScreenSize);
    };
  }, []);

  return (
    <div
      className="opportunity-card"
      onClick={isWidget ? changePage : () => {}}
      style={{ cursor: isWidget ? "pointer" : "unset" }}
    >
      <div className="content">
        <div className="image">
          <img
            src={
              businessAction === "update_user_phone"
                ? missedCall
                : payload.reason_for_refusal ===
                  "Não conseguiu falar mais com o decisor"
                ? missedCall
                : refusedIcon
            }
          />
        </div>
        <div className="text">
          <h3 className="title">
            {businessAction === "update_user_phone"
              ? "Cliente sem contato"
              : payload.reason_for_refusal ===
                "Não conseguiu falar mais com o decisor"
              ? "Cliente sem contato"
              : "Indicação recusada"}
          </h3>
          {businessAction === "update_user_phone" ? (
            <p className="description">
              Não conseguimos falar com <strong>{clientName}</strong> no
              telefone informado.
            </p>
          ) : (
            {
              "Não conseguiu falar mais com o decisor": (
                <p className="description">
                  Não conseguimos falar o decisor do lojista{" "}
                  <strong>{clientName}</strong>
                </p>
              ),
              "O lojista não deixou explicar a solução da Blu": (
                <p className="description">
                  <strong>{clientName}</strong> não demonstrou interesse em
                  conhecer a Blu.
                </p>
              ),
              "Representante não falou da Blu": (
                <p className="description">
                  <strong>{clientName}</strong> informou que ainda não conhece a
                  Blu.
                </p>
              ),
            }[payload.reason_for_refusal]
          )}
        </div>
      </div>

      {!isWidget && !isMobileWidth ? (
        <div
          className="detailcta"
          onClick={() => {
            selectOpportunity({ selectedOpportunity: opportunity });
            changePage(nextPage);
          }}
        >
          <button>Ver detalhes</button>
        </div>
      ) : null}

      {isFinalized ? (
        <img
          src={status === "archived" ? discardedCorner : finalizedCorner}
          style={{ position: "absolute", right: 0, top: 0 }}
        />
      ) : null}
    </div>
  );
}

OpportunityCard.propTypes = {
  businessAction: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
};

OpportunityCard.defaultProps = {
  businessAction: "Cliente sem contato",
  clientName: "ABRILLAR MÓVEIS PLANEJADOS",
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectOpportunity: (payload) => {
      dispatch({ type: Types.SELECT_OPPORTUNITY, payload });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    opportunities: state.opportunities,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityCard);
