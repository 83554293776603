export const Types = {
  REQUEST: "personalizedPage/REQUEST",
  SUCCESS: "personalizedPage/SUCCESS",
  FAIL: "personalizedPage/FAIL",
};

const INITIAL_STATE = {
  pages: [],
  error: false,
  loading: false,
  noItemsFound: false,
};

export default function personalizedPage(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.SUCCESS:
      return {
        ...state,
        pages: action.payload.pages,
        loading: false,
        error: false,
      };

    case Types.FAIL:
      return { ...state, loading: false, error: true, noItemsFound: true };

    default:
      return state;
  }
}

export const Actions = {
  requestPersonalizedPageList: (sellerId) => ({
    type: Types.REQUEST,
    sellerId,
  }),
};
