import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import "./style.scss";
import closeIcon from "../../../assets/general/close.svg";
// import closeBlue from "../../../assets/general/close-blue.svg";

function Backdrop({ isOpen, children, close, showCloseButton, isFullScreen, customWidth, fixedHeight }) {
  const showClass = "backdrop-component -show";
  const hiddenClass = "backdrop-component";
  const [backdropClass, setBackdropClass] = useState(
    isOpen ? showClass : hiddenClass
  );

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      close();
    }
  }, []);

  const handleClick = (e) => {
    if (e.target.classList.contains(hiddenClass)) close();

    return;
  };

  useEffect(() => {
    isOpen ? setBackdropClass(showClass) : setBackdropClass(hiddenClass);
  }, [isOpen]);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick, false);
    };
  }, []);

  return (
    <div className={backdropClass}>
      <div className={`
        ${isFullScreen ? "content -full" : "content"} ${fixedHeight ? "fixed-height" : ""}
      `} 
      style={{ maxWidth: customWidth }}>
      {/* <img src={closeBlue} alt="Fechar" onClick={()=> close()}
       className={showCloseButton ? "close" : "close -hide"} />  */}
        <img
          src={closeIcon}
          alt="Fechar"
          className={showCloseButton ? "close" : "close -hide"}
          onClick={()=> close()}
        />
        {children}
      </div>
    </div>
  );
}

Backdrop.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  close: PropTypes.func.isRequired,
  showCloseButton: PropTypes.bool,
  customWidth: PropTypes.number,
  fixedHeight: PropTypes.bool 
};

Backdrop.defaultProps = {
  showCloseButton: false,
  customWidth: 480,
  fixedHeight: false
};

export default Backdrop;
