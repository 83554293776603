export const Types = {
  SET_FILTER: "dashboardFilter/SET_FILTER",
};

const INITIAL_STATE = {
  filterStr: "",
  orderFilterStr: "",
  filterBy: [],
  filterByStatus: [],
  filterByOrderStatus: [],
  sortBy: "",
  orderSortBy: "",
  sortOrder: "",
  orderSortOrder: "",
  clientList: [],
  orderList: [],
  filteredClientList: [],
  filteredOrderList: [],
};

export default function dashboardFilter(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_FILTER:
      function returnFilteredOrderList(_filter) {
        return _filter.list
          .filter((report) => {
            //If the text typed matches the client name or CNPJ
            const filterClientOnly =
              report[_filter.recommendationType].client_name
                .toLowerCase()
                .includes(_filter.filterString.toLowerCase()) ||
              report[_filter.recommendationType].client_cpf_cnpj.includes(
                _filter.filterString.replace(/[@.-/]/g, "")
              );

            const filterClientAndSupplier =
              _filter.recommendationType === "client_charge"
                ? report[_filter.recommendationType].client_name
                    .toLowerCase()
                    .includes(_filter.filterString.toLowerCase()) ||
                  report[_filter.recommendationType].distributor_name
                    .toLowerCase()
                    .includes(_filter.filterString.toLowerCase()) ||
                  report[
                    _filter.recommendationType
                  ].distributor_cpf_cnpj.includes(
                    _filter.filterString.replace(/[@.-/]/g, "")
                  ) ||
                  report[_filter.recommendationType].client_cpf_cnpj.includes(
                    _filter.filterString.replace(/[@.-/]/g, "")
                  )
                : false;

            if (
              (_filter.recommendationType === "client_recommendation" &&
                filterClientOnly) ||
              (_filter.recommendationType === "client_charge" &&
                filterClientAndSupplier)
            ) {
              //If the current status filter matches the recommendation status
              if (
                (_filter.filterStatus.length > 0 &&
                  _filter.filterStatus.includes(report.status)) ||
                _filter.filterStatus.length <= 0
              )
                return true;
            }
          })
          .sort(function (a, b) {
            if (_filter.sortBy === "client_name") {
              if (_filter.sortOrder === "ASC") {
                return a[_filter.recommendationType].client_name.localeCompare(
                  b[_filter.recommendationType].client_name
                );
              } else if (action.payload.sortOrder === "DESC") {
                return b[_filter.recommendationType].client_name.localeCompare(
                  a[_filter.recommendationType].client_name
                );
              } else {
                return;
              }
            } else if (_filter.sortBy === "created_at") {
              const aDate = new Date(
                a[_filter.recommendationType].created_at
              ).getTime();
              const bDate = new Date(
                b[_filter.recommendationType].created_at
              ).getTime();

              if (_filter.sortOrder === "ASC") {
                return aDate - bDate;
              } else if (_filter.sortOrder === "DESC") {
                return bDate - aDate;
              } else {
                return;
              }
            } else if (_filter.sortBy === "distributor_name") {
              if (_filter.sortOrder === "ASC") {
                return a[
                  _filter.recommendationType
                ].distributor_name.localeCompare(
                  b[_filter.recommendationType].distributor_name
                );
              } else if (action.payload.sortOrder === "DESC") {
                return b[
                  _filter.recommendationType
                ].distributor_name.localeCompare(
                  a[_filter.recommendationType].distributor_name
                );
              } else {
                return;
              }
            } else {
              return;
            }
          });
      }

      return {
        ...state,
        filterStr: action.payload.filterStr,
        filterBy: action.payload.filterBy,
        sortBy: action.payload.sortBy,
        clientList: action.payload.clientList,
        filterByStatus: action.payload.filterByStatus,
        sortOrder: action.payload.sortOrder,
        filteredClientList: returnFilteredOrderList({
          list: action.payload.clientList,
          recommendationType: "client_recommendation",
          filterString: action.payload.filterStr,
          filterStatus: action.payload.filterByStatus,
          sortBy: action.payload.sortBy,
          sortOrder: action.payload.sortOrder,
        }),

        orderFilterStr: action.payload.orderFilterStr,
        orderSortOrder: action.payload.orderSortOrder,
        orderSortBy: action.payload.orderSortBy,
        filterByOrderStatus: action.payload.filterByOrderStatus,
        orderList: action.payload.orderList,
        filteredOrderList: returnFilteredOrderList({
          list: action.payload.orderList,
          recommendationType: "client_charge",
          filterString: action.payload.orderFilterStr,
          filterStatus: action.payload.filterByOrderStatus,
          sortBy: action.payload.orderSortBy,
          sortOrder: action.payload.orderSortOrder,
        }),
      };
    default:
      return state;
  }
}

export const Creators = {
  setFilter: () => {
    return {
      type: Types.SET_FILTER,
    };
  },
};
