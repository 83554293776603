import React, { useEffect }  from "react";
import PropTypes from "prop-types";

import "./style.scss";
function Tabs({
  children,
  tabs,
  currentTab,
  changeTab,
}) {
  // const [activeTab, changeTab] = useState({});

  useEffect(() => {
    changeTab(currentTab);
  }, [currentTab]);

  function handleChangeTab(tab) {
    changeTab(tab.name);
  }

  return (
    <>
      <ul className="tabs">
        {tabs.map((tab) => (
          <li
            onClick={() => handleChangeTab(tab)}
            key={tab.name}
            className={
              currentTab === tab.name ? "tab -active" : "tab"
            }
          >
            <div className="tabinfo">
              <span className="tabtitle">{tab.title}</span>
              {tab.hasBadge ? (
                <div className="badge badge--dashboard-inactive">
                  {tab.badgeValue}
                </div>
              ) : null}
            </div>
          </li>
        ))}
      </ul>
      <div className="tabsseparator"></div>
      {tabs.map((tab) => (
        <div key={tab.name}>
          {currentTab === tab.name ? (
            <div key={tab.name} className="tabcontent">
              {tab.content}
            </div>
          ) : null}
        </div>
      ))}
    </>
  );
}

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
};

export default Tabs;
