export default function ({
  err,
  setFieldHasError,
  setCustomErrorMessage,
  setRefresh,
  refresh,
  setIsLoading,
  hasErrorBypass = false,
  fieldToBypass = "",
}) {
  if (err.response.data.errors.length > 0) {
    const errors = err.response.data.errors;
    let tempHasError = {};
    let tempErrorMessage = {};

    errors.forEach((error) => {
      tempHasError = { ...tempHasError, [error.type]: true };
      tempErrorMessage = {
        ...tempErrorMessage,
        [error.type]: error.messages[0],
      };
      if (hasErrorBypass && error.type === fieldToBypass) {
        tempHasError[error.type] = false;
        tempErrorMessage[error.type] = "";
      }
    });
    setFieldHasError(tempHasError);
    setCustomErrorMessage(tempErrorMessage);
    setRefresh(!refresh);
    setIsLoading(false);
  }
}
