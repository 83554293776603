import React, { useEffect, useState } from "react";
import CpfCnpjFormatter from "../../../shared/CpfCnpjFormatter";

import missedCall from "../../../../assets/opportunities/missed-call.svg";
import refused from "../../../../assets/opportunities/hand-outline.svg";

import { connect } from "react-redux";
import { Types } from "../../../../store/ducks/opportunities";

import "./style.scss";

function DashboardListItem({
  isMobile,
  name,
  cnpj,
  date,
  status,
  rawObject,
  selectOpportunity,
}) {
  const itemDate = new Date(Date.parse(date));
  const [statusLabel, setStatusLabel] = useState("");

  const formattedDate = `${
    itemDate.getDate() < 10 ? "0" + itemDate.getDate() : itemDate.getDate()
  }/${
    itemDate.getMonth() + 1 < 10
      ? "0" + Number(itemDate.getMonth() + 1)
      : itemDate.getMonth() + 1
  }/${itemDate.getFullYear()}`;

  useEffect(() => {
    switch (status) {
      case "intermediate":
        setStatusLabel("Começou a vender");
        break;
      case "processing":
        setStatusLabel("Cliente indicado");
        break;
      case "already_blu_client":
      case "another_recommendation":
      case "invalid":
        setStatusLabel("Indicação inválida");
        break;
      case "has_user_logged":
        setStatusLabel("Fechou com a Blu!");
        break;
      case "has_spent_500":
        setStatusLabel("Vendeu R$500 ou mais");
        break;
      default:
        break;
    }
  }, [status]);

  return (
    <div className="dashboard-list-item">
      <div className="info">
        <span className="cnpj">
          <CpfCnpjFormatter unmaskedCpfCnpj={cnpj} />
        </span>
        <span className="name">{name}</span>
        <span className={`status -mobile -${status}`}> {statusLabel} </span>
        {rawObject?.last_opportunity?.status === "open" ? (
          <>
            {rawObject.last_opportunity.business_action ===
            "update_user_phone" ? (
              <div className="opportunity">
                <img src={missedCall} />{" "}
                <span className="text">
                  Cliente sem contato.{" "}
                  <a
                    className="link"
                    onClick={() => {
                      selectOpportunity({
                        selectedOpportunity: rawObject.last_opportunity,
                      });
                      Turbolinks.visit("/oportunidades?page=detail");
                    }}
                  >
                    Saiba mais
                  </a>
                </span>
              </div>
            ) : (
              <>
                {rawObject.last_opportunity.payload.reason_for_refusal ===
                "Não conseguiu falar mais com o decisor" ? (
                  <div className="opportunity">
                    <img src={missedCall} />{" "}
                    <span className="text">
                      Cliente sem contato.{" "}
                      <a
                        className="link"
                        onClick={() => {
                          selectOpportunity({
                            selectedOpportunity: rawObject.last_opportunity,
                          });
                          Turbolinks.visit("/oportunidades?page=refusalDetail");
                        }}
                      >
                        Saiba mais
                      </a>
                    </span>
                  </div>
                ) : (
                  <div className="opportunity">
                    <img src={refused} />{" "}
                    <span className="text -refusal">
                      Indicação recusada.{" "}
                      <a
                        className="link"
                        onClick={() => {
                          selectOpportunity({
                            selectedOpportunity: rawObject.last_opportunity,
                          });
                          Turbolinks.visit("/oportunidades?page=refusalDetail");
                        }}
                      >
                        Saiba mais
                      </a>
                    </span>
                  </div>
                )}
              </>
            )}
          </>
        ) : null}
      </div>
      <span className={`status -desktop -${status}`}> {statusLabel} </span>
      <div className="date">
        <span>{formattedDate}</span>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectOpportunity: (payload) => {
      dispatch({ type: Types.SELECT_OPPORTUNITY, payload });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    opportunities: state.opportunities,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardListItem);
