import React from "react";

import "./style.scss";

export default function ReadOnlyField({ title, subtitle, onClick, children }) {
  return (
    <div className="read-only-field" onClick={onClick}>
      <div className="textcontainer">
        <span className="title">{title}</span>
        {subtitle ? <span className="subtitle">{subtitle}</span> : null}
        {children ? <span className="subtitle">{children}</span> : null}
      </div>
    </div>
  );
}
