import React from "react";
import PropTypes from "prop-types";

import "./style.scss";
import PrimaryButton from "../PrimaryButton";
import SecondaryButton from "../SecondaryButton";

function FooterActions({
  isLoading,
  primaryAction,
  secondaryAction,
  primaryType,
  secondaryType,
}) {
  return (
    <div className="footer-actions">
      <PrimaryButton type={primaryType} onClick={() => primaryAction()}>
        {isLoading ? (
          <div className="spinner -mg-auto"></div>
        ) : (
          "Atualizar informações"
        )}
      </PrimaryButton>
      <SecondaryButton
        type={secondaryType}
        className="discardbutton"
        onClick={secondaryAction}
      >
        Descartar oportunidade
      </SecondaryButton>
    </div>
  );
}

FooterActions.propTypes = {
  isLoading: PropTypes.bool,
  primaryAction: PropTypes.func,
  secondaryAction: PropTypes.func,
  primaryType: PropTypes.string,
  secondaryType: PropTypes.string,
};

FooterActions.defaultProps = {
  isLoading: false,
  primaryAction: () => {},
  secondaryAction: () => {},
  primaryType: "submit",
  secondaryType: "reset",
};

export default FooterActions;
