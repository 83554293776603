import React, { useState, useEffect } from "react";

import "./style.scss";
import closeIcon from "../../assets/checkout/close.svg";
import orderSuccessIllustration from "../../assets/checkout/ilustra-success.svg";
import listItemIcon from "../../assets/checkout/icn-indicacao.svg";

function Conclusion() {
  const [elementShouldShow, setElementShouldShow] = useState({
    listCall: true,
  });
  const [headingText, setHeadingText] = useState();
  const [listCallText, setListCallText] = useState();

  let client;
  try {
    client = JSON.parse(localStorage.getItem("client"));
  } catch {
    client = {};
  }

  let conclusionInfo;
  try {
    conclusionInfo = JSON.parse(localStorage.getItem("orderConclusion"));
  } catch {
    Turbolinks.visit("/pedidos/resumo");
  }

  let complementaryInfo;
  try {
    complementaryInfo = JSON.parse(localStorage.getItem("complementaryData"));
  } catch {
    Turbolinks.visit("/pedidos/resumo");
  }

  function handleNotBluClient() {
    const shouldElShow =
      conclusionInfo.seller_client_recommendations_count <= 0;

    setElementShouldShow({
      ...elementShouldShow,
      extraInfo: true,
      subheading: shouldElShow,
      indicateCta: true,
    });
  }

  function handleIsBluClient() {
    const clientStatus = conclusionInfo.order_client_status;
    if (clientStatus === "not_blu_client") return;

    const isChargingByBlu =
      complementaryInfo.payment_method === "charging_by_blu";

    const isActive = clientStatus === "active";

    if (isActive && !isChargingByBlu) {
      setHeadingText("Não deixe de cobrar via Blu no seu próximo pedido! 😉");
      setListCallText(
        "Você tem mais segurança no recebimento da sua comissão e seu cliente ainda aproveita:"
      );
      setElementShouldShow({
        ...elementShouldShow,
        chargeByBluIncentive: true,
        indicateCta: false,
      });
    } else if (!isActive && !isChargingByBlu) {
      setHeadingText("Faz tempo que seu cliente não usa nossas soluções 😢");
      setListCallText("Lembre a ele que só a Blu oferece:");
      setElementShouldShow({
        ...elementShouldShow,
        chargeByBluIncentive: true,
        indicateCta: false,
      });
    } else if (isChargingByBlu) {
      setHeadingText(
        "Parabéns! Você tirou seu pedido via Blu e aproveitou vários benefícios 😎"
      );
      setListCallText("");
      setElementShouldShow({
        ...elementShouldShow,
        subheading: false,
        listCall: false,
        indicateCta: false,
        repNota1000: true,
      });
    }
  }

  useEffect(() => {
    handleNotBluClient();
    handleIsBluClient();
  }, []);

  return (
    <div className="order-conclusion container">
      <a href="/pedidos" className="close">
        <img src={closeIcon} alt="Fechar" className="closeicon" />
        <span className="closetext">Fechar</span>
      </a>
      <div className="content">
        <section className="success">
          <img
            src={orderSuccessIllustration}
            alt="Sucesso!"
            className="image"
          />
          <div className="text">
            <h1 className="sent">Pedido enviado!</h1>

            <span className="ordernumber">
              O número do seu pedido é <em>{conclusionInfo.orderCode}</em>
            </span>

            <p className="info">
              A fábrica já recebeu o seu pedido e você vai receber uma cópia da
              solicitação no seu e-mail.
            </p>
          </div>
        </section>

        <section className="bluinfo">
          <h2 className="heading">
            {headingText ? (
              <strong>{headingText} </strong>
            ) : (
              <>
                Ganhe indicando seus clientes! 🎉
              </>
            )}
          </h2>

          {elementShouldShow.subheading ? (
            <h4 className="subheading">
              Seu cliente ainda não é Blu, que tal fazer uma indicação?
            </h4>
          ) : null}

          {elementShouldShow.listCall ? (
            <span className="listcall">
              {listCallText
                ? listCallText
                : "Lojistas que já são Blu aproveitam:"}
            </span>
          ) : null}
          <ul className="list">
            <li className="item">
              <img src={listItemIcon} className="icon" />
              Compra com economia de até 15%, sem restrição de crédito.
            </li>
            <li className="item">
              <img className="icon" src={listItemIcon} />3 formas de pagamento
              para escolher.
            </li>
            <li className="item">
              <img className="icon" src={listItemIcon} />
              Link de pagamento para vender online.
            </li>
          </ul>

          {elementShouldShow.extraInfo ? (
            <p className="extrainfo">
              <strong>Você pode ganhar até R$500</strong> a cada indicação que
              aderir à Blu e ainda garante mais segurança no recebimento da sua
              comissão!
            </p>
          ) : null}
          {elementShouldShow.chargeByBluIncentive ? (
            <span className="bottominfo">
              Cobrando via Blu todo mundo ganha!
            </span>
          ) : null}
          {elementShouldShow.repNota1000 ? (
            <span className="bottominfo">
              Continue sendo um Rep Nota 1.000!
            </span>
          ) : null}
        </section>

        <div className="actions">
          {elementShouldShow.indicateCta ? (
            <a
              href={`/indicacao-premiada?trading_name=${client.tradingName}&cpf_cnpj=${client.cpfCnpj}&client_name=${client.clientName}&phone=${client.phone}&client_email=${client.clientEmail}`}
              className="button -primary -mobile"
            >
              Indicar cliente
            </a>
          ) : null}

          <a
            href="/pedidos"
            className={
              elementShouldShow.indicateCta ? "button" : "button -primary"
            }
          >
            Novo pedido
          </a>
          <a href="/pedidos" className="button -close">
            Fechar
          </a>
          {elementShouldShow.indicateCta ? (
            <a
              href={`/indicacao-premiada?trading_name=${client.tradingName}&cpf_cnpj=${client.cpfCnpj}&client_name=${client.clientName}&phone=${client.phone}&client_email=${client.clientEmail}`}
              className="button -primary -desktop"
            >
              Indicar cliente
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Conclusion;
