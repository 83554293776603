import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import userIcon from "../../assets/checkout/user-blue.svg";
import productIcon from "../../assets/checkout/icn-produtos.svg";
import infoIcon from "../../assets/checkout/icn-infos.svg";
import AccordionComponent from "../shared/AccordionComponent";
import "./style.scss";
import DetailHeader from "../shared/DetailHeader";
import ClientData from "./ClientData";
import ComplementaryData from "./ComplementaryData";
import OverviewItems from "./OverviewItems";
import OverviewValues from "./OverviewValues";
import api from "../services/api";

function Overview({ currentSeller }) {
  const [accordionVisibility, setAccordionVisibility] = useState({
    clientData: false,
    products: false,
    complementaryData: false,
  });
  const [baskets, setBaskets] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  let items = [];
  try {
    items = JSON.parse(localStorage.getItem("localCartItems"));
  } catch {
    Turbolinks.visit("/pedidos/novo");
  }

  let complementaryData;
  try {
    complementaryData = JSON.parse(localStorage.getItem("complementaryData"));
  } catch {
    Turbolinks.visit("/pedidos/complemento ");
  }

  let client;
  try {
    client = JSON.parse(localStorage.getItem("client"));
  } catch {
    Turbolinks.visit("/pedidos");
  }

  function toggleAccordion(accordionName) {
    const tempAccordion = accordionVisibility[accordionName] ? false : true;
    setAccordionVisibility({
      ...accordionVisibility,
      [accordionName]: tempAccordion,
    });
  }

  useEffect(() => {
    let tempBaskets = items.map((item) => {
      return {
        product_id: String(item.product.id),
        unity_price: item.product.unity_price,
        discounts: item.discounts
          .map((_discount) => {
            return _discount > 0 && { percentage: Number(_discount) };
          })
          .filter(Boolean),
        quantity: item.quantity,
      };
    });
    setBaskets(tempBaskets);
  }, []);

  function handleCreateOrder() {
    setIsLoading(true);
    api
      .post("/api/v1/orders", {
        order: {
          seller_id: currentSeller.cpf_cnpj,
          client_cpf_cnpj: client.cpfCnpj,
          client_business_name: client.businessName,
          client_name: client.tradingName,
          client_phone: client.phone,
          client_state_registration: client.stateRegistration,
          address_city: client.city,
          address_complement: client.complement,
          address_neighborhood: client.neighbourhood,
          address_number: client.number,
          address_state: client.state,
          address_street: client.address,
          address_zipcode: client.cep,
          comments: complementaryData.comments,
          frete_type: complementaryData.frete_type,
          payment_method: complementaryData.payment_method,
          tax_substitution: complementaryData.tax_substitution,
          payment_conditions: complementaryData.payment_conditions,
          client_responsible_email: client.clientEmail,
          client_responsible_name: client.clientName,
          ipi: complementaryData.ipi,
          baskets,
        },
      })
      .then((res) => {
        setIsLoading(false);

        localStorage.setItem(
          "orderConclusion",
          JSON.stringify({ ...res.data.report, orderCode: res.data.order.code })
        );
        localStorage.removeItem("localCartItems");
        Turbolinks.visit("/pedidos/conclusao");
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  function handleTotalQuantity(_totalQuantity) {
    setTotalQuantity(_totalQuantity);
  }

  return (
    <div className="order-overview">
      <DetailHeader url="/pedidos/complemento" title="Finalize seu pedido" />
      <div className="container -no-padding">
        <AccordionComponent
          icon={userIcon}
          title="Dados do Cliente"
          name="clientData"
          isOpen={accordionVisibility.clientData}
          toggle={toggleAccordion}
        >
          <ClientData client={client} />
        </AccordionComponent>

        <AccordionComponent
          icon={productIcon}
          title="Produtos"
          name="products"
          isOpen={accordionVisibility.products}
          toggle={toggleAccordion}
          badgeValue={totalQuantity}
        >
          <OverviewItems items={items} />
        </AccordionComponent>

        <AccordionComponent
          icon={infoIcon}
          title="Informações complementares"
          name="complementaryData"
          isOpen={accordionVisibility.complementaryData}
          toggle={toggleAccordion}
        >
          <ComplementaryData complementaryData={complementaryData} />
        </AccordionComponent>
      </div>
      <div className="container">
        <OverviewValues
          handleCreateOrder={handleCreateOrder}
          items={items}
          complementaryData={complementaryData}
          updateItemsQuantity={handleTotalQuantity}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

Overview.propTypes = {
  currentSeller: PropTypes.object.isRequired,
};

export default Overview;
