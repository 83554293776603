$.fn.backdrop = function () {
	const $backdropCaller = $(this);
	const target = $backdropCaller.attr('data-js-backdrop');
	const $backdrop = $(`[data-js-backdrop-container='${target}`);

	//Handles Showing and Hiding backdrop
	function toggleBackdrop() {
		$backdrop.toggle();
	}

	//Allows closing by pressing the ESC key
	$(document).keydown(function (event) {
		if (event.keyCode == 27) {
			toggleBackdrop();
		}
	});

	//Allows closing by clicking outside
	$backdrop.on("click", function (e) {
		if (e.target !== this) return;
		toggleBackdrop();
	});

	//Opens Backdrop with desired content
	$backdropCaller.on("click", function () {
		toggleBackdrop();
	});
};

$(document).on("ready turbolinks:load", function () {
	$("[data-js-backdrop]").backdrop();
});
