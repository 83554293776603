import React from "react";
import DetailHeader from "../../shared/DetailHeader";

import MenuItemMobile from "../../shared/MenuItemMobile";

export default function MyData() {
  return (
    <>
      <DetailHeader
        title="Meus Dados"
        backAction={() => Turbolinks.visit("/minha-conta")}
      />
      <div className="container">
        <div className="my-account-menu-mobile">
          <MenuItemMobile
            title="Dados pessoais"
            subtitle="Sobre você, CPF/CNPJ, mãe, pai"
            onClick={() =>
              Turbolinks.visit("#dados-pessoais")
            }
          />
          <MenuItemMobile
            title="Documentos"
            subtitle="PIS, Identidade, CTPS"
            onClick={() =>
              Turbolinks.visit("#documentos")
            }
          />
          <MenuItemMobile
            title="Endereço"
            subtitle="Seu endereço completo"
            onClick={() => Turbolinks.visit("#endereco")}
          />
        </div>
      </div>
    </>
  );
}
