import React, { useState, useEffect } from "react";

export default function useMacthMedia() {

  const [isMobileWidth, setIsMobileWidth] = useState();

  function handleScreenSize() {
    const mql = window.matchMedia(`(max-width: 1024px)`);

    setIsMobileWidth(mql.matches);
  }

  useEffect(() => {
      handleScreenSize()
    }, []
  );



  return isMobileWidth;
}
