$(document).on("ready turbolinks:load", function () {
	//Every main menu item
	const menuItems = $(".header > .content .nav .options .item");

	//Specific items (buttons, etc)
	const login = $(".header > .content .nav .options .item.-login"),
		signup = $(".header > .content .nav .options .item.-signup"),
		recommendCta = $(".header > .content .nav .options .item.-recommend"),
		logoutMobile = $(".header > .content .nav .options .item.-logoutmobile");

	//General action
	const menuBtn = $(".header > .content .menubtn"),
		close = $(".header > .content .nav .close "),
		nav = $(".header > .content .nav");

	//Menu item (dropdown parent)
	const community = $(".header > .content .nav .options .item.-community"),
		recommendation = $(
			".header > .content .nav .options .item.-recommendation"
		);

	//Defining pathname and hash for ease of use
	const { pathname, hash } = window.location;

	//Specific for the recommendations path (multiple paths related to same item)
	const isInRecommendationPath =
		pathname === "/indicacao-premiada" ||
		pathname === "/indicacao-premiada-check-cpf-cnpj";

	//Paths where Recommend cta shoudn't appear
	const hideRecommendPaths = [
		"/indicacao-premiada-check-cpf-cnpj",
		"/indicacao-premiada",
		"/indicacao-premiada-by-cpf-cnpj",
		"/dashboard",
	];

	// Define if recommendation cta should appear on header
	const shouldHideRecommend = hideRecommendPaths.includes(pathname)
		? true
		: false;

	let windowWidth = $(window).width();
	let isMobileWidth = windowWidth <= 1199 ? true : false;

	//Adds an active class to menu item
	function handleActiveItem(item) {
		item.addClass("-active-item");

		//Active state for dropdown parents (disabled due to lack of definition on how to behave)
		// item.parents(".item").find("> .link").addClass("-active-item");
	}

	//Handles wich menu item should be active
	menuItems.find(".link").each(function () {
		const itemHref = $(this).attr("href");

		//Find the recommendations href
		const isRecommendationHref = itemHref === "/indicacao-premiada";

		//Compare pathname and hash with the href of each item
		if (itemHref == pathname) {
			handleActiveItem($(this));
		} else if (itemHref && itemHref.substring(1) == hash && hash) {
			handleActiveItem($(this));
		} else if (isInRecommendationPath && isRecommendationHref) {
			handleActiveItem($(this));
		}
	});

	//Hides/Shows recommend button based on url (desktop)
	function handleHideRecommendationCta() {
		if (shouldHideRecommend && !isMobileWidth) {
			recommendCta.hide();
		}else{
			recommendCta.show();
		}
	}

	//Handle visual change on pressing a menu item (responsive)
	function paginateHeader(_menuItem) {
		menuItems
			.not(_menuItem)
			.not(login)
			.not(signup)
			.not(recommendCta)
			.toggle();

		_menuItem.find("> a").toggleClass("-active");
	}

	//Receives a menu item, unbind its listeners and undo mobile rules
	function unbindMobileActions(_menuItem) {
		_menuItem.off();
		_menuItem.find(".navdropdown").show();
		_menuItem.find(".arrow").removeClass("-back");

		menuItems.not(logoutMobile).show();
		menuItems.find("> a").removeClass("-active");
	}

	//Receives a menu item and adds listeners to it
	function addMobileAction(_menuItem) {
		_menuItem.find(".navdropdown").hide();
		_menuItem.off().on("click", function (e) {
			e.stopPropagation();
			paginateHeader(_menuItem);
			_menuItem.find(".navdropdown").toggle();
			_menuItem.find(".arrow").toggleClass("-back");
		});
	}

	//Adds mobile features based on screen size
	function handleMobileActions(_isMobileWidth) {
		if (_isMobileWidth) {
			addMobileAction(community);
			addMobileAction(recommendation);

			//Close button
			close.on("click", function () {
				nav.hide();
			});

			//Links that don't redirect will close the menu as well
			menuItems.find("> .navdropdown > li > .link").on("click", function () {
				nav.hide();
			});

			//Hamburger Menu button
			menuBtn.on("click", function () {
				nav.show();
			});
		} else {
			unbindMobileActions(recommendation);
			unbindMobileActions(community);
		}
	}

	//Handles Window resizing so you can switch from responsive to desktop without refreshing
	$(window).resize(function () {
		windowWidth = $(window).width();
		isMobileWidth = windowWidth <= 1199 ? true : false;
		handleMobileActions(isMobileWidth);
		if (!isMobileWidth) {
			unbindMobileActions(recommendation);
			unbindMobileActions(community);
		}
		handleHideRecommendationCta();
	});

	//First calls (without depending on screen resizing)
	handleMobileActions(isMobileWidth);
	handleHideRecommendationCta();
});
