import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useFormik } from "formik";

import { Types as FilterTypes } from "../../../store/ducks/dashboardFilter";

import Field from "../../shared/Form/Field";
import searchIcon from "../../../assets/general/search-icon.svg";
import arrowDownIcon from "../../../assets/general/selected-arrow-down.svg";
import chevronDown from "../../../assets/dashboard/chevron-down.svg";
import sortIcon from "../../../assets/dashboard/icn-ordenar.svg";
import OrderListItem from './OrderListItem';
import StatusBackdrop from "../DashboardList/StatusBackdrop";
import SortBackdrop from "../DashboardList/SortBackdrop";

function OrderRecommendations({
  setFilter,
  filters,
}) {
  const [isMobileWidth, setIsMobileWidth] = useState();
  const [showStatusBackdrop, setShowStatusBackdrop] = useState(false);
  const [showSortBackdrop, setShowSortBackdrop] = useState(false);

  const orderStatusList = [...filters.filterByOrderStatus];

  const fullOrderStatusList = [
    "in_analysis",
    "invalid",
    "paid_with_blu",
    "waiting_payment",
  ];

  const formik = useFormik({
    initialValues: {
      orderSearch: filters.orderFilterStr || "",
      orderStatus:
        orderStatusList.length > 0 ? [...orderStatusList] : ["all", ...fullOrderStatusList],
      orderSort: '',

    },
    onSubmit: (values) => {
      setShowStatusBackdrop(false);
      setFilter({
        ...filters,
        filterByOrderStatus: [...values.orderStatus],
      });
    },
  });

  function handleScreenSize() {
    const mql = window.matchMedia("(max-width: 1024px)");
    setIsMobileWidth(mql.matches);
  }

  function handleSort(name) {
    if (filters.orderSortOrder === "ASC") {
      setFilter({ ...filters, orderSortBy: name, orderSortOrder: "DESC" });
    } else if (filters.sortOrder === "DESC") {
      setFilter({ ...filters, orderSortBy: "", orderSortOrder: "" });
    } else {
      setFilter({ ...filters, orderSortBy: name, orderSortOrder: "ASC" });
    }
  }

  function handleBackdropSort(name, order){
    setFilter({ ...filters, orderSortBy: name, orderSortOrder: order});
    setShowSortBackdrop(false);
  }

  useEffect(() => {
    handleScreenSize();
    window.addEventListener("resize", handleScreenSize);
    return () => {
      window.removeEventListener("resize", handleScreenSize);
    };
  }, []);

  useEffect(() => {
    setFilter({ ...filters, orderFilterStr: formik.values.orderSearch });
  }, [formik.values.orderSearch]);

  useEffect(() => {
    switch (formik.values.orderSort) {
      case "supplierAsc":
        handleBackdropSort("distributor_name", "ASC" );
        break;
      case "supplierDesc":
        handleBackdropSort("distributor_name", "DESC" );
        break;
      case "clientAsc":
        handleBackdropSort("client_name", "ASC" );
        break;
      case "clientDesc":
        handleBackdropSort("client_name", "DESC" );
        break;
      case "dateAsc":
        handleBackdropSort("created_at", "ASC" );
        break;
      case "dateDesc":
        handleBackdropSort("created_at", "DESC" );
        break;
      default:
        break;
    }
  }, [formik.values.orderSort]);

  return (
    <div className="dashboard-recommendations-list">
      <form onSubmit={formik.handleSubmit}>
        <Field
          name="orderSearch"
          placeholder="CNPJ ou razão social"
          label=""
          formHandler={formik}
          leftIcon={searchIcon}
        />
      </form>

      {/* LIST */}
      <div className="dashboard-list-desktop">
        <div className="listheader">
          <div
            className={
              filters.orderSortBy === "client_name" || filters.orderSortBy === "distributor_name" ||
                (isMobileWidth && filters.orderSortBy === "created_at")
                ? "item -selected"
                : "item "
            }
            onClick={() =>
              setShowSortBackdrop(true)
            }
          >
            {{
              [filters.orderSortBy === "client_name" && "ASC"]: (
                <>
                  <span>Cliente (A-Z)</span> <img src={arrowDownIcon} />
                </>
              ),
              [filters.orderSortBy === "client_name" && "DESC"]: (
                <>
                  <span>Cliente (Z-A)</span>
                  <img src={arrowDownIcon} className="up" />
                </>
              ),
              [filters.orderSortBy === "distributor_name" && "ASC"]: (
                <>
                  <span>Fornecedor (A-Z)</span> <img src={arrowDownIcon} />
                </>
              ),
              [filters.orderSortBy === "distributor_name" && "DESC"]: (
                <>
                  <span>Fornecedor (Z-A)</span>
                  <img src={arrowDownIcon} className="up" />
                </>
              ),
              [filters.orderSortBy === "created_at" && isMobileWidth && "ASC"]: (
                <>
                  <span>Mais antigos</span> <img src={arrowDownIcon} />
                </>
              ),
              [filters.orderSortBy === "created_at" && isMobileWidth && "DESC"]: (
                <>
                  <span>Mais recentes</span>
                  <img src={arrowDownIcon} className="up" />
                </>
              ),
            }[filters.orderSortOrder] || (
                <>
                  <span>Cliente</span> <img src={sortIcon} />
                </>
              )}
          </div>
          <div className="verticalseparator" />
          <div
            className={
              formik.values.orderStatus.length > 0 && formik.values.orderStatus.length < 5
                ? "item -selected"
                : "item"
            }
            onClick={() => {
              setShowStatusBackdrop(showStatusBackdrop ? false : true);
            }}
          >
            <span>
              Status
              {formik.values.orderStatus.length > 0 &&
                formik.values.orderStatus.length < 5 ? (
                  <div className="badge badge--dashboard">
                    {formik.values.orderStatus.includes("all")
                      ? formik.values.orderStatus.length - 1
                      : formik.values.orderStatus.length}
                  </div>
                ) : null}
            </span>
            <img src={chevronDown} />
          </div>
          <div
            className={
              filters.orderSortBy === "created_at"
                ? "item -selected -date"
                : "item -date"
            }
            onClick={() => handleSort("created_at")}
          >
            {{
              [filters.orderSortBy === "created_at" && "ASC"]: (
                <>
                  <span>Mais antigos</span> <img src={arrowDownIcon} />
                </>
              ),
              [filters.orderSortBy === "created_at" && "DESC"]: (
                <>
                  <span>Mais recentes</span>
                  <img src={arrowDownIcon} className="up" />
                </>
              ),
            }[filters.orderSortOrder] || (
                <>
                  <span>Data</span> <img src={sortIcon} />
                </>
              )}
          </div>
        </div>
        {filters.filteredOrderList.map((report) => (
          <OrderListItem
            key={report.client_charge.id}
            clientName={report.client_charge.client_name}
            supplierName={report.client_charge.distributor_name}
            clientCnpj={report.client_charge.client_cpf_cnpj}
            supplierCnpj={report.client_charge.distributor_cpf_cnpj}
            date={report.client_charge.created_at}
            status={report.status}
          />
        ))}
        <hr className="separator" />
      </div>

      {filters.orderFilterStr === "" &&
        filters.filterByOrderStatus.length == 0 ? (
          <div className="listendcontainer">
            <span className="listend">Essas são todas as suas indicações.</span>
          </div>
        ) : null}

      {filters.filteredOrderList.length === 0 ? (
        <div className="listendcontainer">
          <span className="listend">Não encontramos nenhum resultado.</span>
        </div>
      ) : null}

      <StatusBackdrop
        isOpen={showStatusBackdrop}
        statusList={fullOrderStatusList}
        close={() => setShowStatusBackdrop(false)}
        formHandler={formik}
        isOrder
        name='orderStatus'
      />
      <SortBackdrop
        isOpen={showSortBackdrop}
        close={() => setShowSortBackdrop(false)}
        formHandler={formik}
        isOrder
        name="orderSort"
        isMobile={isMobileWidth}
      />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFilter: (payload) => {
      dispatch({ type: FilterTypes.SET_FILTER, payload });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    filters: state.dashboardFilter,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderRecommendations);
