import React from "react";
import PropTypes from "prop-types";

import "./style.scss";
import whatsapp from "../../../assets/myAccount/whatsapp.png";
import PrimaryButton from "../PrimaryButton";

const WhatsappPrimaryButton = ({ action, text, ...rest }) => {
  return (
    <PrimaryButton {...rest} onClick={action} className="whatsapp-primary">
      <img src={whatsapp} alt="Salvar" />
      <p> {text}</p>
    </PrimaryButton>
  );
};

WhatsappPrimaryButton.propTypes = {
  text: PropTypes.string,
  action: PropTypes.func.isRequired,
};

WhatsappPrimaryButton.defaultProps = {
  text: "Whatsapp",
};

export default WhatsappPrimaryButton;
