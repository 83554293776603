import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "./style.scss";

function TextAreaField({
  maxLength,
  name,
  cols,
  rows,
  placeholder,
  label,
  formHandler,
  hasError,
  refresh,
  customError,
}) {
  const [hasCustomError, setHasCustomError] = useState(hasError);
  const isInvalid = formHandler.errors[name] && formHandler.touched[name];

  useEffect(() => {
    setHasCustomError(hasError);
  }, [hasError, refresh]);

  return (
    <div className={"textarea-field"}>
      <label htmlFor={name} className="label">
        {label}
      </label>
      <textarea
        name={name}
        id=""
        cols={cols}
        rows={rows}
        placeholder={placeholder}
        maxLength={maxLength}
        className={isInvalid || hasCustomError ? "textarea error" : "textarea"}
        value={formHandler.values[name]}
        onChange={(e) => {
          formHandler.handleChange(e);
          setHasCustomError(false);
        }}
      ></textarea>
      {maxLength ? (
        <span className="charcount">
          {formHandler.values[name].length}/{maxLength} caracteres
        </span>
      ) : null}

      {hasCustomError && (
        <>
          <span className="errormsg"> {customError} </span>
        </>
      )}
    </div>
  );
}

TextAreaField.propTypes = {
  maxLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  cols: PropTypes.number,
  rows: PropTypes.number,
  placeholder: PropTypes.string,
  label: PropTypes.string.isRequired,
  formHandler: PropTypes.object.isRequired,
};

TextAreaField.defaultProps = {
  maxLength: null,
  cols: 40,
  rows: 4,
  placeholder: "",
};

export default TextAreaField;
