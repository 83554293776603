import React, { useEffect } from "react";

import { Types } from "../../../store/ducks/opportunities";
import { connect } from "react-redux";

import "./style.scss";
import Tabs from "../../shared/Tabs";
import OpportunityCard from "../OpportunityCard";
import buttonHelp from "../../../assets/general/button-help.svg";
import NoOpenOpportunities from "./NoOpenOpportunities";

function OpportunitiesList({
  setTab,
  requestOpportunities,
  opportunities,
  currentSeller,
  changePage,
}) {
  function handleChangeTab(tabName) {
    setTab({ tab: tabName });
  }
  useEffect(() => {
    requestOpportunities({ sellerCpf: currentSeller.cpf_cnpj });
  }, []);

  return (
    <>
      <div className="opportunities-list container">
        <div className="opportunity-title">
          <h1>Oportunidades</h1>
          <img src={buttonHelp} onClick={() => changePage("description")} />
        </div>
        <Tabs
          currentTab={opportunities.tab}
          changeTab={handleChangeTab}
          data-testid="tabs"
          tabs={[
            {
              name: "pending",
              title: "Pendentes",
              hasBadge: true,
              badgeValue: opportunities.openList.length,
              content: (
                <>
                  {opportunities.openList.length > 0 ? (
                    <>
                      {opportunities.openList.map((opportunity) => (
                        <div key={opportunity.id}>
                          <OpportunityCard
                            changePage={changePage}
                            clientName={
                              opportunity.opportunitable_object
                                .client_recommendation.client_name
                            }
                            opportunity={opportunity}
                            businessAction={opportunity.business_action}
                            payload={opportunity.payload}
                          />
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <NoOpenOpportunities />
                    </>
                  )}
                </>
              ),
            },
            {
              name: "finalized",
              title: "Finalizadas",
              hasBadge: true,
              badgeValue: opportunities.closedList.length,
              content: (
                <>
                  {opportunities.closedList.length > 0 ? (
                    <>
                      {opportunities.closedList.map((opportunity) => (
                        <>
                          <OpportunityCard
                            opportunity={opportunity}
                            isFinalized
                            status={opportunity.status}
                            key={opportunity.id}
                            businessAction={opportunity.business_action}
                            changePage={changePage}
                            clientName={
                              opportunity.opportunitable_object
                                .client_recommendation.client_name
                            }
                            payload={opportunity.payload}
                          />
                        </>
                      ))}
                    </>
                  ) : (
                    <p className="notfoundtext -mt">
                      Quando você finalizar uma oportunidade ela aparecerá aqui.
                    </p>
                  )}
                </>
              ),
            },
          ]}
        ></Tabs>
      </div>
    </>
  );
}

OpportunitiesList.propTypes = {};

const mapDispatchToProps = (dispatch) => {
  return {
    setTab: (payload) => {
      dispatch({ type: Types.SET_TAB, payload });
    },
    requestOpportunities: (payload) => {
      dispatch({ type: Types.REQUEST, payload });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    opportunities: state.opportunities,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OpportunitiesList);
