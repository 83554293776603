import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import {
  customErrorStyles,
  customStyles,
} from "../helpers/CustomSelectStyles";

export default function DropdownField({
  containerClassName,
  label,
  placeholder,
  options,
  handleChange,
  selectedValue,
  noOptionsMessage,
  error
}) {

  const hasError = Boolean(error)

  return(
    <div className={containerClassName}>
      <div className="inputcontainer">
        <span>{label}</span>
          <Select
            placeholder={
              <span className="selectplaceholder">{placeholder}</span>
            }
            styles={hasError ? customErrorStyles : customStyles}
            menuPortalTarget={document.body}
            menuPosition='fixed'
            maxMenuHeight={170}
            options={options}
            onChange={handleChange}
            components={{
              IndicatorSeparator: () => null,
            }}
            value={options.find((obj) => obj.value === selectedValue)}
            noOptionsMessage={() => noOptionsMessage}
          />
          {hasError &&
            <span className="customerror">
              {error}
            </span>
          }
      </div>
    </div>
  )
}

DropdownField.propTypes = {
  containerClassName: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  handleChange: PropTypes.func,
  selectedValue: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  error: PropTypes.string
};

DropdownField.defaultProps = {
  containerClassName:'',
  label: '',
  placeholder: 'Selecione uma opção',
  options: [],
  handleChange: () => {},
  selectedValue: undefined,
  noOptionsMessage: 'Nenhum resultado encontrado',
  error: ''
};