import { call, takeLatest, put } from "redux-saga/effects";
import { Types } from "../ducks/opportunities";
import api from "../../components/services/api";

function getOpportunities(sellerCpf) {
  return api.get(`/api/v1/sellers/${sellerCpf}/opportunities`);
}

export function* findOpportunities(action) {
  try {
    const response = yield call(getOpportunities, action.payload.sellerCpf);

    yield put({
      type: Types.SUCCESS,
      payload: {
        list: response.data.opportunities,
      },
    });
  } catch (error) {
    yield console.log(error);
    yield put({ type: Types.FAIL });
  }
}

export default function* watchOpportunitiesRequest() {
  yield takeLatest(Types.REQUEST, findOpportunities);
}
