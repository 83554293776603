import React from "react";
import PropTypes from "prop-types";

import "./style.scss";
import threedotsIcon from "../../../../../assets/checkout/threedots.svg";
import editIcon from "../../../../../assets/checkout/edit.svg";
import removeIcon from "../../../../../assets/checkout/bin.svg";
import CurrencyTextFormatter from "../../../../shared/CurrencyTextFormatter";
import { itemValues } from "../../../../shared/helpers/itemValues";
import ItemImage from "../../../../shared/ItemImage";

function CartItem({ item, openActions, removeItem, goToDetailEdit }) {
  function handleRemoveItem(_item) {
    removeItem(_item);
  }

  return (
    <div className="cart-item">
      <div className="product">
        <ItemImage image={item.product.images[0]} size="thumb" />
        <div className="info">
          <span className="code">{item.product.code}</span>
          <span className="name">{item.product.name}</span>
          <span className="price">
            {itemValues(item, "discount") > 0 && (
              <span className="discount">
                <CurrencyTextFormatter value={itemValues(item, "subtotal")} />
              </span>
            )}
            <CurrencyTextFormatter value={itemValues(item, "total")} />
          </span>
        </div>
        <div className="actions">
          <img
            src={threedotsIcon}
            alt="ações"
            className="mobileaction"
            onClick={() => {
              openActions(item);
            }}
          />
          <button
            className="desktopaction"
            onClick={() => goToDetailEdit(item)}
          >
            <img src={editIcon} alt="Editar" />
          </button>
          <button
            className="desktopaction"
            onClick={() => handleRemoveItem(item)}
          >
            <img src={removeIcon} alt="Remover" />
          </button>
        </div>
      </div>
    </div>
  );
}

CartItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default CartItem;
