import React from "react";
import PropTypes from "prop-types";

function CpfCnpjFormatter({ unmaskedCpfCnpj, customClass }) {
  function handleMaskCpfCnpj(_cpfCnpj) {
    if (_cpfCnpj.length === 14) {
      const first = _cpfCnpj.substr(0, 2),
        second = _cpfCnpj.substr(2, 3),
        third = _cpfCnpj.substr(5, 3),
        fourth = _cpfCnpj.substr(8, 4),
        fifth = _cpfCnpj.substr(12, 2);
      return `${first}.${second}.${third}/${fourth}-${fifth}`;
    } else if (_cpfCnpj.length === 11) {
      const first = _cpfCnpj.substr(0, 3),
        second = _cpfCnpj.substr(3, 3),
        third = _cpfCnpj.substr(6, 3),
        fourth = _cpfCnpj.substr(9, 2);
      return `${first}.${second}.${third}-${fourth}`;
    } else {
      return _cpfCnpj;
    }
    // cpfCnpj.length > 11
    //   ? cpfCnpj.replace(
    //       /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    //       "$1.$2.$3/$4-$5"
    //     )
    //   : cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4")
  }

  return (
    <span className={customClass}> {handleMaskCpfCnpj(unmaskedCpfCnpj)} </span>
  );
}

CpfCnpjFormatter.propTypes = {
  unmaskedCpfCnpj: PropTypes.string,
};


CpfCnpjFormatter.defaultProps = {
  unmaskedCpfCnpj: '00000000000',
};
export default CpfCnpjFormatter;
