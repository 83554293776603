import React from "react";
import "./style.scss";

import SecondaryButton from "../../../shared/SecondaryButton";
import PrimaryButton from "../../../shared/PrimaryButton";

export default function Footer({
  stepNumber,
  hideBackButton,
  nextStep,
  previousStep,
  isLoading,
}) {
  const handleButtonText = () => {
    if (isLoading) {
      return <div className="spinner -white -mg-auto" />;
    } else if (stepNumber === 2) {
      return "Criar conta";
    } else {
      return "Avançar";
    }
  };
  return (
    <div className="modal-footer">
      <div className="button-container">
        {stepNumber ? (
          <p className="step-text">Etapa {stepNumber} de 2</p>
        ) : null}
        {!hideBackButton ? (
          <SecondaryButton onClick={previousStep}>Voltar</SecondaryButton>
        ) : null}
        <PrimaryButton
          type="submit"
          onClick={() => {
            nextStep();
          }}
        >
          {handleButtonText()}
        </PrimaryButton>
      </div>
    </div>
  );
}
