import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import axios from "axios";

import okIcon from "../../../../assets/myAccount/ok-circle.svg";
import "./style.scss";
import BottomButtonContainer from "../../../shared/BottomButtonContainer";
import DetailHeader from "../../../shared/DetailHeader";
import Field from "../../../shared/Form/Field";
import PrimaryButton from "../../../shared/PrimaryButton";
import SelectField from "../../../shared/Form/SelectField";
import api from "../../../services/api";
import Snackbar from "../../../shared/Snackbar";
import FormValidation from "../../../shared/helpers/FormValidation";

function Address({
  isRpa,
  customBack,
  customNext,
  showBackCta,
  sellerData,
  updateData,
}) {
  const [fieldHasError, setFieldHasError] = useState({});
  const [customErrorMessage, setCustomErrorMessage] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAddress, setIsLoadingAddress] = useState(false);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);

  const [ufs, setUfs] = useState([]);

  const formik = useFormik({
    initialValues: {
      address_zipcode: sellerData.address_zipcode || "",
      address_street: sellerData.address_street || "",
      address_number: sellerData.address_number || "",
      address_complement: sellerData.address_complement || "",
      address_neighborhood: sellerData.address_neighborhood || "",
      address_city: sellerData.address_city || "",
      state: sellerData.state || "",
    },
    validate: (values) => {
      let errors = {};
      let requiredMsg = "Este campo não pode ficar em branco.";
      if (!values.address_zipcode) errors.address_zipcode = requiredMsg;
      if (!values.address_street) errors.address_street = requiredMsg;
      if (!values.address_number) errors.address_number = requiredMsg;
      if (!values.address_neighborhood)
        errors.address_neighborhood = requiredMsg;
      if (!values.address_city) errors.address_city = requiredMsg;
      if (!values.state) errors.state = requiredMsg;
      return errors;
    },
    onSubmit: (values) => {
      setIsLoading(true);
      api
        .patch(`/api/v1/sellers/${sellerData.cpf_cnpj}`, {
          seller: {
            address_zipcode: values.address_zipcode,
            address_street: values.address_street,
            address_number: values.address_number,
            address_complement: values.address_complement,
            address_neighborhood: values.address_neighborhood,
            address_city: values.address_city,
            state: values.state,
            verify_attributes: [
              "address_street",
              "address_zipcode",
              "address_number",
              "address_complement",
              "address_neighborhood",
              "address_city",
              "state",
            ],
          },
        })
        .then((res) => {
          setIsLoading(false);
          setShowSuccessSnackbar(true);
          if (customNext) {
            customNext();
          }
        })
        .catch((err) => {
          FormValidation({
            err,
            setFieldHasError,
            setCustomErrorMessage,
            setRefresh,
            refresh,
            setIsLoading,
          });
        });
      isRpa && updateData(values);
    },
  });

  async function handleGetUfs() {
    const response = await axios.get(
      "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
    );
    const mappedUfs = [];
    response.data.map((el) =>
      mappedUfs.push({ value: el.nome, label: el.nome, id: el.id })
    );
    setUfs(mappedUfs);
  }

  useEffect(() => {
    handleGetUfs();
    window.scrollTo(0, -1000);
  }, []);

  useEffect(() => {
    if (formik.values.address_zipcode.length === 9) {
      setIsLoadingAddress(true);
      api.get(`/api/v1/ceps/${formik.values.address_zipcode}`).then((res) => {
        const { city, neighborhood, state, street } = res.data.main_address;
        formik.setFieldValue("address_city", city || "");
        formik.setFieldValue("address_neighborhood", neighborhood || "");
        formik.setFieldValue("state", state || "");
        formik.setFieldValue("address_street", street || "");
        setIsLoadingAddress(false);
      });
    }
  }, [formik.values.address_zipcode]);

  return (
    <>
      <DetailHeader
        backAction={() =>
          customBack ? customBack() : Turbolinks.visit("#meus-dados")
        }
        title="Endereço"
        showBackArrow={showBackCta}
      />
      <div className="container">
        <div className="account-address">
          {isRpa ? (
            <h1 className="rpa-title">
              Para finalizar, informe o seu endereço
            </h1>
          ) : null}
          <form onSubmit={formik.handleSubmit}>
            <div className="formrow formseparator">
              <div className="inputcontainer">
                <Field
                  name="address_zipcode"
                  label="CEP"
                  mask="99999-999"
                  placeholder="Informe o seu CEP"
                  formHandler={formik}
                  className="field"
                  isDisabled={isLoadingAddress}
                  hasError={fieldHasError.address_zipcode}
                  customError={customErrorMessage.address_zipcode}
                  refresh={refresh}
                />
                {isLoadingAddress ? <div className="spinner" /> : null}
              </div>
            </div>
            <Field
              name="address_street"
              label="Endereço"
              placeholder="Informe o endereço"
              formHandler={formik}
              className="field"
              isDisabled={isLoadingAddress}
              hasError={fieldHasError.address_street}
              customError={customErrorMessage.address_street}
              refresh={refresh}
            />
            <div className="formrow">
              <Field
                name="address_number"
                label="Número"
                placeholder="Informe o número"
                formHandler={formik}
                className="field"
                hasError={fieldHasError.address_number}
                customError={customErrorMessage.address_number}
                refresh={refresh}
              />
              <Field
                name="address_complement"
                label="Complemento (opcional)"
                placeholder="Informe o complemento"
                formHandler={formik}
                className="field"
                hasError={fieldHasError.address_complement}
                customError={customErrorMessage.address_complement}
                refresh={refresh}
              />
            </div>
            <div className="formrow">
              <Field
                name="address_neighborhood"
                label="Bairro"
                placeholder="Informe o Bairro"
                formHandler={formik}
                className="field"
                isDisabled={isLoadingAddress}
                hasError={fieldHasError.address_neighborhood}
                customError={customErrorMessage.address_neighborhood}
                refresh={refresh}
              />
              <Field
                name="address_city"
                label="Cidade"
                placeholder="Informe a cidade"
                formHandler={formik}
                className="field"
                isDisabled={isLoadingAddress}
                hasError={fieldHasError.address_city}
                customError={customErrorMessage.address_city}
                refresh={refresh}
              />
            </div>

            <div className="formrow">
              <SelectField
                name="state"
                label="Estado"
                options={ufs}
                formHandler={formik}
                hasError={fieldHasError.address_state}
                customError={customErrorMessage.address_state}
                refresh={refresh}
                isDisabled={isLoadingAddress}
              />
            </div>
            <br />
            <BottomButtonContainer>
              <PrimaryButton type="submit">
                {isLoading ? (
                  <div className="spinner -white -mg-auto"></div>
                ) : isRpa ? (
                  "Finalizar"
                ) : (
                  "Salvar"
                )}
              </PrimaryButton>
            </BottomButtonContainer>
          </form>
        </div>
      </div>
      {!isRpa ? (
        <Snackbar
          shouldShow={showSuccessSnackbar}
          text="Dados salvos com sucesso"
          icon={okIcon}
          handleClose={() => setShowSuccessSnackbar(false)}
        />
      ) : null}
    </>
  );
}

Address.propTypes = {
  isRpa: PropTypes.bool,
  customBack: PropTypes.func,
  customNext: PropTypes.func,
  showBackCta: PropTypes.bool,
};

Address.defaultProps = {
  isRpa: false,
  customBack: undefined,
  customNext: () => {},
  showBackCta: true,
};

export default Address;
