import React, { useState } from "react";
import PropTypes from "prop-types";

import "./style.scss";
import Field from "../../shared/Form/Field";
import DetailHeader from "../../shared/DetailHeader";
import FixedButton from "../../shared/Form/FixedButton";
import { useFormik } from "formik";
import percentIcon from "../../../assets/checkout/%.svg";
import ProductImage from "./ProductImage";
import ProductSubtotal from "./ProductSubtotal";
import CurrencyTextFormatter from "../../shared/CurrencyTextFormatter";
import Snackbar from "../../shared/Snackbar";
import notAllowedIcon from "../../../assets/checkout/not-allowed.svg";

const ProductDetail = ({
  product,
  backAction,
  addItem,
  removeItem,
  editItem,
  cartItem,
  cartItemsLength,
}) => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const editFlag = cartItem ? true : false;

  const formik = useFormik({
    initialValues: {
      quantity: editFlag ? cartItem.quantity : 1,
      discount1: editFlag ? cartItem.discounts[0] : "",
      discount2: editFlag ? cartItem.discounts[1] : "",
      discount3: editFlag ? cartItem.discounts[2] : "",
      discount4: editFlag ? cartItem.discounts[3] : "",
    },
  });

  const quantity = formik.values.quantity > 0 ? formik.values.quantity : 1;

  const crypto = window.crypto || window.msCrypto;
  var array = new Uint32Array(1);

  const internalId = editFlag
    ? cartItem.internalId
    : crypto.getRandomValues(array);

  let discounts = [
    formik.values.discount1,
    formik.values.discount2,
    formik.values.discount3,
    formik.values.discount4,
  ];

  function handleShowDiscountFields(prevField, field) {
    if (prevField > 0) {
      return true;
    } else {
      formik.values[field] = "";
      discounts = [
        formik.values.discount1,
        formik.values.discount2,
        formik.values.discount3,
        formik.values.discount4,
      ];

      return false;
    }
  }

  function handleIncreaseQuantity() {
    const tempQuantity = formik.values.quantity;
    tempQuantity < 99999 &&
      formik.setFieldValue("quantity", Number(formik.values.quantity + 1));
  }

  function handleDecreaseQuantity() {
    const tempQuantity = formik.values.quantity;
    tempQuantity > 1 &&
      formik.setFieldValue("quantity", Number(formik.values.quantity - 1));
  }

  function handleCloseSnackbar() {
    setShowSnackbar(false);
  }

  return (
    <div className="product-detail">
      <DetailHeader title="Detalhes" backAction={backAction} />
      <div className="content container">
        <div className="product">
          <ProductImage images={product.images} />
          <div className="infocontainer">
            <div className="info">
              <span className="code">{product.code}</span>
              <span className="name">{product.name}</span>
              <span className="price">
                <CurrencyTextFormatter value={product.unity_price} />
              </span>
            </div>
            <div className="quantity">
              <Field
                name="quantity"
                label="Quantidade"
                formHandler={formik}
                mask="99999"
              />
              <div className="actions">
                <button
                  className={
                    formik.values.quantity <= 1 ? "action -disabled" : "action"
                  }
                  onClick={handleDecreaseQuantity}
                >
                  -
                </button>
                <button
                  className="action -add"
                  onClick={handleIncreaseQuantity}
                >
                  +
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="discounts">
          <h3 className="text"> Descontos do vendedor </h3>
          <div className="formrow">
            <Field
              name="discount1"
              label="Desconto 1"
              formHandler={formik}
              placeholder="0"
              icon={percentIcon}
              className="field"
              mask="99.99"
              maskChar="0"
            />
            {handleShowDiscountFields(formik.values.discount1, "discount2") && (
              <Field
                name="discount2"
                label="Desconto 2"
                placeholder="0"
                formHandler={formik}
                icon={percentIcon}
                className="field"
                mask="99.99"
                maskChar="0"
              />
            )}
          </div>
          <div className="formrow">
            {handleShowDiscountFields(formik.values.discount2, "discount3") && (
              <Field
                name="discount3"
                label="Desconto 3"
                placeholder="0"
                formHandler={formik}
                icon={percentIcon}
                className="field"
                mask="99.99"
                maskChar="0"
              />
            )}
            {handleShowDiscountFields(formik.values.discount3, "discount4") && (
              <Field
                name="discount4"
                label="Desconto 4"
                placeholder="0"
                formHandler={formik}
                icon={percentIcon}
                className="field"
                mask="99.99"
                maskChar="0"
              />
            )}
          </div>
        </div>
        <Snackbar
          handleClose={handleCloseSnackbar}
          shouldShow={showSnackbar}
          icon={notAllowedIcon}
          text="Ops! Não é possível realizar pedidos com mais de 15 produtos."
        />

        <FixedButton
          label={editFlag ? "Alterar" : "Adicionar"}
          clickAction={() => {
            if (editFlag) {
              editItem({ product, discounts, quantity, internalId });
              backAction();
            } else {
              if (cartItemsLength < 15) {
                addItem({ product, discounts, quantity, internalId });
                backAction();
              } else {
                setShowSnackbar(true);
              }
            }
          }}
        >
          <ProductSubtotal
            item={{ product, discounts, quantity, internalId }}
          />
        </FixedButton>
      </div>
    </div>
  );
};

ProductDetail.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductDetail;
