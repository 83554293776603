import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import "./style.scss";

import linkImage from "../../../assets/myAccount/ilustra-link.svg";
import infoIcon from "../../../assets/myAccount/info-outline.svg";
import editIcon from "../../../assets/myAccount/pencil-solid.svg";
import userIcon from "../../../assets/myAccount/user-outline.svg";
import linkIcon from "../../../assets/myAccount/link-solid.svg";
import checkIcon from "../../../assets/myAccount/check-outline.svg";
import checkCircleIcon from "../../../assets/general/check-circle-outline.svg";
import questionMarkIcon from "../../../assets/myAccount/question-mark-circle-solid.svg";
import Field from "../../shared/Form/Field";
import SecondaryButton from "../../shared/SecondaryButton";
import PrimaryButton from "../../shared/PrimaryButton";
import api from "../../services/api";
import FormValidation from "../../shared/helpers/FormValidation";
import DetailHeader from "../../shared/DetailHeader";
import WhatsappPrimaryButton from "../../shared/WhatsappPrimaryButton";
import whatsappShare from "../../shared/helpers/RepLinkWhatsappShare";
import CopyToClipboard from "../../shared/helpers/CopyToClipboard";
import Snackbar from "../../shared/Snackbar";

const PersonalizedPage = ({ sellerData, showBackCta }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [slug, setSlug] = useState("");
  const [nickNameLabel, setNickNameLabel] = useState("");
  const [pageId, setPageId] = useState("");

  const [fieldHasError, setFieldHasError] = useState({});
  const [customErrorMessage, setCustomErrorMessage] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [showCopiedSnackbar, setShowCopiedSnackbar] = useState(false);
  const [showSavedSnackbar, setShowSavedSnackbar] = useState(false);

  const prefix = "rep.blu.com.br/";
  const slugRegex = new RegExp(/^[0-9a-z-]{1,50}$/gm);

  const getList = () => {
    api
      .get(`/api/v1/referrals/configs/index?referrer_id=${sellerData.id}`)
      .then((res) => {
        formik.setFieldValue(
          "nick_name_label",
          res.data.config_referral_pages[0]?.nick_name_label
        );
        formik.setFieldValue(
          "slug",
          prefix + res.data.config_referral_pages[0]?.slug
        );
        setNickNameLabel(res.data.config_referral_pages[0]?.nick_name_label);
        setSlug(res.data.config_referral_pages[0]?.slug);
        setPageId(res.data.config_referral_pages[0]?.id);
      });
  };

  const formik = useFormik({
    initialValues: {
      nick_name_label: "",
      slug: prefix,
    },
    onSubmit: (values) => {
      api
        .post(
          `/api/v1/referrals/configs/${
            slug && nickNameLabel ? "update" : "create"
          }`,
          {
            id: pageId,
            config_referral_page: {
              referrer_type: "Seller",
              referrer_id: sellerData.id,
              slug: values.slug.substr(prefix.length),
              nick_name_label: values.nick_name_label,
              type_client_target: "Client",
            },
          }
        )
        .then((res) => {
          setSlug(values.slug.substr(prefix.length));
          setNickNameLabel(values.nick_name_label);
          setIsEditing(false);
          setShowSavedSnackbar(true);
          getList();
        })
        .catch((err) =>
          FormValidation({
            err,
            setFieldHasError,
            setCustomErrorMessage,
            setRefresh,
            refresh,
            setIsLoading,
          })
        );
    },
    validate: (values) => {
      let errors = {};

      if (!/^[0-9a-z-]{1,50}$/gm.test(values.slug.substr(prefix.length)))
        errors.slug = "Seu link pode conter letras, números e hífens.";

      if (!values.nick_name_label)
        errors.nick_name_label = "Este campo não pode ficar em branco.";
      return errors;
    },
  });

  const handleCopyLink = () => {
    setShowCopiedSnackbar(true);
    CopyToClipboard(`rep.blu.com.br/${slug}`);
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    if (
      formik.values.slug.length < 15 ||
      !formik.values.slug.includes(prefix)
    ) {
      formik.setFieldValue("slug", prefix);
    } else if (
      !slugRegex.test(formik.values.slug.substr(prefix.length)) &&
      formik.values.slug !== prefix
    ) {
      formik.setFieldValue("slug", formik.values.slug.slice(0, -1));
    }
  }, [formik.values.slug]);

  return (
    <>
      {showBackCta ? (
        <DetailHeader
          backAction={() => Turbolinks.visit("/minha-conta")}
          title="Página personalizada"
          showBackArrow={showBackCta}
        />
      ) : null}
      <div className="personalized-page">
        <img src={linkImage} alt="Página personalizada" />
        <h3 className="title"> Personalize e compartilhe sua página</h3>
        <p className="paragraph">
          Com a sua página personalizada você apresenta as soluções Blu para
          seus clientes e, assim que eles se cadastrarem, a indicação é
          registrada automaticamente.
        </p>

        <div className="page-data">
          {isEditing ? (
            <div className="warn-box">
              <img src={infoIcon} />
              <p>
                Se precisar alterar seu link lembre-se de que quem já tiver
                recebido não terá mais acesso a sua página e será necessário
                reenviá-lo.
              </p>
            </div>
          ) : (
            <div className="info">
              <h4>Dados da sua página</h4>
              <div className="tooltip-container">
                <img src={questionMarkIcon} />
                <span className="tooltip">
                  Escolha um link de fácil identificação, como o seu nome. Por
                  exemplo, rep.blu.com.br/joao-silva. <br />
                  Você poderá alterá-lo depois.
                </span>
              </div>

              <button
                className="edit"
                onClick={() => setIsEditing((state) => (state ? false : true))}
              >
                <img src={editIcon} alt="Editar" /> Editar
              </button>
            </div>
          )}
          {!isEditing ? (
            <>
              <div className="data">
                <div className="field-blocked">
                  <img src={linkIcon} alt="link" />
                  <span className="url-before-slug">
                    rep.blu.com.br/
                  </span>
                  {slug || "seu-link"}
                </div>
                <hr className="separator" />
                <div className="field-blocked">
                  <img src={userIcon} alt="link" />
                  {nickNameLabel || "Seu nome ou apelido"}
                </div>
              </div>
              {slug && nickNameLabel ? (
                <div className="actions">
                  <a
                    className="custom-link"
                    target="_blank"
                    href={`/representante/${slug}`}
                  >
                    Ver página
                  </a>
                  <SecondaryButton
                    className="custom-secondary"
                    onClick={handleCopyLink}
                  >
                    Copiar link
                  </SecondaryButton>
                  <WhatsappPrimaryButton
                    action={() => whatsappShare(slug)}
                    className="primary"
                  />
                </div>
              ) : null}
            </>
          ) : (
            <form
              onSubmit={formik.handleSubmit}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  formik.handleSubmit();
                }
              }}
              className="form"
            >
              <Field
                label="Seu link personalizado"
                placeholder={prefix}
                name="slug"
                formHandler={formik}
                hasError={fieldHasError.slug}
                customError={customErrorMessage.slug}
                refresh={refresh}
              />
              {!fieldHasError.slug && !formik.errors.slug ? (
                <span className="helper-text">
                  Seu link pode conter letras, números e hífens.
                </span>
              ) : null}

              <Field
                label="Nome ou apelido"
                placeholder="Seu nome ou apelido"
                name="nick_name_label"
                formHandler={formik}
                hasError={fieldHasError.nick_name_label}
                customError={customErrorMessage.nick_name_label}
                refresh={refresh}
              />

              <div className="button-container">
                <SecondaryButton onClick={() => setIsEditing(false)}>
                  Cancelar
                </SecondaryButton>
                <PrimaryButton
                  disabled={isLoading}
                  type="submit"
                  onClick={formik.handleSubmit}
                  className="primary"
                >
                  <img src={checkIcon} alt="Salvar" />
                  <p> Salvar</p>
                </PrimaryButton>
              </div>
            </form>
          )}
        </div>
      </div>
      <Snackbar
        shouldShow={showCopiedSnackbar}
        handleClose={setShowCopiedSnackbar}
        icon={checkCircleIcon}
        text="Link Copiado"
      />
      <Snackbar
        shouldShow={showSavedSnackbar}
        handleClose={setShowSavedSnackbar}
        icon={checkCircleIcon}
        text="Dados salvos!"
      />
    </>
  );
};

export default PersonalizedPage;
