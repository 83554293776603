import React from "react";
import "./style.scss";

import zeroIcon from "../../../../assets/benefits/0.svg";
import bagIcon from "../../../../assets/benefits/shopping-bag.svg";
import calendarIcon from "../../../../assets/benefits/calendar.svg";
import starIcon from "../../../../assets/benefits/star.svg";
import discountIcon from "../../../../assets/benefits/discount.svg";

export default function BluBenefitsList() {
  return (
    <div className="blu-benefits-list">
      <ul>
        <li>
          <img src={zeroIcon} />
          <p>
            Antecipação de saldo futuro com taxa zero para comprar com
            fornecedores
          </p>
        </li>
        <li>
          <img src={bagIcon} />
          <p>Compras sem análise ou limite de crédito</p>
        </li>
        <li>
          <img src={calendarIcon} />
          <p>Mais prazo para pagar com o Boleto Blu</p>
        </li>
        <li>
          <img src={starIcon} />
          <p>Descontos exclusivos com fornecedores parceiros da Blu</p>
        </li>
        <li>
          <img src={discountIcon} />
          <p>Desconto especial em compras à vista</p>
        </li>
      </ul>
    </div>
  );
}
