export const validate = (values) => {
  let errors = {};
  let requiredMsg = "Este campo não pode ficar em branco.";
  if (!values.businessName) errors.businessName = requiredMsg;
  if (!values.tradingName) errors.tradingName = requiredMsg;
  if (!values.cep) errors.cep = requiredMsg;
  if (!values.address) errors.address = requiredMsg;
  if (!values.number) errors.number = requiredMsg;
  if (!values.state) errors.state = requiredMsg;
  if (!values.city) errors.city = requiredMsg;
  if (!values.neighbourhood) errors.neighbourhood = requiredMsg;
  if (!values.phone) errors.phone = requiredMsg;
  return errors;
};
