import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";

import "./style.scss";
import api from "../../services/api";
import DetailHeader from "../../shared/DetailHeader";
import PrimaryButton from "../../shared/PrimaryButton";
import TextAreaField from "../../shared/Form/TextAreaField";
import CardCta from "../../shared/CardCta";
import FormValidation from "../../shared/helpers/FormValidation";
import { Types } from "../../../store/ducks/opportunities";
import whatsappIcon from "../../../assets/opportunities/whatsapp.svg";
import phoneIcon from "../../../assets/opportunities/phone.svg";
import sendIcon from "../../../assets/opportunities/send.svg";

function OpportunityRefusalProcess({
  changePage,
  currentSeller,
  recentlyUpdated,
  opportunities,
}) {
  const [fieldHasError, setFieldHasError] = useState({});
  const [customErrorMessage, setCustomErrorMessage] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isMobileWidth, setIsMobileWidth] = useState();
  function handleScreenSize() {
    const mql = window.matchMedia("(max-width: 1024px)");
    setIsMobileWidth(mql.matches);
  }

  useEffect(() => {
    handleScreenSize();
    window.addEventListener("resize", handleScreenSize);
    return () => {
      window.removeEventListener("resize", handleScreenSize);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      comments: "",
    },
    onSubmit: (values) => {
      setIsLoading(true);
      api
        .post(`/api/v1/opportunities/process_business_action`, {
          opportunity: {
            id: opportunities.selectedOpportunity.id,
            seller_cpf: currentSeller.cpf_cnpj,
            payload: {
              comments: values.comments,
            },
          },
        })
        .then((res) => {
          setIsLoading(false);
          recentlyUpdated({
            recentlyUpdated: {
              comments: values.comments,
            },
          });
          console.log(res.data);
          changePage("detailUpdatedSuccess");
        })
        .catch((err) => {
          FormValidation({
            err,
            setFieldHasError,
            setCustomErrorMessage,
            setRefresh,
            refresh,
            setIsLoading,
          });
        });
    },
  });

  return (
    <>
      <DetailHeader title="" backAction={() => changePage("refusalDetail")} />
      <div className="container opportunity-refusal-process">
        <div className="content">
          <h1 className="title">
            Entre em contato com seu cliente e garanta seu prêmio
          </h1>
          <p className="description">Como quer entrar em contato com ele?</p>
          <div className="cardscontainer">
            <CardCta
              action={() => {
                Turbolinks.visit(
                  `tel:${opportunities.selectedOpportunity.opportunitable_object.client_recommendation.user_phone}`
                );
              }}
              img={phoneIcon}
              text={
                isMobileWidth ? (
                  "Ligar para meu cliente agora"
                ) : (
                  <span>
                    Você pode ligar para seu cliente pelo número{" "}
                    <em style={{ color: "#0025E0" }}>
                      {
                        opportunities.selectedOpportunity.opportunitable_object
                          .client_recommendation.user_phone
                      }
                      .
                    </em>
                  </span>
                )
              }
            />

            {opportunities.selectedOpportunity.payload?.reason_for_refusal ===
            "Representante não falou da Blu" ? (
              <CardCta
                action={() => {
                  var link = document.createElement("a");
                  link.href =
                    "assets/home/material/muitos-lojistas-e-fornecedores-ja-se-beneficiaram.png";
                  link.download = "Download.png";
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}
                img={sendIcon}
                text="Baixar material sobre a Blu"
              />
            ) : (
              <CardCta
                action={() => {
                  Turbolinks.visit(
                    `whatsapp:contact=${opportunities.selectedOpportunity.opportunitable_object.client_recommendation.user_phone}@s.whatsapp.com`
                  );
                }}
                img={whatsappIcon}
                text="Enviar mensagem via WhatsApp"
              />
            )}
          </div>

          <p className="description">
            <strong>
              Conte pra gente como foi a conversa com seu cliente.
            </strong>{" "}
            Se possível, nos indique a melhor forma de entrar em contato com
            ele.
          </p>
          <form onSubmit={formik.handleSubmit}>
            <TextAreaField
              placeholder="Ex: O cliente está animado para começar a usar as soluções da Blu!"
              name="comments"
              formHandler={formik}
              hasError={fieldHasError.comments}
              customError={customErrorMessage.comments}
              refresh={refresh}
            />

            <div className="buttoncontainer">
              <PrimaryButton type="submit">
                {isLoading ? (
                  <div className="spinner -mg-auto -white"></div>
                ) : (
                  "Enviar e finalizar"
                )}
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    recentlyUpdated: (payload) => {
      dispatch({ type: Types.RECENTLY_UPDATED, payload });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    opportunities: state.opportunities,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpportunityRefusalProcess);
