import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Types as FilterTypes } from "../../../store/ducks/dashboardFilter";
import { useFormik } from "formik";

import "./style.scss";
import arrowDownIcon from "../../../assets/general/selected-arrow-down.svg";
import chevronDown from "../../../assets/dashboard/chevron-down.svg";
import DashboardListItem from "./DashboardListItem";
import sortIcon from "../../../assets/dashboard/icn-ordenar.svg";
import SortBackdrop from "./SortBackdrop";
import StatusBackdrop from "./StatusBackdrop";

function DashboardList({ setFilter, filters }) {
  const [isMobileWidth, setIsMobileWidth] = useState();
  const [showStatusBackdrop, setShowStatusBackdrop] = useState(false);
  const [showSortBackdrop, setShowSortBackdrop] = useState(false);

  const statusList = [...filters.filterByStatus];

  const fullStatusList = [
    "processing",
    "invalid",
    "has_user_logged",
    "has_spent_500",
    "intermediate",
  ];

  const formik = useFormik({
    initialValues: {
      status:
        statusList.length > 0 ? [...statusList] : ["all", ...fullStatusList],
      sort: "",
    },
    onSubmit: (values) => {
      setShowStatusBackdrop(false);
      setFilter({
        ...filters,
        filterByStatus: [...values.status],
      });
    },
  });

  function handleScreenSize() {
    const mql = window.matchMedia("(max-width: 1024px)");
    setIsMobileWidth(mql.matches);
  }

  function handleSort(name) {
    if (filters.sortOrder === "ASC") {
      setFilter({ ...filters, sortBy: name, sortOrder: "DESC" });
    } else if (filters.sortOrder === "DESC") {
      setFilter({ ...filters, sortBy: "", sortOrder: "" });
    } else {
      setFilter({ ...filters, sortBy: name, sortOrder: "ASC" });
    }
  }

  useEffect(() => {
    handleScreenSize();
    window.addEventListener("resize", handleScreenSize);
    return () => {
      window.removeEventListener("resize", handleScreenSize);
    };
  }, []);

  useEffect(() => {}, [statusList]);

  useEffect(() => {
    switch (formik.values.sort) {
      case "clientAsc":
        setFilter({ ...filters, sortBy: "client_name", sortOrder: "ASC" });
        setShowSortBackdrop(false);
        break;
      case "clientDesc":
        setFilter({ ...filters, sortBy: "client_name", sortOrder: "DESC" });
        setShowSortBackdrop(false);
        break;
      case "dateAsc":
        setFilter({ ...filters, sortBy: "created_at", sortOrder: "ASC" });
        setShowSortBackdrop(false);
        break;
      case "dateDesc":
        setFilter({ ...filters, sortBy: "created_at", sortOrder: "DESC" });
        setShowSortBackdrop(false);
        break;
      default:
        break;
    }
  }, [formik.values.sort]);

  return (
    <>
      <div className="dashboard-list-desktop">
        <div className="listheader">
          <div
            className={
              filters.sortBy === "client_name" ||
              (isMobileWidth && filters.sortBy === "created_at")
                ? "item -selected"
                : "item "
            }
            onClick={() =>
              !isMobileWidth
                ? handleSort("client_name")
                : setShowSortBackdrop(true)
            }
          >
            {{
              [filters.sortBy === "client_name" && "ASC"]: (
                <>
                  <span>Cliente (A-Z)</span> <img src={arrowDownIcon} />
                </>
              ),
              [filters.sortBy === "client_name" && "DESC"]: (
                <>
                  <span>Cliente (Z-A)</span>
                  <img src={arrowDownIcon} className="up" />
                </>
              ),
              [filters.sortBy === "created_at" && isMobileWidth && "ASC"]: (
                <>
                  <span>Mais antigos</span> <img src={arrowDownIcon} />
                </>
              ),
              [filters.sortBy === "created_at" && isMobileWidth && "DESC"]: (
                <>
                  <span>Mais recentes</span>
                  <img src={arrowDownIcon} className="up" />
                </>
              ),
            }[filters.sortOrder] || (
              <>
                <span>Cliente</span> <img src={sortIcon} />
              </>
            )}
          </div>
          <div className="verticalseparator" />
          <div
            className={
              formik.values.status.length > 0 && formik.values.status.length < 5
                ? "item -selected"
                : "item"
            }
            onClick={() => {
              setShowStatusBackdrop(showStatusBackdrop ? false : true);
            }}
          >
            <span>
              Status
              {formik.values.status.length > 0 &&
              formik.values.status.length < 6 ? (
                <div className="badge badge--dashboard">
                  {formik.values.status.includes("all")
                    ? formik.values.status.length - 1
                    : formik.values.status.length}
                </div>
              ) : null}
            </span>
            <img src={chevronDown} />
          </div>
          <div
            className={
              filters.sortBy === "created_at"
                ? "item -selected -date"
                : "item -date"
            }
            onClick={() => handleSort("created_at")}
          >
            {{
              [filters.sortBy === "created_at" && "ASC"]: (
                <>
                  <span>Mais antigos</span> <img src={arrowDownIcon} />
                </>
              ),
              [filters.sortBy === "created_at" && "DESC"]: (
                <>
                  <span>Mais recentes</span>
                  <img src={arrowDownIcon} className="up" />
                </>
              ),
            }[filters.sortOrder] || (
              <>
                <span>Data</span> <img src={sortIcon} />
              </>
            )}
          </div>
        </div>
        {filters.filteredClientList.map((report) => (
          <DashboardListItem
            key={report.client_recommendation.id}
            name={report.client_recommendation.client_name}
            cnpj={report.client_recommendation.client_cpf_cnpj}
            date={report.client_recommendation.created_at}
            rawObject={report}
            status={report.status}
          />
        ))}
        <hr className="separator" />
      </div>

      <StatusBackdrop
        isOpen={showStatusBackdrop}
        formHandler={formik}
        name="status"
        close={() => setShowStatusBackdrop(false)}
        statusList={fullStatusList}
      />
      <SortBackdrop
        formHandler={formik}
        name="sort"
        isOpen={showSortBackdrop}
        close={() => setShowSortBackdrop(false)}
        isMobile
      />
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFilter: (payload) => {
      dispatch({ type: FilterTypes.SET_FILTER, payload });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    filters: state.dashboardFilter,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardList);
