import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "./style.scss";
import arrowUp from "../../../../assets/checkout/arrow-up.svg";
import order from "../../../../assets/checkout/order.svg";
import CartItem from "./CartItem";
import Backdrop from "../../../shared/Backdrop";
import CurrencyTextFormatter from "../../../shared/CurrencyTextFormatter";
import { itemValues } from "../../../shared/helpers/itemValues";
function Summary({ items, handleToggle, isOpen, removeItem, goToDetailEdit }) {
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [selectedItem, setSelectedItem] = useState({ product: { name: "" } });
  const [subtotal, setSubtotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);

  function handleShowBackdrop(item) {
    setShowBackdrop(true);
    setSelectedItem(item);
  }

  function handleCloseBackdrop() {
    setShowBackdrop(false);
  }

  function handleOpenSummary() {
    handleToggle();
  }

  function handleItemValues() {
    let tempSubtotal = 0,
      tempDiscount = 0,
      tempTotalQuantity = 0;

    items.forEach((el) => {
      tempSubtotal += itemValues(el, "subtotal");
      tempDiscount += itemValues(el, "discount");
      tempTotalQuantity += el.quantity;
    });
    setSubtotal(tempSubtotal);
    setDiscount(tempDiscount);
    setTotalQuantity(tempTotalQuantity);
  }

  useEffect(() => {
    handleItemValues();
  }, [items]);

  return (
    <>
      <div className={isOpen ? "order-summary -open" : "order-summary"}>
        <div className="container content">
          <div className="summaryinfo">
            <div className="upcta" onClick={handleOpenSummary}>
              <img src={arrowUp} className="arrow" />
              <span>Seu Pedido</span>
            </div>
            <div className="total">
              <span className="text">
                Total
                <em>
                  <CurrencyTextFormatter value={subtotal - discount} />
                </em>
              </span>
            </div>
            <div className="ordericn">
              <div className="orderbadge">{totalQuantity}</div>
              <img src={order} />
            </div>
          </div>

          <div className="items">
            {items.map((item, index) => (
              <CartItem
                key={index}
                item={item}
                openActions={handleShowBackdrop}
                removeItem={removeItem}
                goToDetailEdit={goToDetailEdit}
              />
            ))}
          </div>
          <div className="summaryfooter">
            <div className="info">
              <span className="text">Subtotal</span>
              <span className="price">
                <CurrencyTextFormatter value={subtotal} />
              </span>
            </div>
            <div className="info">
              <span className="text">Descontos</span>
              <span className="price">
                -<CurrencyTextFormatter value={discount} />
              </span>
            </div>
            <div className="info -total">
              <span className="text">Total</span>
              <span className="price">
                <CurrencyTextFormatter value={subtotal - discount} />
              </span>
            </div>
            <a href="/pedidos/complemento" className="button">Continuar</a>
          </div>
        </div>
      </div>
      <Backdrop isOpen={showBackdrop} close={handleCloseBackdrop}>
        <div className="backdropitemaction">
          <span className="title">{selectedItem.product.name}</span>
          <hr />
          <div className="actions">
            <button
              className="action"
              onClick={() => goToDetailEdit(selectedItem)}
            >
              Editar Produto
            </button>
            <button
              className="action -delete"
              onClick={() => {
                removeItem(selectedItem);
                setShowBackdrop(false);
              }}
            >
              Remover Produto
            </button>
          </div>
        </div>
      </Backdrop>
    </>
  );
}

Summary.propTypes = {
  items: PropTypes.array.isRequired,
};

export default Summary;
