import React from "react";
import PropTypes from "prop-types";
import arrowUp from "../../../assets/checkout/arrow-up.svg";
import "./style.scss";

function MenuDropdownDesktop({
  icon,
  title,
  children,
  isOpen,
  toggle,
  showArrow,
  showNewBadge,
  id,
}) {
  return (
    <div
      className={
        isOpen ? "menu-dropdown-desktop -open" : "menu-dropdown-desktop"
      }
    >
      <div className="title" id={id} onClick={() => toggle()}>
        {icon ? <img src={icon} className="icon" /> : null}
        <span className="titletext">
          {title}
          {showNewBadge ? (
            <div className="badge badge--new"> Novo</div>
          ) : null}
        </span>

        {showArrow ? <img src={arrowUp} className="arrow" /> : null}
      </div>
      <div className="content ">{children ? children : null}</div>
    </div>
  );
}

MenuDropdownDesktop.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  customClass: PropTypes.string,
  showArrow: PropTypes.bool,
  showBadge: PropTypes.bool,
  id: PropTypes.string,
};

MenuDropdownDesktop.defaultProps = {
  icon: "",
  children: undefined,
  showArrow: true,
  isOpen: false,
  showBadge: false,
  id: null
};
export default MenuDropdownDesktop;
