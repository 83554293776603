import 'mailcheck'

$(document).on('ready turbolinks:load', function() {
  $('[data-mask="email"], [data-to-validate="email"]').on('keydown', function() {
    $('.mail-suggestion').hide();
  });

  $('[data-mask="email"], [data-to-validate="email"]').on('blur', function() {
    $(this).mailcheck({
      topLevelDomains: ['com', 'net', 'org', 'com.br', 'info', 'edu'],
      suggested: function(element, suggestion) {
        $('.mail-suggestion').html(`você quis dizer <em class='mail-highlight'>${suggestion.full}</em>?`)
        $('.mail-suggestion').show();
      }
    });
  });

  $(document).on('click', '.mail-highlight', function() {
    let suggestionValue = $(this).text()
    $('[data-mask="email"], [data-to-validate="email"]').val(suggestionValue)
    $('.mail-suggestion').hide();
  });
});
