import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import store from "../../store";

import "./style.scss";
import RpaCta from "./RpaCta";
import MoreContent from "./MoreContent";
import MainWidgets from "./MainWidgets";
import api from "../services/api";
import OpportunitiesFirstAccess from "./OpportunitiesFirstAccess";
import ActionCards from "./ActionCards";
import RegisterIntervention from "./RegisterIntervention";

export default function LoggedHome({ currentSeller, portalHost, httpReferer }) {
  const [isInterventionModalOpen, setIsInterventionModalOpen] = useState(true);
  const [registrationStatusDone, setRegistrationStatusDone] = useState(true);
  useEffect(() => {
    api.get(`/api/v1/sellers/${currentSeller.cpf_cnpj}`).then((res) => {
      const {
        address_data_present,
        doc_data_present,
        personal_data_present,
      } = res.data.analytics.registration_status;

      address_data_present && doc_data_present && personal_data_present
        ? setRegistrationStatusDone(true)
        : setRegistrationStatusDone(false);
    });
  }, []);

  const hasInterventionStatus =
    currentSeller.automatic_registration_status ===
      "company_documentation_reproved" ||
    currentSeller.automatic_registration_status ===
      "waiting_company_documentation_upload";
  const hasLoginReferer =
    httpReferer &&
    httpReferer.substring(httpReferer.lastIndexOf("/") + 1) === "login";
  const shouldIntervene = hasInterventionStatus && hasLoginReferer;

  return (
    <Provider store={store}>
      <div className="logged-home">
        <div className="container banner">
          {!registrationStatusDone ? <RpaCta /> : null}
        </div>
        {shouldIntervene ? (
          <RegisterIntervention
            portalHost={portalHost}
            status={currentSeller.automatic_registration_status}
            isOpen={isInterventionModalOpen}
            close={() => setIsInterventionModalOpen(false)}
          />
        ) : null}
        <ActionCards />
        <MainWidgets currentSeller={currentSeller} />
        <MoreContent />
        <OpportunitiesFirstAccess />
      </div>
    </Provider>
  );
}
