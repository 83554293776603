import React from "react";

import "./style.scss";
import noneImage from "../../../../assets/opportunities/none.svg";
import arrowIcon from "../../../../assets/general/arrow-right-primary.svg";

export default function NoOpportunities() {
  return (
    <div className="no-opportunities">
      <div className="imgcontainer">
        <img src={noneImage} style={{ margin: "auto" }} />
      </div>
      <p className="notfoundtext">
        Nenhuma oportunidade para você por enquanto.
      </p>
      <div className="linkcontainer">
        <a className="link" href="/oportunidades?page=description">
          Saiba mais sobre as Oportunidades <img src={arrowIcon} />
        </a>
      </div>
    </div>
  );
}
