import { all } from "redux-saga/effects";

import watchRecommendationsListRequest from "./dashboardRecommendationsSaga";
import watchOpportunitiesRequest from "./opportunitiesSaga";
import watchFindPersonalizedPageRequest from "./personalizedPageSaga";

export default function* rootSaga() {
  yield all([
    watchRecommendationsListRequest(),
    watchOpportunitiesRequest(),
    watchFindPersonalizedPageRequest(),
  ]);
}
