import { call, takeLatest, put } from "redux-saga/effects";
import { Types } from "../ducks/dashboardRecommendations";
import api from "../../components/services/api";

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));
export const stateSuppliers = (state) => state.suppliersList.data.suppliers;

function getRecommendationsList(sellerCpf) {
  return api.get(`/api/v1/sellers/${sellerCpf}/dashboard`);
}

export function* findRecommendationsList(action) {
  try {
    if (action.payload.searchString) {
      yield call(delay, 500);
    }

    const response = yield call(
      getRecommendationsList,
      action.payload.sellerCpf
    );

    yield put({
      type: Types.SUCCESS,
      payload: {
        awards: response.data.awards,
        report_client_recommendations: [
          ...response.data.report_client_recommendations,
        ],
        report_client_charges: [
          ...response.data.report_client_charges,
        ],
      },
    });
  } catch (error) {
    yield console.log(error);
    yield put({ type: Types.FAIL });
  }
}

export default function* watchRecommendationsListRequest() {
  yield takeLatest(Types.REQUEST, findRecommendationsList);
}
