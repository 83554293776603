import React from "react";

import Main from "./Main";

import { Provider } from "react-redux";
import store from '../../store'


function Dashboard({currentSeller}) {
  return (
    <Provider store={store}>
      <Main currentSeller={currentSeller}/>
    </Provider>
  );
}


export default Dashboard;
