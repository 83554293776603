import React from "react";

import "./style.scss";
import CpfCnpjFormatter from "../CpfCnpjFormatter";

function InfoWithIcon({ isCpfCnpj, description, primaryText, icon }) {
  return (
    <div className="info-with-icon">
      <div className="image">
        <img src={icon} />
      </div>
      <div className="text">
        <span>
          {isCpfCnpj ? (
            <CpfCnpjFormatter unmaskedCpfCnpj={description} />
          ) : (
            description
          )}
        </span>
        <span className="primary">{primaryText}</span>
      </div>
    </div>
  );
}

export default InfoWithIcon;
