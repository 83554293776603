import React from "react";
import PropTypes from "prop-types";
import "./style.scss";
import CpfCnpjFormatter from "../../shared/CpfCnpjFormatter";

function ClientData({ client }) {
  return (
    <div className="overview-client-data">
      <div className="field">
        <div className="fieldtitle">CNPJ ou CPF</div>
        <div className="fieldvalue">
          <CpfCnpjFormatter unmaskedCpfCnpj={client.cpfCnpj} />
        </div>

      </div>
      <hr className="separator" />
      <div className="field">
        <div className="fieldtitle">Razão Social</div>
        <div className="fieldvalue">{client.businessName}</div>
      </div>
      <hr className="separator" />
      <div className="field">
        <div className="fieldtitle">Nome Fantasia</div>
        <div className="fieldvalue">{client.tradingName}</div>
      </div>
      <hr className="separator" />
      <div className="field">
        <div className="fieldtitle">Inscrição estadual</div>
        <div className="fieldvalue">
          {client.stateRegistration ? client.stateRegistration : "-"}
        </div>
      </div>
      <hr className="separator" />
      <div className="field">
        <div className="fieldtitle">Razão Social</div>
        <div className="fieldvalue">
          {`${client.address}, 
							${client.number} - 
							${client.neighbourhood}, 
							${client.city}, 
							${client.state} `}
        </div>
      </div>
      <hr className="separator" />
      <div className="field">
        <div className="fieldtitle">Nome do responsável</div>
        <div className="fieldvalue">{client.clientName}</div>
      </div>
      <hr className="separator" />
      <div className="field">
        <div className="fieldtitle">Email do responsável</div>
        <div className="fieldvalue">{client.clientEmail}</div>
      </div>
      <hr className="separator" />
      <div className="field">
        <div className="fieldtitle">Telefone</div>
        <div className="fieldvalue">{client.phone}</div>
      </div>
    </div>
  );
}

ClientData.propTypes = {
  client: PropTypes.object.isRequired,
};

export default ClientData;
