import React from "react";
import PropTypes from "prop-types";
import "./style.scss";
import ProductListItem from "./ProductListItem";
import InfiniteScroll from "react-infinite-scroll-component";

const ProductsList = ({
  goToDetail,
  products,
  suppliers,
  hasMore,
  findMore,
  productCount,
}) => {
  return (
    <ul className="products-list">
      {productCount > 0 ? (
        <InfiniteScroll
          dataLength={products.length}
          next={() => findMore(suppliers)}
          hasMore={hasMore}
          loader={
            <div className="list-spinner-container">
              <div className="spinner"></div>&nbsp;Carregando
            </div>
          }
        >
          {products.map((product) => (
            <li key={product.id} onClick={() => goToDetail(product)}>
              <ProductListItem product={product} />
            </li>
          ))}
        </InfiniteScroll>
      ) : (
        <div className="noitemsfound">
          <span className="text">Não encontramos nenhum resultado</span>
        </div>
      )}
    </ul>
  );
};

ProductsList.propTypes = {
  goToDetail: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  suppliers: PropTypes.array.isRequired,
  hasMore: PropTypes.bool.isRequired,
  findMore: PropTypes.func.isRequired,
  productCount: PropTypes.number.isRequired,
};

export default ProductsList;
