import React from "react";
import Backdrop from "../../../shared/Backdrop";
import BooleanField from "../../../shared/Form/BooleanField";

export default function SortBackdrop({
  isOpen,
  close,
  formHandler,
  name,
  isMobile,
  isOrder,
}) {
  return (
    <div>
      <Backdrop isOpen={isOpen} close={close}>
        <form onSubmit={formHandler.handleSubmit}>
          <h3> Ordenar por </h3>
          <hr
            className="separator"
            style={{
              margin: "12px 0",
              border: 0,
              borderBottom: "1px solid #d8dae8",
            }}
          />
          <BooleanField
            name={name}
            value="clientAsc"
            formHandler={formHandler}
            labelColor="#6b708b "
            label="Cliente (A-Z)"
            type="radio"
            isRow
          />
          <BooleanField
            name={name}
            value="clientDesc"
            formHandler={formHandler}
            label="Cliente (Z-A)"
            type="radio"
            isRow
            labelColor="#6b708b"
          />
          {isOrder ? (
            <>
              <BooleanField
                name={name}
                value="supplierAsc"
                formHandler={formHandler}
                labelColor="#6b708b "
                label="Fornecedor (A-Z)"
                type="radio"
                isRow
              />
              <BooleanField
                name={name}
                value="supplierDesc"
                formHandler={formHandler}
                label="Fornecedor (Z-A)"
                type="radio"
                isRow
                labelColor="#6b708b"
              />
            </>
          ) : null}
          {isMobile ? (
            <>
              <BooleanField
                name={name}
                value="dateDesc"
                formHandler={formHandler}
                label="Mais recentes"
                type="radio"
                isRow
                labelColor="#6b708b "
              />
              <BooleanField
                name={name}
                value="dateAsc"
                formHandler={formHandler}
                label="Mais antigos"
                type="radio"
                isRow
                labelColor="#6b708b "
              />
            </>
          ) : null}
        </form>
      </Backdrop>
    </div>
  );
}
