import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Types } from "../../../store/ducks/opportunities";

import "./style.scss";
import chevronRight from "../../../assets/general/chevron-right.svg";
import OpportunityCard from "../../Opportunities/OpportunityCard";
import NoOpenOpportunities from "../../Opportunities/OpportunitiesList/NoOpenOpportunities";
import NoOpportunities from "../../Opportunities/OpportunitiesList/NoOpportunities";
import PersonalizedPageWidget from "./PersonalizedPageWidget";

function MainWidgets({
  requestOpportunities,
  opportunities,
  currentSeller,
  selectOpportunity,
}) {
  useEffect(() => {
    requestOpportunities({ sellerCpf: currentSeller.cpf_cnpj });
  }, []);
  return (
    <div className="main-widgets container">
      <PersonalizedPageWidget currentSeller={currentSeller} />
      <div className="opportunitieslist">
        <h2 className="title">
          Oportunidades{"  "}
          <div className="badge badge--dashboard">
            {opportunities.openList.length}
          </div>{" "}
          <a className="link" href="/oportunidades">
            Ver tudo <img src={chevronRight} />
          </a>
        </h2>

        <div className="list">
          <>
            {opportunities.openList.length > 0 ? (
              <>
                {opportunities.openList.map((opportunity, index) => (
                  <>
                    {index < 3 ? (
                      <div key={opportunity.id}>
                        <OpportunityCard
                          isWidget
                          changePage={() => {
                            selectOpportunity({
                              selectedOpportunity: opportunity,
                            });
                            Turbolinks.visit("/oportunidades?page=detail");
                          }}
                          clientName={
                            opportunity.opportunitable_object
                              .client_recommendation.client_name
                          }
                          opportunity={opportunity}
                          businessAction={opportunity.business_action}
                          payload={opportunity.payload}
                        />
                      </div>
                    ) : null}
                  </>
                ))}
              </>
            ) : (
              <>
                {opportunities.closedList.length > 0 ? (
                  <NoOpenOpportunities />
                ) : (
                  <NoOpportunities />
                )}
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    requestOpportunities: (payload) => {
      dispatch({ type: Types.REQUEST, payload });
    },
    selectOpportunity: (payload) => {
      dispatch({ type: Types.SELECT_OPPORTUNITY, payload });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    opportunities: state.opportunities,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MainWidgets);
