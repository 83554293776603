import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import axios from "axios";

import okIcon from "../../../../assets/myAccount/ok-circle.svg";
import "./style.scss";
import BottomButtonContainer from "../../../shared/BottomButtonContainer";
import DetailHeader from "../../../shared/DetailHeader";
import Field from "../../../shared/Form/Field";
import PrimaryButton from "../../../shared/PrimaryButton";
import SelectField from "../../../shared/Form/SelectField";
import api from "../../../services/api";
import Snackbar from "../../../shared/Snackbar";
import FormValidation from "../../../shared/helpers/FormValidation";
import { checkValidPISNumber } from "../../../shared/helpers/InputValidators";

export default function Documents({
  isRpa,
  customBack,
  customNext,
  showBackCta,
  sellerData,
  updateData,
}) {
  const [fieldHasError, setFieldHasError] = useState({});
  const [customErrorMessage, setCustomErrorMessage] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);

  const [ufs, setUfs] = useState([]);

  const formik = useFormik({
    initialValues: {
      pis_number: sellerData.pis_number || "",
      personal_id_number: sellerData.personal_id_number || "",
      personal_id_number_emitter: sellerData.personal_id_number_emitter || "",

      // DEPRECIATED ON REP-709 BACKLOG
      // ctps_number: sellerData.ctps_number || "",
      // ctps_serie: sellerData.ctps_serie || "",
      // ctps_state: sellerData.ctps_state || "",
    },
    validate: (values) => {
      let errors = {};
      let requiredMsg = "Este campo não pode ficar em branco.";
      let invalidDocument ='O documento informado não é válido.'
      if (!checkValidPISNumber(values.pis_number)) errors.pis_number = 'PIS inválido';
      if (!values.personal_id_number) errors.personal_id_number = requiredMsg;
      if (values.personal_id_number && values.personal_id_number.length < 6) errors.personal_id_number = invalidDocument;
      if (!values.personal_id_number_emitter)
        errors.personal_id_number_emitter = requiredMsg;
      
      // DEPRECIATED ON REP-709 BACKLOG
      // if (!values.ctps_number) errors.ctps_number = requiredMsg;
      // if (!values.ctps_serie) errors.ctps_serie = requiredMsg;
      // if (!values.ctps_state) errors.ctps_state = requiredMsg;
      return errors;
    },
    onSubmit: (values) => {
      setIsLoading(true);
      api
        .patch(`/api/v1/sellers/${sellerData.cpf_cnpj}`, {
          seller: {
            pis_number: values.pis_number,
            personal_id_number: values.personal_id_number,
            personal_id_number_emitter: values.personal_id_number_emitter,
            
            // DEPRECIATED ON REP-709 BACKLOG
            // ctps_number: values.ctps_number,
            // ctps_serie: values.ctps_serie,
            // ctps_state: values.ctps_state,
            verify_attributes: [
              "pis_number",
              "personal_id_number",
              "personal_id_number_emitter",
              // DEPRECIATED ON REP-709 BACKLOG
              // "ctps_number",
              // "ctps_serie",
              // "ctps_state",
            ],
          },
        })
        .then((res) => {
          setIsLoading(false);
          setShowSuccessSnackbar(true);
          if (customNext) {
            customNext();
          }
        })
        .catch((err) => {
          FormValidation({
            err,
            setFieldHasError,
            setCustomErrorMessage,
            setRefresh,
            refresh,
            setIsLoading,
          });
        });
      isRpa && updateData(values);
    },
  });

  async function handleGetUfs() {
    const response = await axios.get(
      "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
    );
    const mappedUfs = [];
    response.data.map((el) =>
      mappedUfs.push({ value: el.sigla, label: el.sigla, id: el.id })
    );
    setUfs(mappedUfs);
  }

  useEffect(() => {
    handleGetUfs();
    window.scrollTo(0, -1000);
  }, []);

  return (
    <>
      <DetailHeader
        backAction={() =>
          customBack ? customBack() : Turbolinks.visit("#meus-dados")
        }
        title="Documentos"
        showBackArrow={showBackCta}
      />
      <div className="container">
        <div className="account-documents">
          {isRpa ? (
            <h1 className="rpa-title">Preencha os seguintes documentos</h1>
          ) : null}
          <form onSubmit={formik.handleSubmit}>
            <div className="formrow formseparator">
              <Field
                name="pis_number"
                label="Número do PIS"
                placeholder="Informe o número do PIS"
                formHandler={formik}
                className="field"
                hasError={fieldHasError.pis_number}
                customError={customErrorMessage.pis_number}
                refresh={refresh}
                mask="999.99999.99-9"
              />
            </div>
            <div className="formrow formseparator">
              <Field
                name="personal_id_number"
                label="Documento de identidade"
                placeholder="Informe o número da sua identidade"
                mask="***************"
                formHandler={formik}
                className="field"
                hasError={fieldHasError.personal_id_number}
                customError={customErrorMessage.personal_id_number}
                refresh={refresh}
              />
              <Field
                name="personal_id_number_emitter"
                label="Órgão emissor"
                placeholder="Informe o órgão emissor"
                formHandler={formik}
                className="field"
                hasError={fieldHasError.personal_id_number_emitter}
                customError={customErrorMessage.personal_id_number_emitter}
                refresh={refresh}
              />
            </div>

            {/* DEPRECIATED ON REP-709 BACKLOG 
            <div className="formrow">
              <Field
                name="ctps_number"
                label="Número da carteira de trabalho"
                placeholder="Informe o nº da carteira"
                formHandler={formik}
                className="field"
                hasError={fieldHasError.ctps_number}
                customError={customErrorMessage.ctps_number}
                refresh={refresh}
                mask="9999999"
              />
              <Field
                name="ctps_serie"
                label="Série"
                placeholder="Informe o nº de série"
                mask="9999-9"
                formHandler={formik}
                className="field"
                hasError={fieldHasError.ctps_serie}
                customError={customErrorMessage.ctps_serie}
                refresh={refresh}
              />
            </div>

            <div className="formrow">
              <SelectField
                name="ctps_state"
                label="UF"
                options={ufs}
                formHandler={formik}
                hasError={fieldHasError.address_state}
                customError={customErrorMessage.address_state}
                refresh={refresh}
              />
            </div> */}
            <br />
            <BottomButtonContainer>
              <PrimaryButton type="submit">
                {isLoading ? (
                  <div className="spinner -white -mg-auto"></div>
                ) : isRpa ? (
                  "Avançar"
                ) : (
                  "Salvar"
                )}
              </PrimaryButton>
            </BottomButtonContainer>
          </form>
        </div>
      </div>
      {!isRpa ? (
        <Snackbar
          shouldShow={showSuccessSnackbar}
          text="Dados salvos com sucesso"
          icon={okIcon}
          handleClose={() => setShowSuccessSnackbar(false)}
        />
      ) : null}
    </>
  );
}
