import React from "react";
import PropTypes from "prop-types";

import arrowUp from "../../../assets/checkout/arrow-up.svg";
import "./style.scss";

function AccordionComponent({
  icon,
  title,
  children,
  badgeValue,
  isOpen,
  toggle,
  name,
}) {
  return (
    <div
      className={isOpen ? "accordion-component -open" : "accordion-component"}
    >
      <div
        style={{
          background: "#fff",
          borderLeft: "4px solid #173AF2",
          color: "#2D3250",
        }}
        className="title"
        onClick={() => toggle(name)}
      >
        {icon ? <img src={icon} className="icon" /> : null}
        <span className="titletext">{title}</span>
        {badgeValue > 0 ? (
          <div className="accordion-badge"> {badgeValue} </div>
        ) : null}
        <img src={arrowUp} className="arrow" />
      </div>
      <div className="content ">{children}</div>
    </div>
  );
}

AccordionComponent.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  badgeValue: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  customClass: PropTypes.string,
};

AccordionComponent.defaultProps = {
  icon: "",
  badgeValue: 0,
};
export default AccordionComponent;
