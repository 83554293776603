import React from "react";
import PropTypes from "prop-types";

import "./style.scss";
import OverviewItem from "./OverviewItem";

function OverviewItems({ items }) {
  return (
    <div className="overview-items">
      {items.map((item, index) => (
        <OverviewItem item={item} key={index} />
      ))}
    </div>
  );
}

OverviewItems.propTypes = {
  items: PropTypes.array.isRequired,
};

export default OverviewItems;
