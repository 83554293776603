export const customStyles = {
  container: (provided) => ({
    ...provided,
    marginBottom: 20,
  }),
  control: (provided) => ({
    ...provided,
    border: "1px solid #D8DAE8",
  }),
  input: (provided) => ({
    ...provided,
    padding: "10.5px 0",
  }),
  menu: base => {
    return {
      ...base,
      zIndex: 9999
    }
  },
  menuPortal: base => {
    return {
      ...base,
      zIndex: 9999
    }
  }
};

export const customErrorStyles = {
  ...customStyles,
  control: (provided) => ({
    ...provided,
    border: "1px solid #FF4343",
  }),
};

