import React from "react";

import "./style.scss";
import Feedback2 from "./Feedback2";

export default function Feedback({
  clientName,
  supplierName,
  firstCharge,
  feedback,
}) {
  return (
    <div className="container recommendation-feedback">
      <Feedback2 clientName={clientName} supplierName={supplierName} />,
    </div>
  );
}
