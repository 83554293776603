import React, { useEffect } from "react";
import BalanceHeader from "../../shared/BalanceHeader";

import { connect } from "react-redux";
import { Types as RecommendationsListTypes } from "../../../store/ducks/dashboardRecommendations";

function HeaderBalance({
  currentSeller,
  clientRecommendations,
  requestRecommendationsList,
}) {
  useEffect(() => {
    requestRecommendationsList({ sellerCpf: currentSeller.cpf_cnpj });
  }, []);
  
  return (
    <div>
      <BalanceHeader total={clientRecommendations.awards.total}
        clientTotal={clientRecommendations.awards.client_recommendations}
        orderTotal={clientRecommendations.awards.client_charges} />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    requestRecommendationsList: (payload) => {
      dispatch({ type: RecommendationsListTypes.REQUEST, payload });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    clientRecommendations: state.dashboardRecommendations,
    filters: state.dashboardFilter,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderBalance);
