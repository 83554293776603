import React from "react";

import { connect } from "react-redux";
import { Types } from "../../../../store/ducks/dashboardActiveTab";

import Tabs from "../../../shared/Tabs";
import Cards from "../../Cards";
import RecommendationsList from "../../RecommendationsList";

function Desktop({ setActiveTab, dashboardActiveTab, recommendations,currentSeller }) {

  function handleChangeTab(tabName) {
    setActiveTab({ activeTab: tabName });
  }

  return (
    <>
      <div className="container">
        <h1 className="title">Minhas indicações</h1>

        <Tabs
          currentTab={dashboardActiveTab.activeTab}
          changeTab={handleChangeTab}
          tabs={[
            {
              name: "overview",
              title: "Resumo",
              hasBadge: false,
              content: <Cards currentSeller={currentSeller}/>,
            },
            {
              name: "recommendations",
              title: "Indicação premiada",
              hasBadge: true,
              badgeValue: recommendations.report_client_recommendations
                ? recommendations.report_client_recommendations.length
                : 0,
              content: <RecommendationsList />,
            },
          ]}
        />
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setActiveTab: (payload) => {
      dispatch({ type: Types.SET_ACTIVE_TAB, payload });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    dashboardActiveTab: state.dashboardActiveTab,
    recommendations: state.dashboardRecommendations,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Desktop);
