import React, { useEffect, useState } from "react";

import "./style.scss";
import mainImage from "../../assets/representativePage/main-image.png";
import mainDesktopImage from "../../assets/representativePage/main-image-desk.png";
import creditCardIcon from "../../assets/representativePage/credit-card-outline.svg";
import cashIcon from "../../assets/representativePage/cash-outline.svg";
import tagIcon from "../../assets/representativePage/tag-outline.svg";
import one from "../../assets/representativePage/1.svg";
import two from "../../assets/representativePage/2.svg";
import three from "../../assets/representativePage/3.svg";
import taxas from "../../assets/representativePage/icn-taxas.svg";
import economia from "../../assets/representativePage/icn-economia.svg";
import exclusivos from "../../assets/representativePage/icn-exclusivos.svg";
import centralizar from "../../assets/representativePage/icn-centralizar.svg";
import maquininhas from "../../assets/representativePage/maquininhas.png";
import maquininhasDesktop from "../../assets/representativePage/maquininhas-desk.png";
import moneyOutline from "../../assets/representativePage/money-outline.svg";
import blu from "../../assets/representativePage/blu.svg";
import packageIcon from "../../assets/representativePage/package.svg";
import calculator from "../../assets/representativePage/ilustra-calcular.svg";
import calculatorImage from "../../assets/representativePage/img-calculadora.png";
import calculatorImageDesktop from "../../assets/representativePage/img-calculadora-desk.png";
import calculatorImageTablet from "../../assets/representativePage/img-calculadora-tablet.svg";
import partnershipImage from "../../assets/representativePage/partnership.png";
import partnershipDesktopImage from "../../assets/representativePage/partnership-desk.png";
import PrimaryButton from "../shared/PrimaryButton";
import RegisterModal from "./RegisterModal";
import bluLogo from "../../assets/representativePage/blu-logo.svg";
import bluLogoBlue from "../../assets/representativePage/blu-logo-blue.svg";
import notFoundImage from "../../assets/representativePage/not-found.svg";

import api from "../services/api";

export default function RepresentativePage({ representative, portalHost }) {
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [repData, setRepData] = useState();
  const [noPageFound, setNoPageFound] = useState(false);

  const handleOpenModal = () => {
    setIsRegisterModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsRegisterModalOpen(false);
  };

  const handleGoHome = () => {
    Turbolinks.visit("/");
  };

  useEffect(() => {
    api
      .get(
        `/api/v1/referrals/configs/find_by?referrer_type=Seller&slug=${representative}`
      )
      .then((res) => setRepData(res?.data?.config_referral_page))
      .catch((err) => setNoPageFound(true));
  }, []);

  return (
    <div className="representative-page">
      {noPageFound ? (
        <div className="representative-page-not-found container">
          <img className="logo" src={bluLogoBlue} onClick={handleGoHome} />
          <img src={notFoundImage} alt="Página não encontrada" />
          <h4>Ops, essa página não existe</h4>
          <p>
            Entre em contato com seu representante. Pode ser que o endereço
            tenha sido alterado ou digitado errado.
          </p>
        </div>
      ) : (
        <>
          <div className="head">
            <section className="rep">
              <div className="head-content">
                <img src={bluLogo} onClick={handleGoHome} />
                <div className="text-container">
                  <h2 className="representative-page-title">
                    Olá :) <br />
                    <br />
                    Eu sou <em>{repData?.nick_name_label} </em> e quero te
                    apresentar a solução que vai fazer você{" "}
                    <em>economizar até 10%</em> ao comprar com seus
                    fornecedores.
                  </h2>
                  <button
                    onClick={handleOpenModal}
                    className="button button--green"
                  >
                    Comece a pagar com a Blu
                  </button>
                </div>
              </div>
              <img src={mainImage} className="-mobile" />
              <img src={mainDesktopImage} className="-desktop" />
            </section>
          </div>
          <section className="blu-advantages">
            <div className="blu-advantages-content">
              <div className="blu-advantages-item">
                <img src={creditCardIcon} />
                <div className="text">
                  <h4>Venda em até 12x</h4>
                  <p>sem se preocupar com seu fluxo de caixa</p>
                </div>
              </div>
              <hr className="separator" />
              <div className="blu-advantages-item">
                <img src={cashIcon} />
                <div className="text">
                  <h4>Compre sem análise ou limite de crédito</h4>
                  <p>com fornecedores parceiros</p>
                </div>
              </div>
              <hr className="separator" />
              <div className="blu-advantages-item">
                <img src={tagIcon} />
                <div className="text">
                  <h4>Economize até 10%</h4>
                  <p>com taxa zero de antecipação</p>
                </div>
              </div>
            </div>
          </section>

          <section className="payment-solution">
            <h2>Como funciona a solução de pagamento a fornecedores da Blu?</h2>
            <div className="items">
              <div className="item">
                <img src={one} />
                <div className="text">
                  <h4>Faça sua negociação comigo</h4>
                  <p>
                    Seu pagamento será feito via Blu e você não precisa mais se
                    preocupar com limite de crédito para tirar o seu pedido.
                  </p>
                </div>
              </div>
              <div className="item">
                <img src={two} />
                <div className="text">
                  <h4>Você escolhe como quer pagar</h4>
                  <p>
                    Comprar com seu fornecedor via Blu é sempre a melhor opção!
                    Você não paga taxa de antecipação, gerando até 10% de
                    economia para sua loja.
                  </p>
                </div>
              </div>
              <div className="item">
                <img src={three} />
                <div className="text">
                  <h4>Aceitou a cobrança, recebeu!</h4>
                  <p>
                    O fornecedor envia a cobrança direto pela plataforma da Blu.
                    Assim que você aceitar, o fornecedor irá enviar a mercadoria
                    para você.
                  </p>
                </div>
              </div>
            </div>
            <PrimaryButton onClick={handleOpenModal}>
              Comece a pagar com a Blu
            </PrimaryButton>
          </section>

          <section className="receivables">
            <h2>
              Centralize os recebíveis de outras maquininhas na Blu sem nenhum
              custo para contratar ou cancelar
            </h2>
            <div className="receivables-items">
              <div className="receivables-item">
                <img src={taxas} />
                <h3>Melhores taxas</h3>
                <p>
                  Você pode optar por reduzir a sua atual taxa de antecipação
                  pontual ou ter acesso a uma taxa fixa de antecipação de 5,99%
                  na antecipação automática.
                </p>
              </div>

              <div className="receivables-item">
                <img src={economia} />
                <h3>Mais economia</h3>
                <p>
                  Você fica com mais saldo para pagar fornecedores com taxa ZERO
                  de antecipação.
                </p>
              </div>

              <div className="receivables-item">
                <img src={exclusivos} />
                <h3>Produtos exclusivos</h3>
                <p>
                  Aproveite o acesso a produtos exclusivos da Blu, como o Link
                  de Pagamento Blu.
                </p>
              </div>

              <div className="receivables-item">
                <img src={centralizar} />
                <h3>Um só lugar</h3>
                <p>
                  Centralize a gestão das vendas e faça todos os pagamentos e
                  transferências em um só lugar.
                </p>
              </div>
            </div>

            <img
              className="mobile"
              src={maquininhas}
              style={{ marginTop: 20 }}
            />
            <img
              className="desktop"
              src={maquininhasDesktop}
              style={{ marginTop: 20 }}
            />
          </section>

          <section className="calculator">
            <div className="content">
              <img src={calculator} />

              <h2>Na Blu você tem custo zero!</h2>
              <p>
                A plataforma é gratuita e você paga seus fornecedores
                economizando até 10%.
              </p>

              <PrimaryButton onClick={handleOpenModal}>
                Comece a pagar com a Blu
              </PrimaryButton>
            </div>
            <div className="price">
              <div className="items">
                <div className="item">
                  <img src={moneyOutline} />
                  <div className="info">
                    <p>
                      Taxa de adesão <br /> <em>R$ 0,00</em>
                    </p>
                  </div>
                </div>
                <hr className="separator" />
                <div className="item">
                  <img src={blu} />
                  <div className="info">
                    <p>
                      Mensalidade da Conta Digital Blu <br /> <em>R$ 0,00</em>
                    </p>
                  </div>
                </div>
                <hr className="separator" />
                <div className="item">
                  <img src={packageIcon} />
                  <div className="info">
                    <p>
                      Taxa de antecipação para pagar fornecedores <br />{" "}
                      <em>R$ 0,00</em>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <img src={calculatorImage} className="calculator-image -mobile" />
            <img
              src={calculatorImageDesktop}
              className="calculator-image -desk"
            />
            <img
              src={calculatorImageTablet}
              className="calculator-image -tablet"
            />
            
          </section>

          <section className="video-section">
            <div>
              <h2>Compre melhor com a Blu</h2>
              <button
                className="button button--green"
                onClick={handleOpenModal}
              >
                Comece a pagar com a Blu
              </button>
            </div>
            <div className="video-container">
              <iframe
                width="560"
                height="315"
                src="https://play.vidyard.com/SeKuSQ6pQ4MSfCwae1difQ?disable_popouts=1&v=4.2.30&viral_sharing=0&embed_button=0&hide_playlist=1&color=FFFFFF&playlist_color=FFFFFF&play_button_color=000&gdpr_enabled=1&type=inline&autoplay=0&loop=0&muted=0&hidden_controls=0&pomo=2"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </section>

          <section className="partnership">
            <div className="content">
              <h2>
                Minha parceria com a Blu é para tornar as coisas mais{" "}
                <em>simples e vantajosas</em> para o seu negócio
              </h2>
              <p>
                Lembre-se: pagar seus fornecedores com a Blu é sempre a melhor
                opção!{" "}
              </p>
              <PrimaryButton onClick={handleOpenModal}>
                Comece a pagar com a Blu
              </PrimaryButton>
            </div>

            <img src={partnershipImage} className="-mobile" />
            <img src={partnershipDesktopImage} className="-desktop" />
          </section>
          <RegisterModal
            sellerId={repData?.referrer_id}
            isOpen={isRegisterModalOpen}
            close={handleCloseModal}
            portalHost={portalHost}
          />
        </>
      )}
    </div>
  );
}
