$(document).on('ready turbolinks:load', function () {
  const toggleMenu = function (e) {
    e.preventDefault();
    $('.menus').toggleClass('active')
    $('.icon-menu img').toggleClass('active')
    $('.header__navigation').toggleClass('header__navigation--visible')
  }

  $('.header__menu').on('click', toggleMenu)
  $('.header__navigation__close').on('click', toggleMenu)
});
