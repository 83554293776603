import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "./style.scss";
import { itemValues } from "../../shared/helpers/itemValues";
import CurrencyTextFormatter from "../../shared/CurrencyTextFormatter";

function OverviewValues({
  items,
  complementaryData,
  handleCreateOrder,
  updateItemsQuantity,
  isLoading,
}) {
  const [subtotal, setSubtotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [taxSubstitutionValue, setTaxSubstitutionValue] = useState(0);
  const [ipiValue, setIpiValue] = useState(0);

  function handleItemValues() {
    let tempSubtotal = 0,
      tempDiscount = 0,
      tempTotalQuantity = 0;

    items.forEach((el) => {
      tempSubtotal += itemValues(el, "subtotal");
      tempDiscount += itemValues(el, "discount");
      tempTotalQuantity += el.quantity;
    });
    setSubtotal(tempSubtotal);
    setDiscount(tempDiscount);
    updateItemsQuantity(tempTotalQuantity);

    //Taxes
    let tempTaxSubstitution =
        (tempSubtotal - tempDiscount) *
        Number(complementaryData.tax_substitution / 100),
      tempIpi =
        (tempSubtotal - tempDiscount) * Number(complementaryData.ipi / 100);

    setTaxSubstitutionValue(tempTaxSubstitution);
    setIpiValue(tempIpi);
  }

  useEffect(() => {
    handleItemValues();
  }, []);

  return (
    <div className="overview-values">
      <div className="summaryfooter">
        <div className="info">
          <span className="text">Subtotal</span>
          <span className="price">
            <CurrencyTextFormatter value={subtotal} />
          </span>
        </div>
        {discount > 0 ? (
          <div className="info">
            <span className="text">Descontos</span>
            <span className="price">
              -<CurrencyTextFormatter value={discount} />
            </span>
          </div>
        ) : null}
        <div className="info">
          <span className="text">Impostos</span>
          <span className="price">
            <CurrencyTextFormatter value={taxSubstitutionValue + ipiValue} />
          </span>
        </div>
        <div className="info -total">
          <span className="text">Total</span>
          <span className="price">
            <CurrencyTextFormatter
              value={subtotal - discount + (taxSubstitutionValue + ipiValue)}
            />
          </span>
        </div>
        <button
          onClick={handleCreateOrder}
          className="button"
          disabled={isLoading}
        >
          {isLoading ? (
            <div className="spinner -white -mg-auto"></div>
          ) : (
            "Finalizar e enviar pedido"
          )}
        </button>
      </div>
    </div>
  );
}

OverviewValues.propTypes = {
  items: PropTypes.array.isRequired,
  complementaryData: PropTypes.object.isRequired,
  handleCreateOrder: PropTypes.func.isRequired,
  updateItemsQuantity: PropTypes.func.isRequired,
};

export default OverviewValues;
