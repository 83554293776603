export const Types = {
  SET_ACTIVE_TAB: "dashboardActiveTab/REQUEST",
};

const INITIAL_STATE = {
  activeTab: "overview",
};

export default function dashboardActiveTab(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload.activeTab,
      };

    default:
      return state;
  }
}

export const Creators = {
  setActiveTab: () => {
    return {
      type: Types.SET_ACTIVE_TAB,
    };
  },
};
