import React from "react";

import "./style.scss";

export default function ContentCard({ img, title, paragraph, color, link }) {
  return (
    <div className={`home-content-card -${color}`} onClick={()=>{ Turbolinks.visit(link)}}>
      <div className="content">
        <div className="text">
          <h3 className="title">{title}</h3>
          <p className="paragraph">{paragraph}</p>
        </div>
        {img ? <img src={img} /> : null}
      </div>
    </div>
  );
}
