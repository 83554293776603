import React, { useEffect, useRef, useState } from "react";

import rpaImage from "../../../assets/myAccount/ilustra-rpa-1.svg";
import rpaImage2 from "../../../assets/myAccount/ilustra-rpa-2.svg";
import "./style.scss";

import Address from "../MyData/Address";
import Documents from "../MyData/Documents";
import PersonalData from "../MyData/PersonalData";
import PrimaryButton from "../../shared/PrimaryButton";
import SecondaryButton from "../../shared/SecondaryButton";
import Backdrop from "../../shared/Backdrop";

export default function RpaPayment({ currentSeller }) {
  const firstModalShow = useRef(true);

  const [activeSection, setActiveSection] = useState({
    initial: true,
    personalData: false,
    address: false,
    documents: false,
    finish: false,
  });

  const [rpaForm, setRpaForm] = useState({ ...currentSeller });
  const [isModalOpen, setIsModalOpen] = useState(false);
  function handleNavigate(url) {
    Turbolinks.visit(url);
  }

  function handleUpdateRpaForm(values) {
    setRpaForm({ ...rpaForm, ...values });
  }

  function handleNavigation(selected) {
    const tempObject = activeSection;
    Object.keys(tempObject).forEach((v) => (tempObject[v] = false));
    setActiveSection({ ...tempObject, [selected]: true });
  }

  useEffect(() => {
    const onLeave = () => {
      if (firstModalShow.current) {
        firstModalShow.current = false;
        setIsModalOpen(true);
        return;
      }
    };

    document.querySelector("#rpa").addEventListener("mouseleave", onLeave);
  }, []);

  return (
    <div className="container" id="rpa">
      {
        {
          [activeSection.initial]: (
            <div className="rpa-landing">
              <div className="image">
                <img src={rpaImage} alt="Ilustração RPA" />
              </div>

              <div className="content">
                <h1 className="title">Mais comodidade pra você! 🙌</h1>
                <p className="paragraph -darker">
                  Você completa o seu cadastro uma única vez e já está apto a
                  receber pagamentos em sua Conta Digital Blu.
                </p>

                <h2 className="subheading">Por que precisamos desses dados?</h2>
                <p className="paragraph">
                  Estes dados são necessários para a emissão do Recibo de
                  Pagamento Autônomo, documento que formaliza o pagamento para
                  serviços prestados por pessoas físicas para empresas.
                </p>
                <PrimaryButton onClick={() => handleNavigation("personalData")}>
                  Preencher dados
                </PrimaryButton>
              </div>
            </div>
          ),
          [activeSection.personalData]: (
            <PersonalData
              isRpa
              customBack={() => handleNavigation("initial")}
              customNext={() => handleNavigation("documents")}
              updateData={handleUpdateRpaForm}
              sellerData={rpaForm}
            />
          ),
          [activeSection.documents]: (
            <Documents
              isRpa
              customBack={() => handleNavigation("personalData")}
              customNext={() => handleNavigation("address")}
              updateData={handleUpdateRpaForm}
              sellerData={rpaForm}
            />
          ),
          [activeSection.address]: (
            <Address
              isRpa
              customBack={() => handleNavigation("documents")}
              customNext={() => handleNavigation("finish")}
              updateData={handleUpdateRpaForm}
              sellerData={rpaForm}
            />
          ),
          [activeSection.finish]: (
            <div className="rpa-landing">
              <div className="image">
                <img src={rpaImage2} alt="Ilustração RPA" />
              </div>

              <div className="content">
                <h1 className="title -finish">Tudo certo! ✨</h1>
                <p className="paragraph">
                  <strong>
                    Sua conta já está pronta para receber pagamentos.
                  </strong>{" "}
                  Você será notificado por e-mail sempre que houver um depósito
                  em sua Conta Digital Blu.
                </p>
                <PrimaryButton onClick={() => handleNavigate("/minha-conta")}>
                  Entendi
                </PrimaryButton>
              </div>
            </div>
          ),
        }[true]
      }

      <Backdrop
        isOpen={isModalOpen}
        close={() => {
          setIsModalOpen(false);
        }}
      >
        <div className="rpa-modal">
          <h2 className="modaltitle"> Não deixe de preencher seus dados!</h2>
          <p className="modalparagraph">
            Você precisa completar seu cadastro para receber seu prêmio através
            do Recibo de Pagamento Autônomo.
          </p>
          <div className="buttoncontainer">
            <PrimaryButton
              onClick={() => {
                handleNavigation("personalData");
                setIsModalOpen(false);
              }}
            >
              Preencher dados
            </PrimaryButton>
            <SecondaryButton onClick={() => setIsModalOpen(false)}>
              Agora não
            </SecondaryButton>
          </div>
        </div>
      </Backdrop>
    </div>
  );
}
