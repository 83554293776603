import "jquery-validation";
import "jquery-validation/dist/localization/messages_pt_BR";

$(document).on("ready turbolinks:load", function() {
  const isCnpj = localStorage.getItem('signup_person_type') === 'cnpj';
  $(".form-validation").validate({
    ignore: "input[type=hidden], .select2-input, .select2-focusser",
    errorPlacement: function(error, element) {
      const isRadio = element.hasClass("radio_buttons");
      const isCheckbox = element.hasClass("boolean");
      
      const hasDistributorNameError = $("#seller_distributor_name").hasClass("error")
      const hasStateError = $("#seller_state").hasClass("error")

      if(hasDistributorNameError) {
        $("[aria-labelledby='select2-seller_distributor_name-container']").attr('style', 'border-color: red !important');
      } 

      if(hasStateError) {
        $("[aria-labelledby='select2-seller_state-container']").attr('style', 'border-color: red !important');
      }

      if (isRadio || isCheckbox) {
        error.appendTo(element.parents("div")[0]);
        return;
      }

      error.insertAfter(element);
    }
  });

  $("#seller_name").rules("add", {
    required : true,
    nome_completo: true,
    messages : { nome_completo : 'Insira um nome e sobrenome.' }
  })

  $("#seller_phone").rules("add", {
    required : true,
    cell_phone_br: true,
    messages : { cell_phone_br : 'O celular informado não é válido.' }
  })

  $("#seller_email").rules("add", {
    required : true,
    email: true,
    messages : { email : 'O e-mail informado não é válido.' }
  });

  $("#seller_user_cpf").rules("add", {
    required : true,
    cpf: true,
    messages : { cpf : 'O CPF informado não é válido.' }
  });

  $("#seller_cpf_cnpj").rules("add", {
    required : true,
    cnpj: isCnpj,
    messages : { cnpj : 'O CNPJ informado não é válido.' }
  });

  $("select.states").rules("add", "required");

  $("#seller_accepted_terms_of_use").rules("add", {
    required : true,
    messages : { required : 'Marque a caixa de seleção para continuar.' }
  });
  $("select.know_how_blu").rules("add", "required");
  $("select.distributor_name_1").rules("add", "required");
});