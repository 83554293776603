import React from "react";

import "./style.scss";
import mobileClose from "../../../../assets/representativePage/mobile-close.svg";

export default function Title({ close, hideOnDesktop }) {
  return (
    <h2 className={`register-modal-title ${hideOnDesktop && "-desktop-hide"}`}>
      <img src={mobileClose} onClick={close} />
      Criar conta Blu
    </h2>
  );
}
