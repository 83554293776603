import React from "react";

import "./style.scss";
import ContentCard from "./ContentCard";
import bluWithYouImage from "../../../assets/home/ilustra-blu-with-you.svg";
import communityImage from "../../../assets/home/ilustra-comunidade.svg";

export default function MoreContent() {
  return (
    <div className="home-more-content">
      <div className="container">
        <h2 className="title"> Mais conteúdo pra você</h2>
      </div>
      <div className="cardscontainer container">
        <ContentCard
          title="#BluComVocê"
          paragraph="Conheça as nossas soluções para ajudar seu cliente varejista."
          img={bluWithYouImage}
          color="green"
          link="https://conteudo.useblu.com.br/blucomvoce"
        />
        <ContentCard
          title="Conheça a comunidade"
          paragraph="Saiba o que estamos construindo para você, Rep!"
          img={communityImage}
          color="gray"
          link="https://www.blu.com.br/representante/"
        />
      </div>
    </div>
  );
}
