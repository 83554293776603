import React, { useEffect } from "react";

import "./style.scss";
import clientIcon from "../../../assets/opportunities/icn-client.svg";
import phoneIcon from "../../../assets/opportunities/icn-phone.svg";

import DetailHeader from "../../shared/DetailHeader";

import { connect } from "react-redux";
import { Types } from "../../../store/ducks/opportunities";

import InfoWithIcon from "../../shared/InfoWithIcon";
import FooterActions from "../../shared/FooterActions";

function OpportunityDetail({
  changePage,
  opportunities,
  currentSeller,
  showPhone,
  primaryAction,
  secondaryAction,
  image,
  title,
  content,
}) {
  useEffect(() => {
    if (!opportunities.selectedOpportunity?.opportunitable_object) {
      changePage("list");
    }
  }, []);

  return (
    <>
      <DetailHeader title="" backAction={() => changePage("list")} />
      <div className="container opportunity-detail">
        <div className="imgcontainer">
          <img src={image} />
        </div>
        <div className="content">
          <h1 className="title">{title}</h1>

          <InfoWithIcon
            isCpfCnpj
            icon={clientIcon}
            description={
              opportunities.selectedOpportunity?.opportunitable_object
                ?.client_recommendation?.client_cpf_cnpj || changePage("list")
            }
            primaryText={
              opportunities.selectedOpportunity?.opportunitable_object
                ?.client_recommendation?.client_name
            }
          />

          {showPhone ? (
            <InfoWithIcon
              isCpfCnpj={false}
              icon={phoneIcon}
              description="Telefone"
              primaryText={
                opportunities.selectedOpportunity?.opportunitable_object
                  ?.client_recommendation?.user_phone
              }
            />
          ) : null}

          {content}

          <FooterActions
            primaryAction={primaryAction}
            secondaryAction={secondaryAction}
          />
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setTab: (payload) => {
      dispatch({ type: Types.SET_TAB, payload });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    opportunities: state.opportunities,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OpportunityDetail);
