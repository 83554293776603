import React, { useEffect, useState } from "react";
import Switch from "react-switch";

import "./style.scss";
import arrowUpRight from "../../../../assets/myAccount/arrow-up-right.svg";
import privacyImg from "../../../../assets/myAccount/privacy.svg";
import bullhornIcon from "../../../../assets/myAccount/bullhorn.svg";
import lockImg from "../../../../assets/myAccount/lock.svg";
import starIcon from "../../../../assets/myAccount/icn-estrela.svg";

import DetailHeader from "../../../shared/DetailHeader";
import MenuItemMobile from "../../../shared/MenuItemMobile";
import Backdrop from "../../../shared/Backdrop";
import PrimaryButton from "../../../shared/PrimaryButton";
import SecondaryButton from "../../../shared/SecondaryButton";

export default function DataPrivacy({ showBackCta }) {
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [trackingChecked, setTrackingChecked] = useState(
    mixpanel.has_opted_out_tracking() ? false : true
  );

  function handleTrackingSwitch() {
    trackingChecked ? setShowBackdrop(true) : mixpanel.opt_in_tracking();
    setTrackingChecked(true);
  }

  function handleSwitchChange() {
    return handleTrackingSwitch();
  }

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("check").click();
      document.getElementById("check").click();
    }, 300);
  }, []);
  useEffect(() => {
    setTrackingChecked(mixpanel.has_opted_out_tracking() ? false : true);
  }, [mixpanel.has_opted_out_tracking()]);

  return (
    <>
      <DetailHeader
        backAction={() => Turbolinks.visit("#seguranca-e-privacidade")}
        title="Privacidade e dados"
        showBackArrow={showBackCta}
      />
      <div className="account-privacy container">
        <div className="imgcontainer">
          <img src={privacyImg} alt="Privacidade levada a sério" />
        </div>

        <h2 className="sectiontitle">
          Aqui na Blu sua privacidade é levada a sério
        </h2>
        <p className="sectionparagraph -light">
          Conheça todos detalhes da nossa Política de Privacidade para a
          Comunidade Blu Rep nota 1.000.
        </p>

        <a
          className="privacypolicy"
          href="/politica-de-privacidade"
          target="_blank"
        >
          Política de privacidade
          <img src={arrowUpRight} alt="Seta" />
        </a>

        <hr className="separator" />

        <h2 className="sectiontitle"> Personalização da sua experiência</h2>
        <p className="sectionparagraph">
          Seus dados viabilizam nossos produtos e tornam sua experiência ainda
          melhor aqui na Comunidade.
        </p>

        <ul className="personalization">
          <li className="item">
            <img src={starIcon} alt="Estrela" />
            <p>
              <strong>Otimizamos sua navegação </strong> baseado de como você se
              relaciona conosco. Conhecendo você melhor, conseguimos indicar
              materiais de apoio e mostrar oportunidades para potencializar seus
              ganhos.
            </p>
          </li>

          <li className="item">
            <img src={bullhornIcon} alt="Megafone" />
            <p>
              <strong>Ações de marketing exclusivas </strong> para você ficar
              por dentro de todas as tendências do mercado. Com seu cadastro na
              Blu, você tem acesso a conteúdos e serviços exclusivos, ações
              promocionais e mais!
            </p>
          </li>
        </ul>

        <div className="personalizedexp">
          Personalizar experiência
          <label htmlFor="tracking-switch">
            <Switch
              uncheckedIcon={null}
              checkedIcon={null}
              onColor="#2BC783"
              offColor="#D3D4DD"
              boxShadow="rgba(0, 0, 0, 0.2) 1px 1px 3px"
              width={50}
              onChange={handleSwitchChange}
              checked={trackingChecked}
              id="tracking-switch"
            />
          </label>
        </div>

        <MenuItemMobile
          title="Dados da sua conta"
          className="customItem"
          onClick={() => Turbolinks.visit("#dados-da-conta")}
        />

        <Backdrop
          isOpen={showBackdrop}
          close={() => setShowBackdrop(false)}
          showCloseButton
        >
          <div className="imgcontainer">
            <img src={lockImg} alt="Dados e personalização" />
          </div>
          <h1 className="backdroptitle">Dados e personalização</h1>
          <p className="backdropparagraph">
            Optando em não personalizar sua experiência em nossa comunidade
            ficamos impossibilitados de oferecer contúdos exclusivos e
            potencializar seus ganhos.
          </p>
          <br />
          <p className="backdropparagraph">
            <strong>Você tem certeza que deseja desabilitar esta opção?</strong>
          </p>
          <div className="buttons">
            <SecondaryButton
              onClick={() => {
                setTrackingChecked(false);
                setShowBackdrop(false);
                mixpanel.opt_out_tracking();
              }}
            >
              Desabilitar
            </SecondaryButton>
            <PrimaryButton
              onClick={() => {
                setTrackingChecked(true);
                setShowBackdrop(false);
              }}
            >
              Manter habilitado
            </PrimaryButton>
          </div>
        </Backdrop>
      </div>
    </>
  );
}
