import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";

import Backdrop from "../../shared/Backdrop";
import CompanyData from "./CompanyData";
import Footer from "./Footer";
import RepData from "./RepData";
import Success from "./Success";
import FormValidation from "../../shared/helpers/FormValidation";
import api from "../../services/api";
import useMacthMedia from "../../../hooks/useMatchMedia";
import StatusError from "./StatusError";

export default function RegisterModal({ isOpen, close, sellerId, portalHost }) {
  const isMobile = useMacthMedia();
  const [step, setStep] = useState("companyData");
  const [fieldHasError, setFieldHasError] = useState({});
  const [customErrorMessage, setCustomErrorMessage] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasSubmittedCompanyData, setHasSubmittedCompanyData] = useState(false);
  const [counter, setCounter] = useState(0);

  const formik = useFormik({
    initialValues: {
      user_cpf: "",
      user_email: "",
      user_phone: "",
      user_name: "",
      company_cnpj: "",
      company_department_name: "",
      company_declared_department_name: "",
      company_name: "",
      user_phone_2: "",
      company_distributor_name: "",
    },
    onSubmit: (values) => {
      setIsLoading(true);
      api
        .post(`/api/v1/referrals/create`, {
          client: {
            user_cpf: values.user_cpf,
            user_email: values.user_email,
            user_phone: values.user_phone,
            user_name: values.user_name,
            company_cnpj: values.company_cnpj,
            company_department_name: values.company_department_name,
            company_declared_department_name:
              values.company_declared_department_name,
            company_name: values.company_name,
            user_phone_2: values.user_phone_2,
            company_distributor_name: values.company_distributor_name,
          },
          seller_id: sellerId,
          utm_source: "lprep",
          utm_medium: isMobile ? "website_responsivo" : "website_desktop",
          utm_campaign: "economize_ate_10_porcento",
        })
        .then((res) => {
          setIsLoading(false);
          setHasSubmittedCompanyData(true);
          setStep("success");
        })
        .catch((err) => {
          setHasSubmittedCompanyData(true);
          const errors = err.response.data.errors;
          const hasErrorBypass = errors?.some(
            (error) => error.type === "status"
          );
          const fieldToBypass = hasErrorBypass ? "company_cnpj" : "";
          FormValidation({
            err,
            setFieldHasError,
            setCustomErrorMessage,
            setRefresh,
            refresh,
            setIsLoading,
            hasErrorBypass,
            fieldToBypass,
          });
        });
    },
    validate: (values) => {
      let errors = {};
      let requiredMsg = "Este campo não pode ficar em branco.";
      if (!values.company_department_name)
        errors.company_department_name = requiredMsg;
      return errors;
    },
  });

  useEffect(() => {
    if (hasSubmittedCompanyData && step === "companyData") {
      if (!!fieldHasError.status) {
        setStep("statusError");
      } else if (
        fieldHasError.company_cnpj ||
        fieldHasError.company_distributor_name ||
        fieldHasError.company_department_name ||
        fieldHasError.company_name
      ) {
        setStep("companyData");
      } else {
        setStep("repData");
      }
    }
  }, [fieldHasError]);

  useEffect(() => {
    if (!formik.errors.company_department_name && hasSubmittedCompanyData) {
      fieldHasError.company_department_name = true;
      customErrorMessage.company_department_name =
        formik.errors.company_department_name;
    }
  }, [formik]);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const closeStatusErrorHandle = useCallback(() => {
    close();
    setStep("companyData");
  }, []);

  return (
    <Backdrop
      showCloseButton
      isFullScreen
      customWidth={800}
      isOpen={isOpen}
      close={step === "statusError" ? closeStatusErrorHandle : close}
      fixedHeight
    >
      {{
        companyData: (
          <>
            <CompanyData
              form={formik}
              fieldHasError={fieldHasError}
              customErrorMessage={customErrorMessage}
              refresh={refresh}
              modalClose={close}
            />
            <Footer
              nextStep={() => {
                formik.handleSubmit();
                setHasSubmittedCompanyData(true);
              }}
              stepNumber={1}
              hideBackButton={true}
              isLoading={isLoading}
            />
          </>
        ),
        repData: (
          <>
            <RepData
              form={formik}
              fieldHasError={fieldHasError}
              customErrorMessage={customErrorMessage}
              refresh={refresh}
              modalClose={close}
            />
            <Footer
              previousStep={() => setStep("companyData")}
              nextStep={() => {
                formik.handleSubmit();
              }}
              stepNumber={2}
              hideBackButton={false}
              isLoading={isLoading}
            />
          </>
        ),
        success: (
          <Success
            counter={counter}
            portalHost={portalHost}
            email={formik.values.user_email}
            startCounter={() => setCounter(60)}
            modalClose={close}
          />
        ),
        statusError: (
          <StatusError
            handleOkGotIt={() => setStep("companyData")}
            modalClose={closeStatusErrorHandle}
          />
        ),
      }[step] || (
        <>
          <CompanyData form={formik} />
          <Footer
            nextStep={() => setStep("repData")}
            stepNumber={1}
            hideBackButton={true}
          />
        </>
      )}
    </Backdrop>
  );
}
