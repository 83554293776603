import React from "react";
import PropTypes from "prop-types";

import infoIcon from "../../../assets/general/info.svg";

import "./style.scss";

function Card({ tooltip, color, title, value, onClick }) {
  return (
    <div className="dashboard-card">
      <div className="content">
        <div className={`card -${color}`}>
          <div id="has-user-logged" className="info">
            <img src={infoIcon} />
            <span className="tooltip">{tooltip}</span>
          </div>
          <a onClick={onClick}>
            <div className="text">
              <p>{title}</p>
              <span>{value}</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

Card.propTypes = {
  tooltip: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.number,
  onClick: PropTypes.func,
};

Card.defaultProps = {
  tooltip:
    "Sua indicação se tornou cliente Blu. Vamos enviar a maquininha para asvendas começarem o quanto antes.",
  color: "blue",
  title: "Title",
  value: 2,
  onClick: () => {
    console.log("Clicked");
  },
};

export default Card;
