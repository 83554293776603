import {
  ALLOWED_EMAILS_WITHOUT_POSSIBLE_TYPOS,
  INVALID_EMAIL_ADDRESS,
} from "./const";

export function checkValidPISNumber(pisNumber) {
  var pislimpo = pisNumber.replace(/[^0-9]/g, "");
  let ftap = "3298765432";
  let total = 0;
  let i;
  let resto = 0;
  let numPIS = 0;
  let strResto = "";
  let resultado = "";

  numPIS = pislimpo;

  if (numPIS == "" || numPIS == null || numPIS.length < 11) {
    return false;
  }

  for (i = 0; i <= 9; i++) {
    resultado = numPIS.slice(i, i + 1) * ftap.slice(i, i + 1);
    total = total + resultado;
  }

  resto = total % 11;

  if (resto != 0) {
    resto = 11 - resto;
  }

  if (resto == 10 || resto == 11) {
    strResto = resto + "";
    resto = strResto.slice(1, 2);
  }

  if (resto != numPIS.slice(10, 11)) {
    return false;
  }
  return true;
}

export function validateEmail(value) {
  const regex =
    /^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[A-Z0-9-]+\.)+[A-Z]{2,6}$/i;
  const email = value;
  if (regex.test(email)) {
    return Boolean(email);
  }
  return false;
}

export function validateEmailWitelist(
  email,
  allowedEmails = ALLOWED_EMAILS_WITHOUT_POSSIBLE_TYPOS
) {
  // check simple email format
  if (!validateEmail(email)) return INVALID_EMAIL_ADDRESS;

  const splitedEmail = email.toLowerCase().split("@");
  const splitedEmailDomain = splitedEmail[1].split(".");

  // check domains
  if (splitedEmailDomain.length === 1) {
    return INVALID_EMAIL_ADDRESS;
  }

  if (splitedEmailDomain.length === 2) {
    if (!allowedEmails.includes(splitedEmailDomain[1].toUpperCase())) {
      return INVALID_EMAIL_ADDRESS;
    }
  }

  if (splitedEmailDomain.length === 3) {
    if (!allowedEmails.includes(splitedEmailDomain[2].toUpperCase())) {
      return INVALID_EMAIL_ADDRESS;
    }

    if (!allowedEmails.includes(splitedEmailDomain[1].toUpperCase())) {
      return INVALID_EMAIL_ADDRESS;
    }
  }
  return "";
}
