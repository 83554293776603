import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./style.scss";

function Snackbar({ icon, text, shouldShow, handleClose, duration }) {
  const [show, setShow] = useState(shouldShow);

  useEffect(() => {
    if (shouldShow) {
      setShow(true);
      setTimeout(() => {
        setShow(false);
        handleClose();
      }, [duration]);
    }
  }, [shouldShow]);

  return (
    <div className={show ? "snackbar -open" : "snackbar"}>
      <div className="content">
        <img src={icon} alt="Não permitido" />
        <span className="snackbartext">{text} </span>
      </div>
    </div>
  );
}

Snackbar.propTypes = {
  duration: PropTypes.number,
  handleClose: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  shouldShow: PropTypes.bool.isRequired,
  text: PropTypes.string,
};

Snackbar.defaultProps = {
  duration: 2500,
  text: ""
};

export default Snackbar;
