import React from "react";

import "./style.scss";
import close from "../../../assets/general/close-blue.svg";
import successImage from "../../../assets/opportunities/updated-info.svg";
import phoneIcon from "../../../assets/opportunities/icn-phone.svg";
import obsIcon from "../../../assets/opportunities/observation.svg";
import PrimaryButton from "../../shared/PrimaryButton";

import { connect } from "react-redux";
import { Types } from "../../../store/ducks/opportunities";

function OpportunityDetailUpdated({ changePage, opportunities }) {
  return (
    <>
      <div className="container">
        <img
          onClick={() => changePage("list")}
          src={close}
          style={{ marginBottom: 24, cursor: "pointer" }}
        />
      </div>
      <div className="container opportunity-detail-updated">
        <div className="imgcontainer">
          <img src={successImage} />
        </div>
        <div className="content">
          <h1 className="title">Informações atualizadas!</h1>
          <p className="description -black">
            Estes foram os dados que você informou:
          </p>

          {opportunities.recentlyUpdated.newPhone ? (
            <div className="clientinfo">
              <div className="image">
                <img src={phoneIcon} />
              </div>
              <div className="text">
                <span>Telefone</span>
                <span className="primary">
                  {opportunities.recentlyUpdated.newPhone}
                </span>
              </div>
            </div>
          ) : null}
          <div className="clientinfo">
            <div className="image">
              <img src={obsIcon} />
            </div>
            <div className="text">
              <span>Observação</span>
              <span className="primary">
                {opportunities.recentlyUpdated.comments}
              </span>
            </div>
          </div>

          <p className="description">
            As informações do cliente foram atualizadas e iremos fazer novas
            tentativas de contato. Você pode acompanhar andamento dessa
            indicação em ”Minhas indicações”.
          </p>

          <PrimaryButton
            className="primarybtn"
            onClick={() => changePage("list")}
          >
            Fechar
          </PrimaryButton>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    selectOpportunity: (payload) => {
      dispatch({ type: Types.SELECT_OPPORTUNITY, payload });
    },
  };
};
const mapStateToProps = (state) => {
  return {
    opportunities: state.opportunities,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpportunityDetailUpdated);
