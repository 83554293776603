import React, { useEffect } from "react";

import "./style.scss";
import PrimaryButton from "../../../shared/PrimaryButton";
import emailImage from "../../../../assets/representativePage/ilustra-email.svg";
import Title from "../Title";

export default function Success({
  counter,
  startCounter,
  modalClose,
  email,
  portalHost,
}) {
  useEffect(() => {
    startCounter();
  }, []);

  return (
    <>
      <Title close={modalClose} hideOnDesktop />
      <div className="modal-success">
        <img src={emailImage} alt="email" />
        <div className="content">
          <h2 className="title">
            Quase lá! Confirme seu e-mail para finalizar seu cadastro
          </h2>
          <p className="paragraph">
            Confira a caixa de entrada do seu e-mail e siga as instruções para
            dar continuidade ao seu cadastro.
          </p>
          <p className="paragraph">
            Pode levar alguns minutos para que o e-mail apareça em sua caixa de
            entrada. Não se esqueça de verificar as pastas de spam e promoções,
            por garantia!
          </p>

          <PrimaryButton
            onClick={() => {
              window.open(`${portalHost}/users/password/new`, "_blank");
            }}
            disabled={counter > 0}
          >
            Não recebi email de boas vindas {counter > 0 ? counter : null}
          </PrimaryButton>
        </div>
      </div>
    </>
  );
}
