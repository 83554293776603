import statusHook from './status-hook';

$.fn.statusForm = function() {
    const $form = $(this);
    const $backdrop = $("[data-js-backdrop-container]")
    const $rows = $("[data-js-datatable] > tbody > tr");

    $form.on("submit", function(event) {
        let selected = [];

        $(".dashboard__boolean__input").each(function(_index, element) {
            const field = $(element).val();
            const checked = $(element).is(':checked');

            if (checked) {
                selected.push(field);
            }
        });

        event.preventDefault();
        statusHook(selected);
        
        mixpanel.track("recommendation_filter",{
            sort_type: selected
        })

        $backdrop.toggle();
    });
};

$(document).on("ready turbolinks:load", function () {
    $("[data-js-filter-form]").statusForm();
});
