import React, { useEffect, useState } from "react";
import "./style.scss";

import Advantages from "../Advantages";
import Field from "../../../shared/Form/Field";
import getSuppliers from "../../../shared/helpers/GetSuppliers";
import Title from "../Title";
import api from "../../../services/api";
import DropdownField from "../../../shared/DropdownField";

export default function CompanyData({
  form,
  fieldHasError,
  customErrorMessage,
  refresh,
  modalClose,
}) {
  const [selectedValue, setSelectedValue] = useState(
    "" || form.values.company_distributor_name
  );
  const [selectedDepartment, setSelectedDepartment] = useState(
    "" || form.values.company_department_name
  );
  const [options, setOptions] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);

  const handleChange = (e) => {
    setSelectedValue(e.value);
    form.setFieldValue("company_distributor_name", e.value);
    setFieldHasError({ company_distributor_name: false });
  };

  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.value);
    form.setFieldValue("company_department_name", e.value);
    setFieldHasError({ company_department_name: false });
  };

  useEffect(() => {
    getSuppliers(setSuppliers);
  }, []);
  useEffect(() => {
    setOptions(
      suppliers.map((supplier) => ({ value: supplier, label: supplier }))
    );
  }, [suppliers]);

  useEffect(() => {
    api.get("/api/v1/referrals/department_list").then((res) =>
      setDepartmentOptions(
        (res.data.departments || []).map((department) => ({
          value: department.name,
          label: department.name,
        }))
      )
    );
  }, []);

  return (
    <div className="company-data">
      <div style={{ width: "100%", maxWidth: 456 }}>
        <Title close={modalClose} />
        <p className="step-info">Vamos lá. Informe os dados da sua empresa.</p>
        <div className="form-row">
          <Field
            label="CNPJ"
            name="company_cnpj"
            placeholder="Informe seu CNPJ"
            formHandler={form}
            hasError={fieldHasError.company_cnpj}
            customError={customErrorMessage.company_cnpj}
            mask="99.999.999/9999-99"
            refresh={refresh}
          />
          <Field
            label="Nome da empresa"
            name="company_name"
            placeholder="Informe a empresa"
            formHandler={form}
            hasError={fieldHasError.company_name}
            customError={customErrorMessage.company_name}
            refresh={refresh}
          />
        </div>
        <DropdownField 
          label='Fornecedor principal'
          error={
            customErrorMessage?.company_distributor_name && 
            fieldHasError?.company_distributor_name ?
            customErrorMessage.company_distributor_name : ''
          }
          options={options}
          handleChange={handleChange}
          selectedValue={selectedValue}
        />
        <DropdownField 
          label='Segmento da empresa'
          error={ 
            form.errors.company_department_name &&
            (fieldHasError.company_department_name || 
              customErrorMessage.company_department_name) ? 
            customErrorMessage.company_department_name || 
            form.errors.company_department_name 
            : ''
          }
          options={departmentOptions}
          handleChange={handleDepartmentChange}
          selectedValue={selectedDepartment}
        />
      </div>
      <Advantages />
    </div>
  );
}
