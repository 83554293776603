const classNames = {
  selected: "collection-radio-buttons--label-selected",
  label: '.collection-radio-buttons--label',
  radio: '.collection-radio-buttons--radio'
};

const getRadioLabel = (el) => $(el).parent().find(classNames.label);

const onChangeRadio = function () {
  const labels = $(classNames.label);
  const label = getRadioLabel(this);

  labels.removeClass(classNames.selected);
  label.addClass(classNames.selected);
};

$(document).on("ready turbolinks:load", function () {
  getRadioLabel(`${classNames.radio}:checked`).addClass(classNames.selected);
  $(classNames.radio).on("change", onChangeRadio);
});
