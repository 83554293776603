import React from "react";

import "./style.scss";
import PrimaryButton from "../../../shared/PrimaryButton";
import SecondaryButton from "../../../shared/SecondaryButton";

export default function FeedbackCtas() {
  return (
    <div className="feedback-ctas">
      <PrimaryButton
        className="primary"
        onClick={() => Turbolinks.visit("/indicacao-premiada")}
      >
        Nova indicação
      </PrimaryButton>
      <SecondaryButton onClick={() => Turbolinks.visit("/dashboard")}>
        Finalizar
      </SecondaryButton>
    </div>
  );
}
