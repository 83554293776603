import React, { useEffect, useState } from "react";
import Select from "react-select";

import "./style.scss";
import api from "../../services/api";
import {
  customErrorStyles,
  customStyles,
} from "../../shared/helpers/CustomSelectStyles";
import ClientInfo from "../../shared/ClientInfo";
import PrimaryButton from "../../shared/PrimaryButton";
import FormValidation from "../../shared/helpers/FormValidation";
import DetailHeader from "../../shared/DetailHeader";
import BottomButtonContainer from "../../shared/BottomButtonContainer";
import useMacthMedia from "../../../hooks/useMatchMedia";

export default function SupplierStep({
  suppliers,
  recommendationForm,
  updateForm,
  previousStep,
  goToFeedback,
}) {
  const isMobile = useMacthMedia();
  const utmMediumValue = isMobile ? "website_responsivo" : "website_desktop";
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");

  const [fieldHasError, setFieldHasError] = useState({});
  const [customErrorMessage, setCustomErrorMessage] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setSelectedValue(e.value);
    setFieldHasError({ distributor_name: false });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);
    api
      .post(`/api/v1/client_recommendations`, {
        client_recommendation: {
          seller_cpf: recommendationForm.seller_cpf,
          distributor_name: selectedValue,
          verify_attributes: ["distributor_name"],
        },
        utm_medium: utmMediumValue,
      })
      .then(() => {
        api
          .post(`/api/v1/client_recommendations`, {
            client_recommendation: {
              ...recommendationForm,
              distributor_name: selectedValue,
            },
            utm_medium: utmMediumValue,
          })
          .then((response) => {
            setIsLoading(false);
            if (response.data.status == "ok") {
              goToFeedback({
                client_cpf_cnpj: response.data.client_cpf_cnpj,
                client_name: response.data.client_name,
                first_ok_created_at: "123",
                distributor_name: response.data.distributor_name,
              });
            }
          })
          .catch((err) => {
            setIsLoading(false);
          });
      })
      .catch((err) => {
        FormValidation({
          err,
          setFieldHasError,
          setCustomErrorMessage,
          setRefresh,
          refresh,
          setIsLoading,
        });
      });

    updateForm({ distributor_name: selectedValue });
  };

  useEffect(() => {
    setOptions(
      suppliers.map((supplier) => ({ value: supplier, label: supplier }))
    );
  }, []);

  return (
    <>
      <DetailHeader
        title="Para finalizar, informe:"
        backAction={previousStep}
        desktopOnly
      />

      <div className="container supplier-step">
        <ClientInfo
          altStyle
          greenIcon
          name={recommendationForm.client_name}
          cpfCnpj={recommendationForm.client_cpf_cnpj}
          className="clientInfo"
        />
        <form onSubmit={handleSubmit}>
          <div className="formrow">
            <div className="inputcontainer">
              <span>Fornecedor principal</span>
              <Select
                placeholder={
                  <span className="selectplaceholder">Selecione uma opção</span>
                }
                styles={
                  fieldHasError.distributor_name
                    ? customErrorStyles
                    : customStyles
                }
                options={options}
                onChange={handleChange}
                value={options.find((obj) => obj.value === selectedValue)}
              />
              {fieldHasError.distributor_name &&
              customErrorMessage.distributor_name ? (
                <span className="customerror">
                  {customErrorMessage.distributor_name}
                </span>
              ) : null}
            </div>
          </div>
          <BottomButtonContainer hasSeparator>
            <PrimaryButton type="submit">
              {isLoading ? (
                <div className="spinner -white -mg-auto"></div>
              ) : (
                "Finalizar"
              )}
            </PrimaryButton>
          </BottomButtonContainer>
        </form>
      </div>
    </>
  );
}
