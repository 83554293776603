import React from "react";
import PropTypes from "prop-types";

function CurrencyTextFormatter({ value }) {
  return (
    <>
      {new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value ? value : 0)}
    </>
  );
}

CurrencyTextFormatter.propTypes = {
  value: PropTypes.number.isRequired,
};

export default CurrencyTextFormatter;
