$(document).on("ready turbolinks:load", function () {
	let hiddenClass = "hidden-sidebar";
	let sidebar = $(".dashboard__sidebar");
	let sidebarShowButton = $(".dashboard__login__button");

	$(document).click(function () {
		if (!sidebar.hasClass(hiddenClass)) {
			sidebar.addClass(hiddenClass);
			sidebarShowButton.removeClass(hiddenClass);
		}
	});

	sidebar.click(function (event) {
		event.stopPropagation();
	});

	sidebarShowButton.on("click", function () {
		sidebarShowButton.addClass(hiddenClass);
		setTimeout(() => {
			sidebar.removeClass(hiddenClass);
		}, 100);
	});
});
