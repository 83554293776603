import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";

import "./style.scss";
import modalImage from "../../../assets/opportunities/opportunities-modal.svg";
import Backdrop from "../../shared/Backdrop";
import PrimaryButton from "../../shared/PrimaryButton";

export default function OpportunitiesFirstAccess() {
  const [isOpen, setIsOpen] = useState(false);
  const cookies = new Cookies();

  useEffect(() => {
    if (!cookies.get("hasAccessed")) {
      setIsOpen(true);
      cookies.set("hasAccessed", true, { path: "/" });
    }
  }, []);

  return (
    <div className="opportunities-first-access">
      <Backdrop 
        isFullScreen 
        close={() => setIsOpen(false)} 
        isOpen={isOpen} 
        showCloseButton
        fixedHeight
      >
        <div className="badge badge--new">Novidade!</div>
        <img src={modalImage} className="modalimg"/>
        <h2 className="maintitle">
          Aproveite todas as suas oportunidades de premiação
        </h2>
        <p className="paragraph">
          A partir de agora, você pode acompanhar de perto as suas indicações e
          descobrir o que precisa fazer para ajudar o seu cliente a aderir à
          Blu.{" "}
          <strong>
            Assim, você não perde nenhuma oportunidade de ganhar mais dinheiro.{" "}
          </strong>
        </p>

        <PrimaryButton
          style={{ maxWidth: "100%" }}
          onClick={() => Turbolinks.visit("/oportunidades")}
        >
          Ver minhas oportunidades
        </PrimaryButton>
      </Backdrop>
    </div>
  );
}
