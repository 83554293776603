// Callback to search all rows by the desired status
const searchRowsByStatus = (selected, rows, status) => rows.filter(function(_index, element) {
    // TODO: Refact for a more readable code
    const hasSelected = selected.length > 0;
    const desiredStatus = selected.reduce((prev, curr) => {
        const hasValue = $(element).text().toLowerCase().includes(curr.toLowerCase());

        return !!prev ? prev : hasValue;
    }, false);
    const containsDesiredStatus = hasSelected ? desiredStatus : true;

    return containsDesiredStatus === status;
});

// Set checkbox inputs as checked by default (specially useful for status-card.js)
const setCheckboxesStates = (selected) => {
    $(".dashboard__boolean__input").prop("checked", false);

    selected.forEach(element => {
        $(`.dashboard__boolean__input[value='${element}']`).prop("checked", true);
    });
}

const statusHook = function(selected) {
    const $rows = $("[data-js-datatable] > tbody > tr");
    const visibleRows = searchRowsByStatus(selected, $rows, true);
    const hiddenRows = searchRowsByStatus(selected, $rows, false);
    const hasRecommendations = visibleRows.length > 0;

    setCheckboxesStates(selected);
    $(visibleRows).show();
    $(hiddenRows).hide();

    if (!hasRecommendations) {
        $(".dashboard-no-recommendations").show();
    } else {
        $(".dashboard-no-recommendations").hide();
    }
};

export default statusHook;
