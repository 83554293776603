import React from "react";

import "./style.scss";
import close from "../../../assets/general/close-blue.svg";
import discardSuccessImage from "../../../assets/opportunities/discard-success.svg";
import obsIcon from "../../../assets/opportunities/observation.svg";
import PrimaryButton from '../../shared/PrimaryButton'

import { connect } from "react-redux";

function OpportunityDiscardSuccess({ changePage, opportunities}) {
  return (
    <>
      <div className="container">
        <img onClick={() => changePage('list')} src={close} style={{ marginBottom: 24, cursor: 'pointer' }} />
      </div>
      <div className="container opportunity-discard-success">

        <div className="imgcontainer">
          <img src={discardSuccessImage} />
        </div>
        <div className="content" >
          <h1 className="title">Oportunidade descartada</h1>

          <p className="description"> A oportunidade foi descartada e você não verá mais a indicação do cliente <strong>{opportunities.recentlyDiscarded.client_name}</strong> na lista das suas indicações.</p>

          <p className="description -black">O motivo informado foi:</p>

          <div className="clientinfo">
            <div className="image">
              <img src={obsIcon} />
            </div>
            <div className="text">
              <span>Motivo</span>
              <span className="primary">{opportunities.recentlyDiscarded.negative_comments}</span>
            </div>
          </div>

          <p className="description">
            Se quiser, você pode fazer a indicação desse cliente novamente.
          </p>

          <PrimaryButton className="primarybtn" onClick={() => changePage('list')}>Fechar</PrimaryButton>
        </div>

      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    opportunities: state.opportunities,
  };
};

export default connect(mapStateToProps)(OpportunityDiscardSuccess);
