import React from "react";

import feedbackImage from "../../../../assets/recommendations/ilustra-2.svg";
import FeedbackMainBody from "../FeedbackMainBody";
import FeedbackCtas from "../FeedbackCtas";

function Feedback2({ clientName, supplierName }) {
  return (
    <div>
      <FeedbackMainBody
        image={feedbackImage}
        titleAndSubtitle={
          <>
            <h1 className="title -hassubtitle">Indicação realizada!</h1>
            <h2 className="subtitle">
              Você pode ganhar <em>R$500</em> por essa indicação:
            </h2>
          </>
        }
        paragraph={
          <>
            <h3 className="paragraphtitle">
              💰 Você ainda pode ganhar <em>R$500</em>
            </h3>
            <p className="paragraph">
              Assim que <strong>{clientName}</strong> {" "}se tornar um cliente Blu
              você recebe seu prêmio.
            </p>
          </>
        }
      />
      <FeedbackCtas />
    </div>
  );
}

export default Feedback2;
