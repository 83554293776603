import { call, takeLatest, put } from "redux-saga/effects";
import { Types } from "../ducks/personalizedPage";
import api from "../../components/services/api";

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));
export const stateSuppliers = (state) => state.suppliersList.data.suppliers;

function getPages(sellerId) {
  return api.get(`/api/v1/referrals/configs/index?referrer_id=${sellerId}`);
}

export function* findPersonalizedPages({sellerId}) {
  try {
    const response = yield call(getPages, sellerId);

    yield put({
      type: Types.SUCCESS,
      payload: {
        pages: response.data.config_referral_pages,
      },
    });
  } catch (error) {
    yield console.log(error);
    yield put({ type: Types.FAIL });
  }
}

export default function* watchFindPersonalizedPageRequest() {
  yield takeLatest(Types.REQUEST, findPersonalizedPages);
}
