import React from "react";

import "./style.scss";

function CardCta({ action, img, text }) {
  return (
    <div className="card-cta" onClick={action}>
      <img className="image" src={img} />
      <p className="text"> {text}</p>
    </div>
  );
}

export default CardCta;
